.thankyou-page-main {
    background-image: url(../../assets//img/login-page-bckground.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: -100px;

    .thankyou-page-container {
        margin-left: 250px;
        color: white;
        padding-bottom: 180px;

        h1 {
            font-size: 7rem;
        }
        p {
            margin-left: 20px;

        }
        .homepage-link {
            font-size: 32px;
            color: #ff009d;
            margin-left: 20px;
        }
       
    }
}

@media only screen and (max-width:1400px){
    .thankyou-page-container{
        margin-left: 150px!important;
    }
}

@media only screen and (max-width:1100px){
    .thankyou-page-container{
        margin-left: 50px!important;
    }
}

@media only screen and (max-width:768px){
    .thankyou-page-container{
        margin: 0 auto!important;

        h1{
            font-size:4rem!important;
        }
        p{
            margin-left: 10px!important;
        }
        .homepage-link{
            margin-left: 10px!important;
        }
    }

    .thankyou-page-main{
        margin-bottom: 0px;
    }
    
}