#triangleWrapper {
  width: 50px;
  height: 50px;
  float: left;
  position: relative;
}

.segmentTriangle {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: linear-gradient(to right bottom, black 50%, transparent 50%)
}

.segmentTriangle1 {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: linear-gradient(to left bottom, black 50%, transparent 50%)
}

// .logo {
//   width: 0;
//   height: 0;
//   border-style: solid;
//   border-width: 30px 0 30px 55px;
//   border-color: transparent transparent transparent #42cb87;
// }
.logo-sec {
  margin: -200px auto 0;
  position: relative;
  .logo {
    position: absolute;
    --side-size: 30px;
    border-left: var(--side-size) solid transparent;
    border-right: var(--side-size) solid transparent;
    border-bottom: calc(2 * var(--side-size) * 0.866) solid #42cb87;
    border-top: var(--side-size) solid transparent;
    display: inline-block;
    transform: rotate(-30deg);
    opacity: 0;
    &.logo2 {
      transform: rotate(31deg);
      left: -15px;
    }
    &.logo3 {
      left: -59px;
      top: 34px;
    }
    &.logo4 {
      transform: rotate(-89deg);
      left: -52px;
      top: 88px;
    }
    &.logo5 {
      left: -1px;
      top: 68px;
    }
  }
  .club-text {
    font-size: 60px;
    position: absolute;
    top: 45px;
    width: 300px;
    font-family: $font-family-logo;
    span {
      display: inline-block;
      position: relative;
    }
  }
  // .logo2 {
  //   left: -44px;
  //   top: 8px;
  //   transform: rotate(61deg);
  // }
  // .logo3 {
  //   left: -60px;
  //   top: 34px;
  //   transform: rotate(4deg);
  //   border-bottom-width: 36px;
  // }
  // .logo4 {
  //   left: -47px;
  //   top: 62px;
  //   transform: rotate(-61deg);
  //   border-bottom-width: 28px;
  //   border-top-width: 33px;
  // }
  // .logo5 {
  //   left: -1px;
  //   top: 71px;
  //   transform: rotate(1deg);
  // }
}

.intro-svg-logo .st2 {
  width: 200px;
  height: auto;
  stroke-width: 2.5;
  // stroke: #000;
  fill: #fff;
  stroke-dasharray: 0 24;
  animation: 2s infinite animateFirst cubic-bezier(0.950, 0.050, 0.795, 0.035) alternate;
}

.animateSecond {
  animation: 0.2s 0.45s animateSecond ease-out forwards;
}

@keyframes animateFirst {
  0% {
    stroke-dasharray: 24 20;
    fill: none;
    opacity: 0.5;
    stroke: #42cb87;
  }
  50% {
    stroke-dasharray: 150 24;
    stroke: none;
    opacity: .75;
    fill: #42cb87
  }
  100% {
    stroke-dasharray: 200 24;
    stroke: #42cb87;
    opacity: 1;
    fill: none
  }
}

@keyframes animateSecond {
  to {
    stroke-dasharray: 6 24;
  }
}

.intro-svg-logo {
  polygon {
    animation-duration: 1s, 4s;
    animation-name: pulse, color-change-2x;
    animation-iteration-count: infinite;
    color: #26a380;
    animation-delay: 0s, 5000ms;
    &.path-7 {
      animation-delay: -1s
    }
    &.path-6 {
      animation-delay: -.875s
    }
    &.path-5 {
      animation-delay: -.75s
    }
    &.path-4 {
      animation-delay: -.625s
    }
    &.path-3 {
      animation-delay: -.5s;
      // transform: translateX(40px) translateY(40px);
    }
    &.path-2 {
      animation-delay: -.375s
    }
    &.path-1 {
      animation-delay: -.25s
    }
    &.path-0 {
      animation-delay: -.125s
    }
  }
  @keyframes pulse {
    0% {
      opacity: .1;
    }
    30% {
      opacity: .8;
    }
    100% {
      opacity: .1;
    }
  }
  /* ----------------------------------------------
 * Generated by Animista on 2021-9-20 16:11:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
  /**
 * ----------------------------------------
 * animation color-change-2x
 * ----------------------------------------
 */
  @-webkit-keyframes color-change-2x {
    0% {
      fill: #19dcea;
      stroke: #fff;
    }
    30% {
      fill: none;
      stroke: #fff;
    }
    60% {
      fill: #42cb87;
      stroke: none;
    }
    100% {
      fill: #b22cff;
      stroke: #fff;
    }
  }
  @keyframes color-change-2x {
    0% {
      fill: #19dcea;
      stroke: #fff;
    }
    30% {
      fill: none;
      stroke: #fff;
    }
    60% {
      fill: #42cb87;
      stroke: none;
    }
    100% {
      fill: #b22cff;
      stroke: #fff;
    }
  }
}