.make-it-available-sec {
    &.hidden {
        display: none;
    }
    .custom-checkbox {
        padding: 0;
        
    }
    .form-select {
        margin-left: 5px;
    }
}