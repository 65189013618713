.nft-single {
  position: relative;
  z-index: 2;
  background-color: $color-white;

}

.nft-detail {
  background-color: $color-offwhite2;
  padding: $spacing-base 0;

  @include respond-above(sm) {
    height: calc(100vh - 80px);
  }


  .container-fluid {
    padding: 0 $spacing-double;
  }

  &-title {
    font-size: $font-size-h3;
    margin-bottom: 0;
  }

  &-author {
    font-size: $font-size-body + 0.2;
  }

  &-img-sec {
    text-align: center;
    padding-right: $spacing-base;

    @include respond-above(sm) {
      border-right: 1px solid $color-divider;
    }

    img {
      margin: 0 auto;
      max-width: 100%;
      object-fit: cover;
      height: 300px;

      @include respond-above(sm) {
        height: calc(100vh - 120px);
      }
    }
  }

  &-text-sec {
    padding-top: $spacing-double;
    padding-bottom: $spacing-double;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
  }

  &-acc {
    padding-top: $spacing-double + $spacing-base;
    padding-bottom: $spacing-double + $spacing-base;

    .accordion-button:not(.collapsed) {
      color: inherit;
    }

    .accordion-item {
      border-left: none;
      border-right: none;
      border-top: none;

    }

    .accordion-button,
    .accordion-body {
      padding-left: 0;
      padding-right: 0;
    }

    .accordion-item,
    .accordion-button {
      background-color: transparent;
    }

    .accordion-button:focus {
      border-color: transparent;
      outline: 0;
      box-shadow: none;
    }


  }

  &-price {
    padding-left: $spacing-base;
    padding-right: $spacing-double + $spacing-base;


    span {
      display: block;
    }

    strong {
      font-size: $font-size-h1 - 0.25;
    }
  }

  &-price-sec {
    display: flex;
    background-color: $color-white;
    padding: 5px 10px;
    border-radius: 50rem;
    margin-top: auto;

    .btn {
      flex: 1;
      border-radius: 50rem;
      font-size: $font-size-h4;
    }
  }
}

.nft-more {
  padding-top: $spacing-double + $spacing-double;
  padding-bottom: $spacing-double + $spacing-double;

  h3 {
    padding-bottom: $spacing-base;
  }
}


.livestream-table-view {
  .table {
    tr {
      th {
        min-width: 200px;

        &:nth-of-type(1),
        &:nth-of-type(8) {
          min-width: inherit;
        }

        &:nth-of-type(3),
        &:nth-of-type(4) {
          min-width: 150px;
        }

        &:nth-of-type(6) {
          min-width: 120px;
        }

        &:nth-of-type(7) {
          min-width: 155px;
        }
      }
    }
  }
}