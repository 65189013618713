.chat-user {
  display: flex;
  border: 1px solid transparent;
  padding: 10px;
  text-decoration: none;
  color: $color-body;
  margin-bottom: $spacing-base - 5;
  transition: all .35s;
  &:hover {
    box-shadow: 0 4.5px 3.6px rgba($color-black, 0.01), 0 12.5px 10px rgba($color-black, 0.015), 0 30.1px 24.1px rgba($color-black, 0.02), 0 100px 80px rgba($color-black, 0.03);
    // border-color: $color-body;
  }
}

.chat-container {
  border: 1px solid $color-body;
  background-color: $color-body;
  height: calc(100vh - 170px);
}

.chat-sec-top {
  border-radius: 0rem;
  padding: 10px 15px;
  box-shadow: 0 4.5px 3.6px rgba($color-black, 0.01), 0 12.5px 10px rgba($color-black, 0.015), 0 30.1px 24.1px rgba($color-black, 0.02), 0 100px 80px rgba($color-black, 0.03);
  display: flex;
  align-items: center;
  background-color: $color-white;
  &-img {
    margin-right: $spacing-base - 5;
    img {
      max-width: 40px;
    }
  }
}

.chat-user-sec {
  height: calc(100vh - 170px);
  background: $color-white;
  box-shadow: 0 4.5px 3.6px rgba($color-black, 0.01), 0 12.5px 10px rgba($color-black, 0.015), 0 30.1px 24.1px rgba($color-black, 0.02), 0 100px 80px rgba($color-black, 0.03);
  padding: 0;
  border-right: 1px solid $color-body;
  overflow-y: auto; 
  .chat-user-info {
    flex: 9999;
    a {
      text-decoration: none;
    }
  }
  @media only screen and (max-width:991px) {
    padding: 0 10px;
  }
}

.chat-username {
  display: flex;
  font-size: $font-size-sml;
  font-weight: $font-weight-bold;
  color: $color-body;
  align-items: center;
  .chat-time {
    margin-left: auto;
    font-size: $font-size-xsml - .1;
  }
}

.chat-user-status {
  font-size: $font-size-xsml;
}

.chat-user-img {
  flex: 0 0 40px;
  margin-right: 10px;
  img {
    max-width: 100%;
  }
}

.chat-messages {
  display: flex;
  flex-direction: column;
  // max-height: 600px;
  overflow-y: auto;
  .chat-message-left,
  .chat-message-right {
    display: flex;
    flex-shrink: 0
  }
}

.chat-message-left {
  margin-right: auto
}

.chat-message-right {
  flex-direction: row-reverse;
  margin-left: auto
}

.chat-sec {
  background-color: $color-body;
  padding: 0 0 $spacing-base;
  height: calc(100vh - 215px);
  overflow-y: auto;
}

// Chat Messages
.chat-messages {
  padding: 1.5rem;
  .chat-message-right {
    padding: 1rem;
    .chat-message-img {
      margin-left: 15px;
    }
    .chat-message-box {
      border-radius: 50px 0px 50px 50px;
    }
  }
  .chat-message-left {
    padding: 1rem;
    .chat-message-img {
      margin-right: 15px;
      flex: 0 0 50px;
    }
  }
  .chat-message-box {
    flex-shrink: 1;
    border-radius: 0 50px 50px;
    padding: .5rem 2rem;
    background-color: $color-white;
    &-textby {
      display: flex;
      font-size: $font-size-xsml;
      &-user {
        font-weight: $font-weight-bold;
      }
    }
    &-desc {
      font-size: $font-size-xsml;
    }
  }
  .chat-message-time {
    font-size: $font-size-xsml;
    margin-left: auto;
  }
  .chat-message-img {
    img {
      max-width: 40px;
    }
  }
}

.chat-sec-btm {
  padding: 0px 25px;
  border-radius: 50rem;
  background: $color-white;
  box-shadow: 0 4.5px 3.6px rgba($color-black, 0.01), 0 12.5px 10px rgba($color-black, 0.015), 0 30.1px 24.1px rgba($color-black, 0.02), 0 100px 80px rgba($color-black, 0.03);
  position: relative;
  z-index: 9;
  bottom: 30px;
  margin: 0 15px;
  .input-group {
    align-items: center;
    input {
      border: none;
      padding: 20px 10px;
    }
  }
}

.subscribers-chat {
  #pills-tabContent {
    height: calc(100% - 50px);
  }
  .container-custom,
  #SubscriberInbox,
  #SubscriberInbox .row {
    height: 100%;
  }
}
.chat-message-container {
  height: calc(100vh - 330px);
  overflow-y: auto;
}

.dropdownReport .dropdown-toggle:after{
  display: none;
}