.toggle-switch-container {
  position: relative;
  display: inline-block;
  text-align: left;
  top: 8px;
}
.toggle-checkbox {
  display: none;
}
.toggle-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
  padding-left: 0;
}
.toggle-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.toggle-inner:before,
.toggle-inner:after {
  float: left;
  width: 50%;
  height: 36px;
  padding: 0;
  line-height: 36px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}
.toggle-inner:before {
  content: attr(data-switch);
  padding-left: 15px;
  background-color: $color-primary-dk;
  color: #fff;
}
.toggle-inner:after {
  content: attr(data-switch-one);
  padding-right: 10px;
  background-color: #bbb;
  color: #fff;
  text-align: right;
}
.toggle-switch {
  display: block;
  width: 24px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 70%;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.toggle-checkbox:checked + .toggle-label .toggle-inner {
  margin-left: 0;
}
.toggle-checkbox:checked + .toggle-label .toggle-switch {
  right: 0px;
  left: auto;
}