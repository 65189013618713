h3,
.h3 {
  font-size: $font-size-h3;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $font-family-heading;
  font-weight: 600;
  margin-bottom: 10px;
  letter-spacing: 0px;
}