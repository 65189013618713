.dive-in {
  background-color: $color-body;
  border-radius: 0px;
  padding-top: 30px;
  padding-bottom: 40px;

  @include respond-above(sm) {
    border-radius: 50px;
    padding-top: 70px;
    padding-bottom: 80px;
  }

  h2 {
    color: $color-white;
    margin-bottom: 30px;

    @include respond-above(sm) {
      margin-bottom: 70px;
    }
  }

  .slider-dive-in {
    margin-bottom: 30px;

    @include respond-above(sm) {
      margin-bottom: 60px;
    }

    @include respond-below(sm) {
      padding-left: 20px;
      padding-right: 20px;
    }

    text-align: left;
  }

  .slider-item {
    a {
      text-decoration: none;
    }

    @include respond-above(xs) {
      padding-top: $spacing-double;
      padding-left: $spacing-base;
      padding-right: $spacing-base;
      border-left: 1px solid rgba($color-form-border, .5);
    }

    &-title {
      font-size: $font-size-h3;
      font-family: $font-family-body;
      color: $color-white;
      font-weight: $font-weight-light;
      margin-bottom: 0;
    }

    &-channel {
      font-size: $font-size-sml;
      color: $color-white;
      font-weight: $font-weight-semibold;
      margin-bottom: $spacing-base;
      display: block;
    }

    &-img {
      // margin-bottom: $spacing-base;
      border-radius: $border-radius-default + $border-radius-default;

      img {
        width: 100%;
        border-radius: $border-radius-default + $border-radius-default;
      }
    }

    &-footer {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      &-left {
        color: $color-white;
        font-size: $font-size-xsml;
        margin-right: auto;
        font-style: italic;
      }
    }
  }

  .slider-dive-in {
    .slick-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 100%;
      background-color: transparent;
      border: none;
      font-size: 0;
      color: $color-primary;

      @include respond-below(sm) {
        right: calc(100% - 20px);
      }

      &:before {
        font-family: 'bootstrap-icons' !important;
        font-size: $font-size-h3;
      }

      &.slick-next {
        left: 100%;

        @include respond-below(sm) {
          left: calc(100% - 20px);
        }

        &:before {
          content: "\f285";
        }
      }

      &.slick-prev {
        &:before {
          content: "\f284";
        }
      }

      &.slick-disabled {
        &::before {
          color: $color-gray-lt;
        }
      }

      &:hover {
        color: $color-primary-hover;
      }
    }
  }

  &.detail {
    text-align: center;
    border-radius: 0px;
    background-color: $color-white;
    position: relative;
    z-index: 1;
    min-height: 100vh;

    h2 {
      margin-bottom: 0;
      color: $color-primary-dk;
    }

    .slider-item {
      border-left: none;
      text-align: left;

      &-header {
        .slider-item-footer-left {
          font-size: $font-size-body;
        }
      }

      &-footer-left {
        font-style: normal;
      }
    }

    .livestream-row {
      margin-bottom: $spacing-double - 10;

      @include respond-above(sm) {
        border-bottom: 1px solid $color-black-4e;

        padding-bottom: $spacing-double + $spacing-double;
      }

      &:last-of-type {
        border-bottom: none;
      }

      .slider-item-img {
        margin-bottom: $spacing-base;
      }
    }

    .main-content {
      background-color: transparent;

      .dive-in-top-slider {
        width: 100%;
      }
    }

    .category-listing {
      width: 100%;
      position: static;
    }
  }
}

.category-listing {
  position: fixed;
  top: 70px;
  text-align: left;
  height: calc(100% - 71px);
  // overflow-y: auto;
  width: 250px;

  ul {
    padding-top: 15px;
    list-style: none;
    padding-left: 0;

    li {
      a {
        display: block;
        text-decoration: none;

      }
    }
  }
}


.nft-price-info {
  font-size: 14px;
  display: flex;
  padding: 0 10px 10px;
  flex-wrap: wrap;

  .nft-rating {
    margin-left: auto;
  }

}

.category-listing-main-content {
  .featured-card {
    width: 100%;
    margin-bottom: 30px;
  }
}

.checkout {
  background: radial-gradient(circle, #838383 0%, #191919 100%);
}


.livestream-table-view tr {
  

  
  td {
    min-height: 65px;
    vertical-align: middle;

    .btn,
    button, .guest-icon {
      // padding: 0 10px;
      background: transparent;
      border: none;
      color: #000;
      line-height: 10px;

    

      span {
        margin-left: auto !important;

        i {
          // padding-right: 0 !important;
        }
      }

      &:after {
        border: none;
      }
    }

    .dropdown-item {
      button {
        padding-left: 0;
        padding-right: 4px;
        vertical-align: middle;
      }

      i {
        width: 24px;
        padding-right: 4px;
        display: inline-block;

        font-size: 16px;
      }
    }

    .dropdown::before {
      top: 10px;
      position: absolute;
      left: 5px;
      z-index: -1;
    }

    &.action-icons {
      // .guest-icon {
      //   i {
      //     font-size: 18px;
      //     text-align: center;
      //     margin-left: 1px;
      //   }
      // }
      .guest-icon {
        margin-left: 4px;
      }
      .btn-share {
        &.share-dropdown {
          position: static;
          background-color: transparent;
          .btn {
            background-color: transparent;
            color: #000;
            width: auto;
            height: auto;
            i {
              margin-right: 0;
            }
          }
        }
        i {
          font-size: 1.2rem;
        }
      }
        
      i {
        font-size: 25px;
        padding: 0.3rem 0.6rem;
        // margin: 0 5px;
        &.bi-x {
          font-size: 30px;
        }
        &.bi:hover{
          color: #000;
          text-decoration: none;
          opacity: 0.75;
          background-color: #e8eaed;
          border-radius: 50%;
          transition: background-color 0.3s;
        }
      }
   
    }
  }
 
}



.main-content-info {
  display: flex;
  margin: 0 -15px 64px;
  flex-wrap: wrap;

  @include respond-above(lg) {
    margin: 0 -15px 64px;
  }

  &-card {

    display: flex;
    padding: 0 15px;
    flex-wrap: wrap;
    flex: 0 0 100%;
    flex-direction: column;
    margin-bottom: 10px;

    @include respond-above(sm) {
      flex-direction: row;
      flex: 0 0 calc(33.333%);
      margin-bottom: 0px;

    }

    @include respond-above(lg) {
      padding: 0 15px;
    }

    &:last-of-type {
      margin-right: 0;
    }

    i {
      width: 54px;
      height: 54px;
      border-radius: $border-radius-default;
      background-color: #FFDFDF;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
      font-size: $font-size-h3;
      color: $color-hamburger;
    }

    strong {
      font-size: $font-size-h3;
      color: $color-hamburger;
    }
  }

  &-card-content {
    width: 100%;
    padding: 25px 20px;
    border: 1px solid $color-divider;
    border-radius: $border-radius-default;
    display: flex;


  }

  &-card-desc {
    display: flex;
    flex-direction: column;

  }
}

.filter-sec-category-list {
  display: flex;
  align-items: center;
  width: calc(100% - 300px);
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;

  @include respond-below(md) {
    width: calc(100% - 0px);
    display: none;
  }

  @include respond-below(sm) {
    display: block;
    text-align: center;
    position: relative;
  }

  strong {
    font-size: 18px;
    flex: 0 0 150px;
    border-right: 1px solid #ddd;
    margin-right: 20px;
    padding-right: 20px;

    @include respond-below(sm) {
      margin-right: 0;
      padding-right: 0;
    }
  }

  .arrow {
    border: 0;
    color: $color-primary;
    font-size: 24px;
    background-color: transparent;
    padding: 0 15px;

    @include respond-below(sm) {
      position: absolute;
      left: 0;
      top: 30px;
      background: #fff;
    }

    &.next {
      @include respond-below(sm) {
        right: 0;
        left: auto;
      }
    }

    &:hover {
      color: $color-primary-dk;
    }
  }
}

.filter-sec-category-list ul {
  display: flex;
  // flex-wrap: nowrap;
  margin-bottom: 0;
  padding-top: 0;
  overflow: hidden;
  padding: 0 10px;
  width: calc(100% - 100px);
  scroll-behavior: smooth;

  @include respond-below(sm) {
    padding-top: 10px;
    width: calc(100% - 0px);
  }

}

.filter-sec-category-list li {
  flex: 0 0 auto;
  padding-right: 50px;

  a {
    color: $color-black-2e;
    font-size: 18px;

    @include respond-below(sm) {
      font-size: 16px;
    }

    &:hover, &.active {
      color: $color-body;
    }
    &.active {
      font-weight: 600;
    }
  }


}

.btn-check:active+.btn-primary:focus,
.btn-check:checked+.btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.scheduleActionBtns.dropdown,
.scheduleActionBtns.dropend,
.scheduleActionBtns.dropstart,
.scheduleActionBtns.dropup {
  position: static;
}

.scheduleActionBtns>.btn::before {
  font-family: "bootstrap-icons";
  content: "\F5D3";
}

.livestream-table-view .bi-trash3:hover{
  color: #000;
  text-decoration: none;
  opacity: 0.75;
  background-color: #e8eaed;
  border-radius: 50%;
  transition: background-color 0.3s;
}
 .bi-trash3{
  font-size: 20px;
  padding: 0.3rem 0.6rem;
}
// .livestream-table-view tr:hover{
//   background-color:white!important;
//  }
