.create-livestream {
    padding: 0;

    .card-boxed {
        h3 {
            font-size: $font-size-body + .05;
        }
    }

    &-steps {
        display: flex;
        width: 100%;

        @include respond-below(sm) {
            flex-direction: column;
        }
    }

    &-left,
    &-right {
        width: 50%;
    }

    @include respond-below(lg) {
        &-left {
            width: 60%;
        }

        &-right {
            width: 40%;
        }

    }

    @include respond-below(sm) {
        &-left {
            width: 100%;

        }

        &-right {
            width: 100%;
        }

    }


    &-left {
        padding: 30px 30px 0;

        @media only screen and (max-width:1440px) {
            padding: 15px 30px 0;
        }

        @include respond-below(sm) {
            padding-bottom: 20px;
        }

        .MuiOutlinedInput-input {

            @media only screen and (max-width:1440px) {
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }

        .MuiInputLabel-formControl {

            @media only screen and (max-width:1440px) {
                top: -6px;
            }
        }


        h3 {
            padding-bottom: 0px;
            font-size: 1.5rem;
            color: $color-hamburger;
            margin-bottom: 0;

            a {

                color: $color-hamburger;
                text-decoration: none;
            }

            i {
                width: 38px;
                height: 38px;
                border-radius: 50%;
                border: 1px solid $color-border-light;
                color: $color-body;
                display: inline-block;
                font-size: 20px;
                text-align: center;
                margin-right: 10px;
                line-height: 35px;
                vertical-align: middle;

                &:hover {
                    background-color: $color-body-light;
                    border-color: $color-body-light;
                    color: $color-white;
                }
            }

            @include respond-below(sm) {
                font-size: 1.5rem;
            }
        }

        .css-1bw0nnu-MuiStep-root {
            padding-left: 10px;
            padding-right: 10px;


        }

        .css-m5vj9m-MuiStepper-root {
            justify-content: center;


            @include respond-below(xl) {
                margin-right: auto;
            }

            @media only screen and (max-width:1330px) {
                margin-right: 0;

            }

            @include respond-below(md) {
                margin-top: 20px;
                margin-right: auto;
            }
        }

        .css-ascpo7-MuiStepLabel-root {

            align-items: center;

            @media only screen and (max-width:1330px) {
                flex-direction: column;

            }

        }

        .css-j5w0w9-MuiStepConnector-root {
            flex: 0;
        }

        .MuiStepIcon-root {
            width: 30px;
            height: 30px;

            @media only screen and (max-width:1330px) {
                margin-bottom: 10px;

            }

        }

        .MuiStepper-root {
            .MuiStepLabel-iconContainer {

                .Mui-active,
                .Mui-completed {
                    box-shadow: 0 2px 7px 2px rgba(0, 0, 0, 0.08);
                    border-radius: 50%;
                    color: $color-body;
                }


            }
        }

        .Mui-active,
        .Mui-completed {

            .step-number {
                color: $color-body-light;
            }

            circle {
                color: $color-white;
            }

            text {
                fill: $color-body;
            }
        }

        .guest-list {
            ul {
                padding-left: 0;
                margin-bottom: 150px;
                flex-direction: column;
            }

            ul,
            li {
                display: flex;
            }

            li {
                width: 100%;
                border-bottom: 1px solid $color-body;
                display: flex;
                padding: 10px 0;

                &:hover {
                    .guest-list-actions {
                        opacity: 1;
                    }
                }
            }

            &-actions {
                margin-left: auto;
                display: flex;
                opacity: 0;

                button {
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }

            &-mail {
                margin-right: 40px;
            }
        }

    }

    &-right {
        &-bg-img {
            min-height: 100vh;
            height: 100%;

            @include respond-below(sm) {
                min-height: inherit;
                height: auto;
            }
        }

        .banner-cover.livestream {
            min-height: 100vh;
            height: 100%;

            padding: 0 10px 10px;

            @include respond-below(sm) {
                align-items: flex-start;
            }

            @include respond-below(sm) {
                min-height: inherit;
                height: auto;
            }

            .banner-cover-profile {
                margin-left: 0px;


                // width: calc(100% - 30px);
                width: calc(60% - 30px);
                margin: 0;
                padding-bottom: 0px;
                flex: 0 0 33.333%;

                @include respond-below(sm) {
                    width: 100%;
                }

                &__right {
                    width: 100%;
                    max-height: 550px;
                }



            }



        }
    }

    &.nft {
        .create-livestream-left {
            @include respond-below(md) {
                width: 100%;
            }

            .MuiStepper-root {
                margin-right: 0;
                margin-top: 0;

                @media only screen and (max-width:575px) {
                    margin-top: 20px;
                    margin-right: auto;
                }
            }
         

        }

        .create-livestream-right {
            @include respond-below(md) {
                display: none;
            }
        }


    }

    .checkout-card-latest {
        flex: 0 0 66.6667%;
        flex-wrap: wrap;
        padding-bottom: 0;

        @include respond-below(sm) {
            padding-top: 15px;
            flex-direction: column;
        }
    }

    .checkout-card {
        max-width: 100%;
        padding-bottom: 0;
        flex: 1;

        @include respond-below(sm) {
            padding-left: 5px;
            padding-right: 5px;
            margin-bottom: 10px;
        }

        .btn {

            &:hover,
            &:focus,
            &.active {
                background: #ffffff !important;
                color: #2e2e2e !important;
                cursor: auto;
            }
        }

        .checkout-card-title {
            font-size: 16px;
        }

        .checkout-card-price {
            font-size: 20px;

            &.sml {
                font-size: 22px;
            }
        }

        .checkout-card-info li {
            font-size: 13px;
        }

        .btn {
            font-size: 13px;
        }

        padding-bottom: 0;
    }


}

.modal {
    .card-nft {
        width: 75% !important;
    }
}

.uploaded-img {
    width: 400px;
    height: 400px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.uploaded-img img {
    width: 100%;
    height: auto;
}

.uploaded-img::after {
    content: "";
    height: 100%;
    width: 100%;
    background-position: center center;
    background-size: cover;
    left: 0;
    top: 0;
    position: absolute;
    filter: blur(8px);
    z-index: -1;
}

// .css-1atslg0-MuiTypography-root {
//     font-family: inherit;
// }

.create-livestream .create-livestream-steps {
    .create-livestream-right .banner-cover.livestream .banner-cover-profile {
        max-width: 50%;
        min-width: 50%;

        @include respond-below(sm) {
            max-width: 100%;
            min-width: 100%;
        }
    }
}

.create-livestream-right-bg-img.bgImg .bgImg {
    height: 100vh;
    width: 100%;
    background-size: cover;
    position: absolute;
    filter: blur(3px);

}

.create-livestream-right-bg-img.bgImg img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

.main-content-back2 {
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;


    i {
        width: 29px;
        height: 29px;
        border-radius: 50%;
        border: 1px solid #DCDCDC;
        background-color: #FFFFFF;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        font-size: 18px;
    }

    i:hover {
        background-color: $color-body-light;
        border-color: $color-body-light;
        color: $color-white;
    }

    .btn-back {
        position: absolute;
        top: 20px;
        left: 10px;
        z-index: 1;
    }
}

 .wait-popup {
    text-align: center!important;
    width: 100%!important;
    margin-top: 7px;
    /* padding: 56px 0; */
    padding-top: 52px;
}

.blockchain-wait .pt-5 {
    padding-top: 0 !important;
}