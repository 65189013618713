.settings-sec {
  padding: 0px;

  h3 {
    border-bottom: 1px solid $color-divider;
    padding-bottom: $spacing-base;
  }

  .upload-btn-sec {
    display: flex;

    @include respond-below(sm) {
      margin-top: 15px;
    }

    .btn {
      margin-right: 10px;

      @include respond-above(sm) {
        margin-right: 15px;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .uploaded-img {
    width: 77px;
    height: 77px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    img {
      border-radius: 50%;
    }
  }
}