.card {
  font-size: $font-size-sml;
  border-radius: $border-radius-default;
  border-color: $color-body;

  &-header,
  &-footer {
    background-color: transparent;
  }

  &-header {
    display: flex;
  }

  &-header__right {
    margin-left: auto;
  }

  &-header__left__status {
    color: $color-body;
  }

  &-header__right,
  &-header__left {
    display: flex;
    flex-direction: column;
  }

  &-body {
    ol {
      padding-left: $spacing-base;
    }
  }

  // Card Post
  &.card-post {
    padding: 15px 0px;
    // margin-bottom: $spacing-base - 10;
    border-color: transparent;
    border-bottom-color: $color-body;
    border-radius: 0;

    .badge {
      color: $color-gray-lt;
      background-color: $color-gray;
      margin-left: auto;
    }

    .card-post__header {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-top: 10px;
      order: 2;
      margin-top: 10px;

      ul {
        padding-left: 0;
        list-style: none;
        padding-right: $spacing-base;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 0px;
        font-size: $font-size-xsml - .1rem;

        @media only screen and (min-width: 992px) {
          font-size: $font-size-xsml;
        }

        li {
          padding-right: $spacing-base;

          &:after {
            content: "|";
            margin-left: 10px;
          }

          &:last-of-type {
            &:after {
              content: "";
              margin-left: 0;
            }
          }
        }
      }
    }

    .card-post__content {
      display: flex;
      flex-wrap: wrap;
      position: relative;

      @media only screen and (min-width: 992px) {
        position: static;
      }

      &-img {
        margin-right: $spacing-base;

        i {
          font-size: $font-size-h3;
          line-height: 1.3;
        }
      }

      &-left {
        display: flex;
        flex-wrap: wrap;
        margin-right: $spacing-base;
        flex: 0 0 100%;

        @media only screen and (min-width: 992px) {
          flex: 0 0 60%;
        }
      }

      &-right {
        display: none;
        margin-top: $spacing-base;
        text-align: right;

        .btn {
          margin-left: 4px;
          padding-left: 5px;
          padding-right: 5px;
        }

        @media only screen and (min-width: 992px) {
          flex: 9999;
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
        }

        &.active {
          display: block;
          width: 200px;
          background: $color-white;
          position: absolute;
          right: 0px;
          top: 13px;
          border: 1px solid $color-gray;
          padding: 0px;
          z-index: 1;
          border-radius: 4px;

          a {
            display: block;
            border: none;
            border-bottom: 1px solid $color-body;
            text-align: left;
            margin-left: 0;
            color: $color-body;
            font-size: $font-size-xsml;

            i {
              margin-right: $spacing-base - 10;
            }

            &:hover {
              background-color: transparent;
              color: rgba($color-primary, 1);
            }
          }
        }
      }

      &-desc {
        flex: 9999;

        h3 {
          font-size: $font-size-h3 - .25rem;

          i {
            @media only screen and (max-width: 767px) {
              font-size: $font-size-h3;
              vertical-align: middle;
            }
          }

          a {
            text-decoration: none;
            color: $color-body;

            &:hover {
              color: $color-primary;
            }
          }
        }

        p {
          margin-bottom: 0;
        }
      }

      .morecontent {
        .more-content-show {
          display: inline;
        }
      }

      .morecontent span {
        display: none;
      }

      .btn-action {
        position: absolute;
        right: 0;
        font-size: $font-size-h3 - 4;
      }
    }

    &.video {
      .card-post__content-img {
        i {
          color: $color-orange;
        }
      }

      h3 {
        i {
          color: $color-orange;
        }
      }
    }

    &.audio {
      .card-post__content-img {
        i {
          color: $color-primary;
        }
      }

      h3 {
        i {
          color: $color-primary;
        }
      }
    }

    &.static {
      @media only screen and (max-width: 767px) {
        border-color: $color-primary;

        h3 {
          i {
            @media only screen and (max-width: 767px) {
              color: $color-primary;
            }
          }
        }
      }
    }

    &.polls {
      @media only screen and (max-width: 767px) {
        border-color: $color-primary;

        h3 {
          i {
            @media only screen and (max-width: 767px) {
              color: $color-primary;
            }
          }
        }
      }
    }

    &.polls {
      @media only screen and (max-width: 767px) {
        border-color: $color-primary;

        h3 {
          i {
            @media only screen and (max-width: 767px) {
              color: $color-primary;
            }
          }
        }
      }
    }
  }

  // Card boxed
  &.card-boxed {
    min-height: 200px;
    text-decoration: none;
    transition: all .35s;
    border: none;
    color: $color-body;

    text-align: center;

    transition: all .35s;

    // box-shadow: 0 1.5px 1.8px rgba(0, 0, 0, 0.022), 0 3.7px 4.6px rgba(0, 0, 0, 0.031);
    i {
      font-size: $font-size-body + .5rem;
      transition: all .35s;
    }

    .card-boxed-img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      min-height: 150px;
      border: 1px solid #e6e6e6;
      border-radius: 6px;
      padding: 10px;
      position: relative;
      z-index: 0;

      &:after {
        content: "";
        border: 1px solid #e6e6e6;
        border-radius: 6px;
        width: calc(100% - 25px);
        height: calc(100% - 25px);
        position: absolute;
        z-index: -1;

      }
    }

    h3 {
      margin-top: 10px;
      font-weight: normal;
      font-size: $font-size-h6 + 0.05;
    }

    #Video {
      path {
        &:first-of-type {

          transform: translate(-151.545px, -227.063px) !important;
          transition: all .35s;
        }
      }
    }

    .audio {
      #Audio {
        transition: all .35s;

        rect {
          &:first-of-type {
            transition: all .35s;
          }
        }

        ellipse {
          transform: translate(55.817px, 56.921px) !important;
          transition: all .35s;

        }

        g g {
          path {
            &:first-of-type {
              transform: translate(-567.141px, -277px) !important;
              transition: all .35s;
            }
          }
        }
      }
    }



    .text-img-svg {
      .text-anim {
        // transform:translate(-331.248px, -436.911px);
        animation: text 1s ease 0s infinite alternate both;
        -moz-animation: text 1s ease 0s infinite alternate both;
      }
    }


    &:hover {

      // box-shadow: 0 1.5px 1.8px rgba(0, 0, 0, 0.022), 0 3.7px 4.6px rgba(0, 0, 0, 0.031), 0 7.6px 9.4px rgba(0, 0, 0, 0.039), 0 15.7px 19.3px rgba(0, 0, 0, 0.048), 0 43px 53px rgba(0, 0, 0, 0.07);
      i {
        font-size: $font-size-h1;
      }

      .card-boxed-img {
        #Video {
          path {
            &:first-of-type {

              transform: translate(-155.545px, -227.063px) !important;
            }
          }
        }

        #Audio {
          rect {
            &:first-of-type {
              fill: #18cc73;
              transition: all .35s;
            }
          }

          ellipse {
            transition: all .35s;

            animation: myAnim 1s ease 0s infinite alternate both;
          }

          g g {
            path {
              &:first-of-type {

                animation: mic 1s ease 0s infinite alternate both;
                -moz-animation: mic 1s ease 0s infinite alternate both;
                transition: all .35s;
              }
            }
          }
        }

        .static-image-svg {
          .first-tree {
            ellipse {
              transform: translate(781.125px, 205.919px) rotate(0deg);
              animation: static 1s ease 0s infinite alternate both;
              -moz-animation: static 1s ease 0s infinite alternate both;
            }
          }

          .second-tree {
            ellipse {
              transform: translate(833.715 204.919) rotate(0deg);
              animation: static1 1s ease 0s infinite alternate both;
              -moz-animation: static1 1s ease 0s infinite alternate both;
            }
          }
        }

        .polls-img-svg {
          .first-line {
            // transform:translate(-331.248px, -436.911px);
            animation: polls 1s ease 0s infinite alternate both;
            -moz-animation: polls 1s ease 0s infinite alternate both;
          }

          .second-line {
            // transform:translate(-331.248px, -436.911px);
            animation: polls 1s ease-in-out 0.25s infinite alternate both;
            -moz-animation: polls 1s ease-in-out 0.25s infinite alternate both;
          }

          .third-line {
            // transform:translate(-331.248px, -436.911px);
            animation: polls 1s ease 0.35s infinite alternate both;
            -moz-animation: polls 1s ease 0.35s infinite alternate both;
          }

          #Ellipse_43 {
            animation: pollsEllipse 1s ease 0s infinite alternate both;
            -moz-animation: pollsEllipse 1s ease 0s infinite alternate both;
          }

          #Ellipse_44 {
            animation: pollsEllipse 1s ease 0.5s infinite alternate both;
            -moz-animation: pollsEllipse 1s ease 0.5s infinite alternate both;
          }
        }

        .livestream-public {
          #group {
            animation: livestream 1s ease 0s infinite alternate both;
            -moz-animation: livestream 1s ease 0s infinite alternate both;
          }

          #group-2 {
            animation: livestream 1s ease 0.15s infinite alternate both;
            -moz-animation: livestream 1s ease 0.15s infinite alternate both;
          }

          #group-3 {
            animation: livestream 1s ease 0.3s infinite alternate both;
            -moz-animation: livestream 1s ease 0.3s infinite alternate both;
          }

          #group-4 {
            animation: livestream 1s ease 0.45s infinite alternate both;
            -moz-animation: livestream 1s ease 0.45s infinite alternate both;
          }

          #group-5 {
            animation: livestream 1s ease 0.75s infinite alternate both;
            -moz-animation: livestream 1s ease 0.75s infinite alternate both;
          }

          #group-6 {
            animation: livestream 1s ease 0.6s infinite alternate both;
            -moz-animation: livestream 1s ease 0.6s infinite alternate both;
          }
        }

        .sound-wave {
          animation: livestream1 1s ease 0s infinite alternate both;
          -moz-animation: livestream1 1s ease 0s infinite alternate both;
        }

        .sound-wave1 {
          animation: livestream1 1s ease 0.25s infinite alternate both;
          -moz-animation: livestream1 1s ease 0.25s infinite alternate both;
        }

        .livestream-individual-user {
          animation: livestream 1s ease 0.25s infinite alternate both;
          -moz-animation: livestream 1s ease 0.25s infinite alternate both;
        }

        .livestream-private-user {
          animation: livestream 1s ease 0s infinite alternate both;
          -moz-animation: livestream 1s ease 0s infinite alternate both;
        }

      }
    }
  }
}

.create-content-sec {
  h3 {
    font-weight: 300;
  }
}

@keyframes myAnim {
  0% {
    transform: translate(55.817px, 56.921px);
  }

  100% {

    transform: translate(75.817px, 56.921px);
  }
}

@-moz-keyframes myAnim {
  0% {
    transform: translate(55.817px, 56.921px);
  }

  100% {

    transform: translate(75.817px, 56.921px);
  }
}

@keyframes mic {
  0% {
    transform: translate(-567.141px, -277px);
  }

  100% {
    transform: translate(-567.141px, -280px);
  }
}

@-moz-keyframes mic {
  0% {
    transform: translate(-567.141px, -277px);
  }

  100% {
    transform: translate(-567.141px, -280px);
  }
}

@keyframes static {
  0% {
    transform: translate(781.125px, 205.919px) skewY(0deg);
  }

  100% {
    transform: translate(781.125px, 205.919px) skewY(8deg);
  }
}

@keyframes static1 {
  0% {
    transform: translate(833.715 204.919) skewY(0deg);
  }

  100% {
    transform: translate(833.715 204.919) skewY(8deg);
  }
}

@keyframes polls {
  0% {
    fill: #e4e4e4;
    translate: (-327.271px, -273.756px)
  }

  100% {
    fill: #18cc73;
    translate: (-279.271px, -273.756px)
  }
}

@keyframes polls1 {
  0% {
    fill: #e4e4e4;
    translate: (-327.271px, -273.756px)
  }

  100% {
    fill: #18cc73;
    translate: (-279.271px, -273.756px)
  }
}

@keyframes polls2 {
  0% {
    fill: #e4e4e4;
    translate: (-327.271px, -273.756px)
  }

  100% {
    fill: #18cc73;
    translate: (-279.271px, -273.756px)
  }
}

@keyframes pollsEllipse {
  0% {
    fill: #e4e4e4;
  }

  100% {
    fill: #18cc73;
  }
}

@keyframes livestream {
  0% {
    fill: #e4e4e4;
  }

  100% {
    fill: #18cc73;
  }
}

@keyframes livestream1 {
  0% {
    fill: #18cc73;
  }

  100% {
    fill: #e4e4e4;
  }
}



// @keyframes text {
//   0% {
//     transform:translate(-331.248px, -402.911px);
//     path:("M340.513,415.072a.69.69,0,1,0,0,1.38H358.7a.69.69,0,0,0,.023-1.379h-18.21Z");
//   }
//   100% {
//     d:path("M340.513,450.291a.69.69,0,1,0,0,1.38h36.7a.69.69,0,0,0,.023-1.379h-18.21Z");
//     transform:translate(-331.248px, -436.911px)
//   }
// }


// @-moz-keyframes static {
//   0% {
//     transform: translate(781.125px,205.919px) skewX(-10deg, -10deg) ;
//   }

//   100% {
//     transform: translate(781.125px,205.919px) skewX(3deg) ;
//   }
// }