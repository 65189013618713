.title-w-bdr {
  position: relative;
  font-size: $font-size-h3;
  margin-bottom: $spacing-base;
  font-weight: $font-weight-light;
  span {
    display: inline-block;
    padding-bottom: $spacing-base - 5;
    &:after {
      position: absolute;
      content: "";
      width: 40px;
      height: 1px;
      background-color: $color-secondary;
      top: 100%;
      left: 0;
      content: none;
    }
  }
}