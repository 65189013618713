.site-header {
  background-color: $color-white;
  // box-shadow: $box-shadow-default;
  // z-index: 9999;
  z-index: $zindex-sticky;
  top: 0;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  position: -webkit-sticky;
  position: sticky;
  -webkit-transition: all .25s ease;
  transition: all .25s ease;
  border-bottom: 1px solid $color-divider;

  .container-fluid {
    padding: 0 $spacing-base;

    @include respond-above(sm) {
      padding: 0 $spacing-double;
    }
  }

  .navbar-brand {
    justify-content: center;
    flex: 0 0 100%;

    display: flex;

    @include respond-above(sm) {
      padding-right: $spacing-base;
    }

    img {
      max-height: 65px;
      width: 130px;

      @include respond-above(sm) {
        max-height: 65px;
      }

      &.logo-white {
        display: none;

        @include respond-above(sm) {
          display: block;
        }
      }

      &.logo-mobile {
        display: none;

        @include respond-below(sm) {
          display: block;
        }

      }
    }
  }

  .navbar-nav {
    margin-left: auto;
    flex-direction: row;

    @include respond-below(sm) {
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;

    }

    li {
      a {
        font-size: $font-size-body;
        position: relative;
        color: $color-body;

        @include respond-below(sm) {
          padding-top: 0;
          padding-bottom: 0;
        }

        &:after {
          content: '';
          position: absolute;
          top: 80%;
          left: 50%;
          background-color: $color-body;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          transform: translate(-50%, 10px);
          opacity: 0;
          transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);

          @media only screen and (max-width:767px) {
            top: 90%;
          }
        }

        &:hover {
          opacity: .7;
        }

        &.active {
          &:after {
            transform: translate(-50%, 0);
            opacity: 1;
          }
        }
      }

      text-transform: uppercase;
      margin-right: $spacing-double;

      @include respond-below(sm) {

        margin: 0 $spacing-base;
      }

      @include respond-below(xs) {

        margin: 0 $spacing-base - 5;
      }


      &:first-of-type {
        margin-left: 0;
      }

      // &:nth-of-type(2) {
      //   a {
      //     @include respond-above(sm) {
      //       font-size: 24px;
      //       font-weight: 600;
      //       padding-top: 0px;
      //       padding-bottom: 0px;
      //     }
      //   }
      // }
    }
  }


  &__left {
    display: flex;
    align-items: center;
    margin-right: -180px;

    @include respond-below(sm) {
      flex: 0 0 auto;
      margin: 0 auto;
    }
  }

  &__right {
    .dropdown-menu {
      width: 200px;
      border: none;
    }

    .dropdown-item {
      font-size: $font-size-sml;
    }

    .dropdown-toggle {
      text-decoration: none;
      border-radius: 50%;
      // background-color: $color-white;
      border: 1px solid $color-body;

      &:after {
        content: none;
      }
    }
  }

  &.hide {
    top: -120px;
  }

  // Static Innerpage header
  &.static {

    .search-box {
      .btn-search {
        color: $color-primary;
      }

      .input-search {
        letter-spacing: 0;
        outline-offset: 0;
        color: $color-body
      }
    }

    .login-sec {
      display: flex;

      .login-icon {
        svg {
          width: 20px;
        }
      }

      .btn {
        font-size: $font-size-sml;
        // color: $color-white;
      }

      .btn {
        padding: 8px 20px;
        font-size: $font-size-xsml;

        @media only screen and (min-width: 768px) {
          padding: 8px 35px;
          font-size: $font-size-sml;
        }
      }

      @media only screen and (max-width: 767px) {

        position: absolute;
        bottom: 6px;
        right: 15px;
      }

      // .btn {
      //   display: inline-block;
      //   padding: 8px 20px;
      //   text-decoration: none;
      //   font-size: $font-size-xsml;
      //   color: $color-primary;
      //   margin: 0 1px;
      //   position: relative;
      //   @media only screen and (min-width: 768px) {
      //     padding: 8px 35px;
      //     font-size: $font-size-sml;
      //   }
      //   >span {
      //     position: relative;
      //     z-index: 5;
      //   }
      //   // &:after {
      //   //   content: '';
      //   //   /* background: #fff; */
      //   //   position: absolute;
      //   //   top: 0;
      //   //   right: 0;
      //   //   bottom: 0;
      //   //   left: 0;
      //   //   border: 2px solid #42CB86;
      //   //   -webkit-transform: skewX(-20deg);
      //   //   -ms-transform: skewX(-20deg);
      //   //   transform: skewX(-20deg);
      //   //   border-radius: 20px 0px 0px 40px;
      //   //   z-index: -1;
      //   // }
      //   // &:last-of-type {
      //   //   color: $color-white;
      //   //   &:after {
      //   //     background-color: $color-primary;
      //   //     -webkit-transform: skewX(-20deg);
      //   //     -ms-transform: skewX(-20deg);
      //   //     transform: skewX(-20deg);
      //   //     border-radius: 0px 40px 20px 0px;
      //   //   }
      //   // }
      //   &:focus {
      //     -webkit-box-shadow: none;
      //     box-shadow: none;
      //   }
      //   &:hover {
      //     color: $color-white;
      //     // &:after {
      //     //   background-color: $color-primary;
      //     // }
      //     &:last-of-type {
      //       color: $color-primary;
      //       &:after {
      //         background-color: transparent;
      //         color: $color-primary;
      //       }
      //     }
      //   }
      // }
    }

    .mobile-toggle {
      position: absolute;
      right: 10px;
      font-size: 24px;
      bottom: 6px;
      width: 32px;
      height: 32px;
      text-align: center;
      padding-left: 5px;
      margin-right: 0;
      line-height: normal;

      i {
        position: absolute;
        right: 0;
        padding-right: 28px;
        padding-bottom: 10px;
        top: -4px;
      }
    }
  }

  // Homepage
  &.fixed {
    position: static;
    width: 100%;
    top: 0;
    background-color: transparent;
    border-bottom-color: transparent;

    @include respond-above(sm) {
      position: fixed;
    }

    &.hide {
      top: -120px;

      @include respond-above(sm) {
        top: 0;
        position: fixed;
      }
    }

    .login-profile-icon {
      fill: #fff;

      @include respond-below(sm) {
        fill: $color-body;
      }
    }

    .login-icon-sec:hover .login-profile-icon {
      fill: #ddd;
    }

    &.fixedActive {
      background-color: rgba($color-black, .7);
    }

    .search-box {
      .input-search {
        @include placeholder {
          color: $color-white;
        }

        @include respond-below(sm) {
          @include placeholder {
            color: $color-white;
          }
        }
      }

      &.active {
        .input-search {
          @include placeholder {
            color: $color-body;
          }
        }
      }
    }

    .nav-link {
      color: $color-white;
    }
  }
}

.header-fixed {
  .site-header {
    // position: static;
    width: 100%;
    top: 0;
    background-color: #fff;




    @include respond-above(sm) {
      position: fixed;
      border-bottom-color: transparent;

    }

    &.fixedActive {
      background-color: rgba($color-black, .7);
    }

    .search-box {
      .input-search {
        @include placeholder {
          color: $color-white;
        }

        @include respond-below(sm) {
          @include placeholder {
            color: $color-white;
          }
        }
      }

      &.active {
        .input-search {
          @include placeholder {
            color: $color-body;
          }
        }
      }
    }

    .nav-link {
      color: $color-body;

      @include respond-above(sm) {
        color: $color-white;

      }
    }

    .login-profile-icon {
      fill: #fff;

      @include respond-below(sm) {
        fill: $color-body;
      }
    }

    .login-icon-sec:hover .login-profile-icon {
      fill: #ddd;
    }

  }

  &.w-gradient {
    .site-header {
      @include respond-above(sm) {
        // background: linear-gradient(180deg, #000000 0%, rgba(26, 26, 26, 0) 100%);
        background: linear-gradient(180deg, #000 0%, rgba(26, 26, 26, 0.5) 45%, rgba(26, 26, 26, 0.1) 80%, rgba(26, 26, 26, 0) 100%);
        border-bottom: none;

      }
    }

    &.scroll-up {

      .site-header {
        transform: translateY(0px);
        background-color: rgba($color-black, .7);

        @include respond-below(sm) {
          background-color: $color-white;
        }

      }

    }

    &.header-fixed {
      .fixedActive {
        background-color: rgba($color-black, .7);
      }
    }

  }
}

.scroll-down {
  .site-header {
    transform: translateY(-150px);

    @include respond-below(sm) {
      transform: translateY(0px);
    }
  }
}


.profile-dropdown {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  .profile-icon {
    width: 25px;
    height: 25px;
  }
}

.creator_searchbar_list {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 4px 8px;
  border-bottom: 1px solid #eee;

  &:last-of-type {
    border-bottom: none;
  }

  a {
    color: $color-body;
    display: flex;
    align-items: center;
    width: 100%;

  }
}

.creator_search_container {
  position: absolute;
  top: 80px;
  right: 80px;
  /* background-color: white; */
  width: 250px;
  background: #fff;
  box-shadow: 0 8px 8px 0 rgb(0 0 0 / 15%);
  border: 1px solid #eee;
  border-radius: 8px;
  // height: 100vh;
  display: flex;
  overflow: hidden;

  ul {
    margin-bottom: 0;
  }
}

.search-creator {
  position: absolute;
  top: 14px;
  right: 10px;

  @include respond-above(sm) {

    top: 26px;
    right: 66px;

  }
  .disable{

  }
  
  &:before {
    // content: "\F52A";

    position: absolute;
    top: 0;
    right: 0;
    color: white;
    font-size: 16px;
    font-family: "bootstrap-icons";
    top: 5px;
    right: 6px;

    // border-radius: 50%;

    @include respond-above(sm) {
      right: 26px;
    }
    &:hover{
      color: #2b2b2b;
      border-radius: 50%;
    }
  }

  input {
    @include respond-above(sm) {
      width: 250px;
      transition: width 0.9s,  background-color 1s; //cubic-bezier(0.000, 0.795, 0.000, 1.000);
      margin-right: 15px;
      background: white;
      // border-radius: 8px;
      
      &:focus{
        // background: white;
        border-color: white;
        // color: white;
      }

    }
  }
  input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
    height: 1em;
    width: 1em;
    border-radius: 50em;
    background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg) no-repeat 50% 50%;
    background-size: contain;
    opacity: 0.8;
    pointer-events: none;
    margin-right: 20px;
    background-color: white;
  }
  .disable{
    width: 37px;
    transition: width 0.9s,  background-color 1s; //cubic-bezier(0.000, 0.795, 0.000, 1.000);
    background: transparent;
    border: 0;
    // border-radius: 50%;
    // border-color: white;
   
    &:hover{
      cursor: pointer;
      // color: #000;
  text-decoration: none;
   border-radius: 50% !important;
  opacity: 0.75;
  color: #2b2b2b;
  background-color:transparent;
  opacity: 0.4;
  // border-radius: 50%;
  transition: background-color 0.3s;
    }
    &:focus{
      border-color: white;
      background: transparent;
      // border-radius: 50%;
    }
  }

  .searchbar-active{
    z-index: 5;
    top: 1px;
    opacity: 0;
    background: #f5f0f0 !important;
    color: white;
    padding: 1rem 1rem;
    border-radius: 50%;
    position: absolute;
    right: 18px;


    &:hover{
      display: block;
      opacity: 0.5;

    }
  }
}

.search-icon {
  &:before{
    color: black;
  }

}