.card-ticket {
  border-radius: 20px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.11);
  padding-bottom: 25px;
  position:relative;

  &-img {
    img {
      object-fit: cover;
      border-radius: 20px 20px 0 0;
      max-width: 100%;
    }
  }

  &-content {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    color: #5d5d5d;
    position: relative;

    &-title,
    &-time {
      font-size: 12px;
    }

    &-time {
      border-bottom: 1px dashed #979797;
      padding-bottom: 5px;
      margin-bottom: 5px;
    }

    &:after,
    &:before {
      content: "";
      border-bottom-right-radius: 90px;
      border-top-right-radius: 90px;
      position: absolute;
      left: 0;
      bottom: 100%;
      width: 15px;
      height: 30px;
      background: #f2f2f2;

    }

    &:before {
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
      border-bottom-left-radius: 90px;
      border-top-left-radius: 90px;
      right: 0;
      left: auto;
    }


  }

  &-price-info {
    padding-left: 0;
    list-style: none;
    font-size: 12px;
    margin-bottom: 30px;

    // Record Unavailable
    .record-unavailable & {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px dashed #979797;

    }

    li {
      display: flex;

      span {
        &:last-of-type {
          margin-left: auto;
        }
      }

      &:last-of-type {
        margin-top: 15px;

        strong {
          &:last-of-type {
            margin-left: auto;
          }
        }
      }
    }
  }

  &-footer {
    padding: 0 30px;
    display: flex;

    .btn-black {
      font-size: 14px;
      flex-grow: 1;
      margin-right: 0px;
      font-weight: bold;
      padding-top: 0;
      padding-bottom: 0;
      min-height: 40px;
      line-height: normal;
    }

    .btn-link {
      flex-shrink: 1;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      border: 1px solid #2b2b2b;
      color: #2b2b2b;

      i {
        display: block;
        margin-right: 0;
      }

      &:hover {
        background-color: #f3f3f3;
        border-color: #2b2b2b;

      }

      &:focus {
        background-color: #2b2b2b;
        color: #fff;

        box-shadow: none;
      }
    }

    .no-record {
      font-size: 14px;
      padding-top: 6px;
      padding-bottom: 6px;
    }

    &.refund-info {
      display: flex;
      flex-direction: column;
      font-size: 14px;

      span {
        padding-bottom: 5px;

        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }

    &-right {
      display: flex;
      width: 90px;
      flex-wrap: wrap;
      

      .btn {
        padding-left: 0px;

        padding-right: 0px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-left: 5px;
        text-align: center;
        min-height: inherit;

        i {
          margin-right: 0;
          font-size: 20px;
        }

        // svg {
        //   width: 11px;
        //   margin-left: -2px;
        // }

        // &.guest {
        //   position: relative;

        //   &:after {
        //     position: absolute;
        //     right: 3px;
        //     content: "+";
        //     font-size: 12px;
        //     top: 1px;
        //   }
        // }
      }
    }
  }
  .gifted-ticket{
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    color: white;
    padding: 3px 10px;
    z-index: 5;
    border-radius: 50%;
    font-weight: 900;
    right: 9px;
    top: 9px;
    font-size: 22px;

    // .bi{
    //   font-size: 22px;
    // }
    .bi{
      font-size: 22px;
      color: white;
    }
  }

}