.backToTop {
  right: 15px;
  position: fixed;
  bottom: 30px;
  background: $color-white;
  border: 2px solid $color-primary;
  width: 30px;
  height: 30px;
  z-index: 9999;
  color: $color-primary;
  opacity: 0;
  overflow: hidden;
  transition: all .35s ease;
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
  &:after,
  &:before {
    content: "";
    border: 3px solid $color-primary;
    border-bottom: none;
    border-left: none;
    transform: rotate(-45deg);
    position: absolute;
    left: 0;
    right: 0;
    top: 10px;
    margin: auto;
    width: 10px;
    height: 10px;
    transition: all .35s cubic-bezier(0.075, 0.82, 0.165, 1)
  }
  &:before {
    transform: translateY(20px) rotate(-45deg);
    opacity: 0;
  }
  &:hover {
    background-color: $color-primary;
    &:after {
      border-color: $color-white;
      transform: translateY(-20px) rotate(-45deg);
      opacity: 0;
    }
    &:before {
      opacity: 1;
      border-color: $color-white;
      transform: translateY(0px) rotate(-45deg);
    }
  }
  &.show {
    opacity: 1;
  }
}


/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}