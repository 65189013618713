.link {
  font-size: $font-size-sml;
}

.link-primary {
  color: $color-primary;

  &:focus,
  &:hover {
    color: rgba($color-primary, .7);
  }

  &-dk {
    color: $color-primary-dk;

    &:focus,
    &:hover {
      color: rgba($color-primary-dk, .7);
    }
  }
}

.link-orange {
  color: $color-orange;

  &:focus,
  &:hover {
    color: rgba($color-orange, .7);
  }

}