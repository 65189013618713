.btn {
  font-size: $font-size-body;
  min-height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: $border-radius-default;
  text-transform: none;
  padding:.438rem 1rem;

  // @include respond-below(sm) {
  //   font-size: 14px;
  // }

  i {
    margin-right: 5px;
  }

  // Button Primary
  &.btn-primary {
    background-color: $color-primary-dk;
    color: $color-white;
    border-color: $color-primary-dk;

    &:hover,
    &.active,
    &:active {
      color: $color-primary-dk;
      background-color: rgba($color-primary-dk, .1);
    }

    &:focus {
      color: $color-primary-dk;
      -webkit-box-shadow: none;
      box-shadow: none;
      background-color: rgba($color-primary-dk, .15);
    }
  }

  // Button Link
  &.btn-link {
    color: $color-primary;

    &:hover {
      border-color: $color-primary;
      text-decoration: none;
    }

    &.no-bdr {
      text-decoration: none;

      &:hover {
        color: rgba($color-primary, .6);
        border-color: transparent;
      }
    }

    &-orange {
      color: $color-orange;

      &:hover {
        text-decoration: underline;
      }
    }

    &.btn-link-w-icon {
      text-decoration: none;
      overflow: hidden;

      i {
        position: relative;
        ;
        margin-left: $spacing-base - 5;

        &:before {
          -webkit-transition: -webkit-transform .25s ease .25s;
          transition: -webkit-transform .25s ease .25s;
          transition: transform .25s ease .25s;
          transition: transform .25s ease .25s, -webkit-transform .25s ease .25s
        }

        &:after {
          content: "\f144";
          font-family: 'bootstrap-icons' !important;
          position: absolute;
          -webkit-transform: translateY(28px) rotate(-8deg);
          -ms-transform: translateY(28px) rotate(-8deg);
          transform: translateY(28px) rotate(-8deg);
          left: -3px;
          -webkit-transition: -webkit-transform .25s ease 0s;
          transition: -webkit-transform .25s ease 0s;
          transition: transform .25s ease 0s;
          transition: transform .25s ease 0s, -webkit-transform .25s ease 0s
        }
      }

      &:hover {
        i {
          &:before {
            -webkit-transform: translateY(-28px) rotate(-8deg);
            -ms-transform: translateY(-28px) rotate(-8deg);
            transform: translateY(-28px) rotate(-8deg);
            -webkit-transition: -webkit-transform .25s ease -0s;
            transition: -webkit-transform .25s ease -0s;
            transition: transform .25s ease -0s;
            transition: transform .25s ease -0s, -webkit-transform .25s ease -0s
          }

          &:after {
            -webkit-transform: translateY(0px);
            -ms-transform: translateY(0px);
            transform: translateY(0px);
            -webkit-transition: -webkit-transform .25s ease .25s;
            transition: -webkit-transform .25s ease .25s;
            transition: transform .25s ease .25s;
            transition: transform .25s ease .25s, -webkit-transform .25s ease .25s
          }
        }
      }
    }

    &-primary {
      text-decoration: none;
      color: $color-primary;

      &:hover {
        text-decoration: underline;
      }
    }

  }

  // Button Primary - Outline
  &.btn-outline-primary {
    color: $color-primary-dk;
    border-color: $color-primary-dk;

    &:hover,
    &.active,
    &:active {
      background-color: rgba($color-primary-dk, .1);
    }

    &:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
      background-color: rgba($color-primary-dk, .15);
    }
  }

  // Button Orange
  &.btn-orange {
    background-color: $color-orange;
    color: $color-white;
    border-color: $color-orange;

    &:hover,
    &.active,
    &:active {
      color: $color-orange;
      background-color: rgba($color-orange, .1);
    }

    &:focus {
      color: $color-orange;
      -webkit-box-shadow: none;
      box-shadow: none;
      background-color: rgba($color-orange, .15);
    }
  }

  // Button Orange - Outline
  &.btn-outline-orange {
    color: $color-orange;
    border-color: $color-orange;
    border-radius: 50rem;

    &:hover,
    &.active,
    &:active {
      background-color: rgba($color-orange, .1);
    }

    &:focus {
      -webkit-box-shadow: none;
      box-shadow: none;
      background-color: rgba($color-orange, .15);
    }
  }

  &.no-text-desktop {
    @media only screen and (min-width: 768px) {
      i {
        margin-right: 0;
      }
    }
  }

  &.btn-rounded {
    border-radius: 50rem;
  }

  &.btn-wide {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }

  &.btn-white {
    background-color: $color-white;
    color: $color-black;
    border-radius: $border-radius-default;
    display: inline-flex;
    font-weight: $font-weight-bold;
    border-radius: 50rem;
    padding-left: 2rem;
    padding-right: 2rem;

    &:hover {
      background-color: #f3f3f3;
    }

    &:focus {
      background-color: #2b2b2b;
      border-color: #2b2b2b;
      color: #fff;
      border: 1px solid #fff;
      box-shadow: none;
    }
  }

  &.btn-black {
    background-color: #2b2b2b;
    color: #fff;
    border-radius: 50rem;
    padding-left: 2rem;
    padding-right: 2rem;

    @include respond-below(sm) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  &.btn-outline-black{
    background-color: $color-white;
    border-color: $color-black-2e;
    color: $color-black-2e;
    border-radius: 50rem;
    padding-left: 2rem;
    padding-right: 2rem;

    &:hover {
      background-color: #f3f3f3;

    }

    &:focus {
      // background-color: #2b2b2b;
      // color: #fff;

      box-shadow: none;
    }
  }

  &-text {
    background-color: transparent;
    border: none;
    text-decoration: underline;

    &:hover {
      color: $color-body-light;
      text-decoration: none;

    }
  }
}
.next-button .btn:hover{
  border:1px solid #212529 ;
}