.card-livestream {
  border-radius: 10px;
  padding: 100% 40px 18px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border: 1px solid #979797;
  background-color: #fff;
  text-decoration: none;
  display: block;

  @include respond-below(sm) {
    padding-left: 20px;
    padding-right: 20px;

  }

  @include respond-below(xs) {
    padding-top: 40%;
  }

  &.square {
    padding: 55% 40px 20px;

    @include respond-below(xs) {
      padding-top: 25%;
    }

    &:after {
      // background-image: linear-gradient(180deg, black 0%, rgba(0, 0, 0, 0.5) 0%, rgba(0,0,0, 0) 100%);
      content: "";
      position: absolute;
      width: 100%;
      height: 100px;
      left: 0;
      top: 0;
      z-index: 0;
    }
  }

  &:before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    height: 50%;
    background-image: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0.8519607672) 10%, rgba(0, 0, 0, 0) 100%);
    width: 100%;
    border-radius: 8px;
    z-index: 1;

    @include respond-below(sm) {

      height: 80%;

    }

  }

  &-title {
    margin-bottom: 5px;
    padding-bottom: 0;
    white-space: nowrap;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $color-white;

    a {
      text-decoration: none;
      color: #fff;
    }

    @include respond-below(sm) {
      font-size: 1.25rem;
    }
  }

  .video-img {
    transition: all 0.35s ease;
    margin-left: auto;
    margin-right: auto;
    max-width: 90px;

    video {
      border-radius: 50%;
      margin: 0 auto 10px;
      border: 2px solid $color-body;
      transition: all 0.35s ease;
      object-fit: cover;
      max-width: 90px;
      height: 100%;
    }
  }

  &-bgimg {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    z-index: 0;
    filter: blur(3px);
    margin: -3px;
  }

  &-img {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    overflow: hidden;
    object-fit: cover;
    z-index: 0;
    max-width: 100%;
  }

  &-date {
    color: #fff;
    margin-top: 25px;
    margin-bottom: 10px;
    z-index: 1;
  }

  &-content {
    display: flex;
    flex-direction: column;
    z-index: 1;
    transform: translateY(25px);
    position: relative;
    width: 100%;
    transition: all 0.35s ease 0.1s;

    .past-event & {
      transform: none;
    }

    @include respond-below(md) {
      transform: translateY(0px);
      // align-items: center;

    }

    // .more-livestream-sec & {
    //   transform: translateY(5px);
    // }
  }

  &-live-sec {
    position: absolute;
    right: 10px;
    top: 15px;
    z-index: 0;
    color: #fff;
    display: flex;
    flex-direction: column;
    z-index: 1;

    .card-livestream-liveIcon {
      @include respond-below(sm) {
        max-width: 120px;
      }
      img {
        width: 100%;
      }
    }

    .card-livestream-live-status-text {
      padding: 3px 10px;
      background: rgba(0, 0, 0, 0.8);
      border-radius: 4px;
      margin-left: 6px;

      @include respond-below(sm) {
        margin-left: 6px;
        font-size: 14px;
        padding: 3px 10px;
      }
    }

  }

  .btn {
    position: relative;
    z-index: 1;
    display: inline-flex;
    transform: translateY(120px);
    transition: all 0.35s ease 0s;

    @include respond-below(sm) {
      transform: translateY(0px);
    }
  }

  &:hover {
    .card-livestream-content {
      transform: translateY(0);
      transition: all 0.35s ease 0s;
    }

    .btn {
      transform: translateY(0);
      transition: all 0.35s ease 0s;
    }
  }

  &-likes-count {
    position: absolute;
    right: 15px;
    top: 10px;
    color: #ffffff;
    z-index: 1;
    background: rgba(0, 0, 0, 0.3);
    padding: 4px 12px;
    border-radius: 30px;
    font-size: 14px;

    i {
      font-size: 14px;
      vertical-align: middle;
      padding-right: 5px;
    }


  }


}

.share-dropdown {
  &.dropdown {
    position: absolute;
    top: 0;
    right: 0;

    .btn {
      transform: none;
      background-color: transparent;
      top: 10px;
      right: 15px;

      border-color: transparent;
      color: #ffffff;
      z-index: 1;
      background: rgba(0, 0, 0, 0.3);
      padding: 4px 12px;
      border-radius: 30px;
      font-size: 18px;
      width: 40px;
      height: 40px;

      &:after {
        content: none;
      }
    }

    .dropdown-menu {
      background: rgba(0,0,0,0.8);
    
      a {
        color: #fff;
        &:hover{
        background-color: rgba(0,0,0,0.8);
        }
      }
    }
  }
}
.card-livestream-nft-status {
  position: absolute;
  top: 10px;
  right: 45px;
  z-index: 1;
  color: #fff;
  display: flex;
  align-items: center;
}
.card-livestream-nft-status span {
  padding-left: 5px;
}

.box {
  position: relative;
  max-width: 600px;
  width: 90%;
  height: 400px;
  background: #fff;
  box-shadow: 0 0 15px rgba(0,0,0,.1);
}

/* common */
.card-livestream-ribbon {
  width: 120px;
  height: 120px;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}
.card-livestream-ribbon::before,
.card-livestream-ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.card-livestream-ribbon span {
  position: absolute;
  display: block;
  width: 184px;
  padding: 6px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
  
}
.card-livestream-ribbon span img {
  width: 28px;
}

/* top left*/
.card-livestream-ribbon-top-left {
  top: -10px;
  left: -10px;
}
.card-livestream-ribbon-top-left::before,
.card-livestream-ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.card-livestream-ribbon-top-left::before {
  top: 0;
  right: 0;
}
.card-livestream-ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.card-livestream-ribbon-top-left span {
  right: -22px;
  top: 30px;
  transform: rotate(-45deg);
}
