.post-form-sec {
  .post-form {
    border-right: 1px solid $color-body;
  }
  .plans-checkbox {
    @include respond-above(sm) {
      margin-left: $spacing-base - 5;
    }
    @include respond-above(md) {
      margin-left: $spacing-base - 10;
    }
    .custom-checkbox {
      padding: 3px 0;
    }
    label {
      &:before {
        top: 5px;
        position: absolute;
      }
    }
    input {
      &:checked+ {
        label {
          &:after {
            top: 8px;
          }
        }
      }
    }
  }
  .bundle-info {
    display: flex;
    padding-left: $spacing-double;
    .bundle-name {
      display: flex;
    }
    .bundle-detail {
      margin-left: $spacing-double;
    }
  }
  .bundle-desc {
    display: flex;
    padding-left: $spacing-double;
  }
  .set-end-date-sec {
    .col-auto:nth-of-type(1) {
      margin-left: 33px;
    }
  }
}