.sidebar {
  position: fixed;
  width: 70px;
  background-color: $color-white;
  // -webkit-box-shadow: 0 5px 3px rgba($color-black, .3);
  // box-shadow: 0 5px 3px rgba($color-black, .3);
  padding-top: 10px;
  display: none;
  top: 0;
  bottom: 0;
  z-index: 1000;
  // top: 100px;
  border-right: 1px solid $color-divider;

  @media only screen and (min-width: 768px) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .main-menu {
    list-style: none;
    padding-left: 0;
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    flex-grow: 1;

    li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      // border-bottom: 1px solid $color-offwhite;

      a {
        padding: 8px 20px;
        text-decoration: none;
        color: $color-black-2e;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        border-left: 4px solid transparent;

        &.active {
          color: $color-body;
          border-left: 4px solid $color-body;
        }
      }

      i {
        font-size: $font-size-sidebar-icon - 0.25rem;
        vertical-align: middle;
        margin-right: 5px;
      }

      &:hover {
        a {
          background-color: rgba($color-sidebar-hover, 0.3);
        }
      }

      &.menu-main {
        .menu-text {
          vertical-align: middle;
        }
      }
    }

    .menu-text {
      font-size: 0px;
      visibility: hidden;
      display: inline-block;
    }
  }

  &.active {
    width: 250px;

    .menu-text {
      font-size: $font-size-body - .125rem;
      visibility: visible;
    }
    .menu-main {
      .menu-text {
        font-size: $font-size-body;
      }
    }
  }

  .sidebar-logo {
    padding: 0 15px;
    margin: 5px 0 20px;

    img {
      max-width: 120px;
    }
  }

  .sidebar-toggle {
    position: absolute;
    top: 130px;
    left: calc(100% + 1px);
    padding: 5px 0px;
    border-left: none;
    font-size: 18px;
    display: none;

    i {
      color: $color-black;
    }

    &:after {
      left: -22px;
      top: 0;
      content: "";
      position: absolute;
      pointer-events: none;
      background-color: $color-white;
      z-index: -1;
      width: 40px;
      height: 40px;
      border-radius: 0px 8px 0 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      border: 1px solid $color-divider;
      border-bottom-color: transparent;
      border-left-color: transparent;
      // box-shadow: 2px -2px 3px rgba(0, 0, 0, 0.2);
    }
  }

  .sidebar-profile-info {
    h3 {
      overflow: hidden;
      white-space: nowrap;
      visibility: hidden;
      opacity: 0;
    }
  }

  &.active {
    .sidebar-profile-info {
      h3 {
        opacity: 1;
        overflow: visible;
        white-space: normal;
        visibility: visible;
      }
    }
  }
}

.sidebar-bottom-menu {
  background-color: $color-white;

  .main-menu {
    margin-bottom: 0;
  }
}

// 0px 0px 10px #31AE6F