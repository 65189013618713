.table {
  font-size: $font-size-body;
  &.table-default {
    thead {
      tr {
        border-top-width: 0;
      }
    }
    th,
    td {
      border-width: 0;
    }
    tbody {
      td {
        font-size: $font-size-sml - 1;
        padding: .5rem .75rem;
      }
     
    }
  }
  &.table-common {
    thead {
      th {
        border-bottom-color: $color-body;
      }
    }
    >:not(:last-child) {
      > :last-child> {
        * {
          border-bottom-color: $color-form-border;
        }
      }
    }
  }

  

  // &.table-hover>tbody>tr:hover{
  //   background-color: white !important;
  // }
  // &.table-custom {
  //   border-collapse: separate;
  //   border-spacing: 0px 15px;
  //   thead {
  //     th {
  //       background: $color-body;
  //       border: none;
  //       padding: 1rem;
  //     }
  //     th:first-of-type {
  //       border-radius: 5px 0 0 5px;
  //     }
  //     th:last-of-type {
  //       border-radius: 0px 5px 5px 0;
  //     }
  //   }
  //   tbody {
  //     tr {
  //       transition: box-shadow .35s;
  //       td,
  //       th {
  //         background: #fafafa;
  //         border: none;
  //         padding: 1rem;
  //       }
  //       th:first-of-type {
  //         border-radius: 5px 0 0 5px;
  //       }
  //       td:last-of-type {
  //         border-radius: 0px 5px 5px 0;
  //       }
  //       &:hover {
  //         box-shadow: 0 4px 5.5px rgba(0, 0, 0, 0.013), 0 5px 20px rgba(0, 0, 0, 0.07);
  //       }
  //     }
  //   }
  // }
}