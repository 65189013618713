.order-summary-detail {
  h3 {
    font-weight: bold;
    border-bottom: 1px solid $color-divider;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  ul {
    padding-left: 0;
    margin-bottom: 40px;

    li {
      list-style: none;
      padding-bottom: 10px;
    }
  }

  ol {
    padding-left: 1rem;

    li {
      padding-bottom: 20px;
    }
  }

  strong {
    font-size: 1.125rem;
  }

  .attendee-info {
    padding-left: 0;
    display: flex;

    &-left {
      display: flex;
      flex-direction: column;

      strong {
        margin-bottom: 5px;
        display: block;
        font-weight: 600;
      }
    }

    &-right {
      display: flex;
      margin-left: auto;

      .btn-text {
        margin-left: 30px;
      }

    }

  }

}