.profile-info {
  border: 1px solid $color-form-border;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  &-left {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 45px;
    flex: 0 0 45px;
    border: 1px solid $color-form-border;
    margin-right: $spacing-base - 5;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
  &-right {
    -webkit-box-flex: 9999;
    -ms-flex: 9999;
    flex: 9999;
    a {
      font-size: $font-size-h6;
      text-decoration: none;
      color: $color-body;
      &.btn {
        font-size: $font-size-body;
      }
    }
    ul {
      margin-left: 0;
      padding-left: 0;
      font-size: $font-size-sml;
      margin-bottom: 10px;
      li {
        list-style: none;
        display: inline-block;
        padding-right: 10px;
      }
    }
  }
}