.support-main {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 30px;
      }
}
.main-support{
  @media (min-width: 1400px){
    max-width: 1200px;
  }
}
.support {
	width: 50%;
    @media only screen and (max-width: 768px) {
       width: 100%;
      }
   
}
.right{
    display:flex;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 30px;
    
}
.left{
    @media only screen and (max-width: 768px) {
        margin-bottom: 30px;
      }
}
.contactus-container{
    border-radius: 20px;
    // box-shadow: 5px 5px 5px 5px rgb(243, 240, 240);
    // border: 1px solid #2b2b2b;
    // padding: 20px;
    width: 100%;
    
}
.contact-details {
    display: flex;
    // flex-direction: column;
    // align-items: center;
    width: 100%;
    // text-align: center;
    // justify-content: center;
}
.contactus-content img{
  width: 90%;
  margin: 0 auto;
  margin-bottom: 75px;
}
.section{
    display: flex;
    align-items: center;
    justify-content: center;
}
.contactus-content{
    display: flex;
    flex-direction: column;

}

.col-md-12 .css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    border-radius: 8px;
}



.search-click {
    border: 1px solid #ccc;
    outline: none;
    background-size: 22px;
    background-position: 13px;
    border-radius: 10px;
    width: 50px;
    height: 50px;
    padding: 25px;
    transition: all 0.5s;
  }
  .search-click:focus {
    width: 300px;
    padding-left: 50px;
  }
  .search-click {
    position: relative;
    overflow: hidden;
    height: 50px;
  }
  .search-click input {
    background: transparent;
    border: 1px solid #ccc;
    outline: none;
    position: absolute;
    width: 300px;
    height: 50px;
    left: 0%;
    padding: 10px;
  }

  .support h6{
    font-size: 32px;
    font-weight: 200;
    color: #2b2b2b;
  }

  .support-heading-content{
    font-size: 16px;
    font-weight: 400;
    color: #2b2b2b;
  }

  .support .btn-secondary{
    width: 30%;
    /* margin: 0 auto; */
    margin-top: 16px;
  }