.form-control,
label,
.col-form-label {
  font-size: $font-size-sml;
  padding-left: 15px;
}

.form-label {
  padding-left: 0;
}

.form-control {
  border-radius: $border-radius-default;

  &:focus {
    box-shadow: none;
  }
}


.form-floating {

  .form-control,
  .form-select {
    height: calc(2.5rem + 8px);
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: $font-size-sml;
    border-radius: $border-radius-default;
    border-width: 1px;
    padding-left: 15px;
    padding-right: 15px;
    border-color: $color-form-border;

    &:not(:placeholder-shown) {
      border-color: $color-form-border;

      ~label {
        opacity: 1;
        color: $color-body
      }
    }

    &:focus {
      box-shadow: none;
      border-color: $color-body;

      ~label {
        opacity: 1;
        color: $color-body-light;
      }
      input {
        color: $color-body-light;
      }
    }
  }

  >label {
    padding-top: 0.7rem;
    padding-bottom: .5rem;
    padding-left: 15px;
    font-size: $font-size-sml;
  }

  textarea.form-control {
    height: 100px;
  }

  >.form-control:focus~label,
  >.form-control:not(:placeholder-shown)~label,
  >.form-select~label {
    transform: scale(.85) translateY(-0.7rem) translateX(.65rem);
    padding: 0;
    padding-left: 10px;
    background: #fff;
    padding-right: 10px;
    height: auto;
    color: $color-body-light;
    
  }

  >.form-control:focus,
  >.form-control:not(:placeholder-shown) {
    padding-top: 0.625rem;
    padding-bottom: .625rem;
    color: $color-body-light;
  }

  .form-control[readonly], .form-select[disabled],.form-control[disabled] {
    position: relative;
    +label {
      border: none;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 10px;
        background:#e9ecef;
        bottom: 0;
        border: none;
        z-index: -1;
      }
    }
  }
}

.form-select {
  height: calc(2.75rem + 4px);
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: $font-size-sml;
  border-radius: $border-radius-default;

  &.form-select-sm {
    height: calc(1.75rem + 8px);
    padding-top: .35rem;
    padding-bottom: .35rem;
  }

  &:focus {
    box-shadow: none;
  }
}

.custom-radio {
  border-bottom: 1px solid $color-body;
  position: relative;

  &.no-bdr {
    border-bottom: none;
  }

  [type="radio"] {

    &:checked,
    &:not(:checked) {
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: 1;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    &:checked+label,
    &:not(:checked)+label {
      position: relative;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: block;
      color: $color-body
    }

    &:checked+label:before,
    &:not(:checked)+label:before {
      content: '';
      position: absolute;
      left: 0px;
      top: 12px;
      width: 20px;
      height: 20px;
      border: 1px solid $color-body;
      border-radius: 100%;
      background: $color-white;

    }

    &:checked+label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: $color-primary;
      position: absolute;
      top: 16px;
      left: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }

    &:not(:checked)+label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: $color-primary;
      position: absolute;
      top: 4px;
      left: 4px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    &:checked+label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  &.radio-green {
    [type="radio"] {

      &:checked+label:before,
      &:not(:checked)+label:before {
        border-color: $color-primary;
      }
    }
  }
}

.custom-checkbox {
  display: block;
  padding: 10px 0;
  border-bottom: 1px solid $color-body;

  &.no-bdr {
    border-bottom: 1px solid transparent !important;
  }

  &.disabled {
    label {
      &:before {
        border-color: $color-body;
      }
    }
  }

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  .form-text {
    padding-top: 3px;
  }

  label {
    padding-left: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    span {
      line-height: 1;
      vertical-align: top;
    }

    position: relative;
    cursor: pointer;
    display: block;

    &:before {
      content: '';
      -webkit-appearance: none;
      background-color: transparent;
      border: 1px solid $color-primary;
      box-shadow: 0 1px 2px rgba($color-black, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 10px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 8px;
    }
  }

  input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 9px;
    width: 6px;
    height: 12px;
    border: solid $color-primary;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}