@font-face {
  font-family: 'icomoon';
  src: url('./icomoon.eot?w4757f');
  src: url('./icomoon.eot?w4757f#iefix') format('embedded-opentype'), url('./icomoon.ttf?w4757f') format('truetype'), url('./icomoon.woff?w4757f') format('woff'), url('./icomoon.svg?w4757f#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-subscribers:before {
  content: "\e900";
}

.icon-post:before {
  content: "\e901";
}

.icon-my-club:before {
  content: "\e902";
}

.icon-all-post:before {
  content: "\e903";
}