.main-content.profile-sec {
  // background-color: #2A1B1B !important;
  margin-bottom: 0;
  border-radius: 0;
  @include respond-below(sm) {
    padding-bottom: 20px;
  }
}

.per-acc-sec {
  height: 250px;
  margin-bottom: 10px;
  // border-radius: 50px 50px 0 0;
  background: linear-gradient(0deg, #88CEF1 0%, #FF54B9 100%);
  padding: 0 30px;
  display: flex;
  // flex-direction: row;
  width: 100%;
  position: relative;
  z-index: 0;
  @include respond-below(sm) {
    border-radius: 10px 10px 0 0;
  }

  @include respond-below(sm) {
    height: 180px;
    padding: 0 15px;
  }

  h2 {
    font-size: 48px;
    align-self: center;

    @include respond-below(sm) {
      align-self: flex-start;
      margin-top: auto;
      font-size: 30px;
    }
  }

  &-detail {
    display: flex;
    align-items: center;
    width: 100%;
    color: #fff;

    @include respond-below(sm) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &-upload {
    align-self: flex-end;
    margin-left: auto;
    display: flex;
    margin-bottom: 10px;

    @include respond-below(sm) {
      align-self: flex-start;
      margin-left: 0;
      font-size: 14px;
    }

    &-left {
      display: flex;
      flex-direction: column;
      text-align: right;
      margin-right: 10px;

      span {
        &:first-of-type {
          font-weight: bold;
        }

        &:last-of-type {
          font-size: 14px;
        }
      }

      @include respond-below(sm) {
        order: 1;
        margin-left: 0;
        text-align: left;
      }
    }

    &-right {
      font-size: 48px;
      line-height: 0;

      @include respond-below(sm) {
        margin-right: 10px;
        font-size: 36px;
      }

      a {
        text-decoration: none;

        &:hover {
          i {
            background: rgba(0, 0, 0, 0.6);
          }
        }
      }

      i {
        color: white;
        font-weight: normal !important;
        width: 52px;
        height: 52px;
        background: rgba(0, 0, 0, 0.3);
        padding: 5px;
        border-radius: 50%;
        display: flex;
        font-size: 23px;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    // border-radius: 50px 50px 0 0;
    z-index: -1;
    @include respond-below(sm) {
      border-radius: 10px 10px 0 0;
    }
  }
}

.per-acc-detail {
  display: flex;
  background-color: #fff;
  border-radius: 0 0 50px 50px;
  flex-direction: column;

  @include respond-below(sm) {
    flex-direction: column;
    border-radius: 0 0 10px 10px;
  }

  &-menu {
    // flex: 0 0 225px;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    // border-right: 1px solid $color-divider;

    @include respond-below(sm) {
      flex: 9999;
      padding-top: 20px;
    }

    .tab-profile {
      color: #2b2b2b;
      opacity: 0.8;
      text-decoration: none;
      font-size: 14px;
      padding: 0 30px;
      padding-left: 0;
      margin-bottom: 0px;
      font-weight: 400;
      text-transform: none;

      @include respond-below(sm) {

        padding: 0px;
        margin-left: 10px;
        min-height: inherit;
        font-size: 14px;

      }

      &:hover{
        opacity: 1;
      }
      &.Mui-selected,
      &.active {
        color: #2b2b2b;
        opacity: 1;
        font-weight: bold;
      }
    }

    a {
      color: #8c8c8c;
      text-decoration: none;
      font-size: 14px;
      padding: 0 30px;
      margin-bottom: 15px;
      font-weight: 600;
      text-transform: none;

      &:hover,
      &.Mui-selected,
      &.active {
        color: #2b2b2b;
      }
    }

    .tab-profile {
      align-items: flex-start;
      font-family: $font-family-body;
      letter-spacing: 0.1rem;
      margin: 0 34px 0 0;
    }

    .MuiTabs-indicator {
      background-color:white ;

      @include respond-below(sm) {
        display: none;
      }
    }

    .MuiTabs-flexContainer {
      display: inline;  
      @include respond-below(sm) {
            }
    }

    .MuiButtonBase-root.MuiTab-root {
      @include respond-below(sm) {
        min-width: inherit;
        flex: 1;
        border-bottom: 1px solid #eee;
        padding: 10px 0;
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }


  &-menu-tab {
    // margin-bottom: 200px;
    border-bottom: 1px solid #d8d8d8;
    @include respond-below(sm) {
      margin-bottom: 0px;
      min-height: inherit;
    }
  }

  &-content {
    flex: 9999;
    margin-left: -1px;
    // border-left: 1px solid $color-divider;
    // padding-top: 24px;
    .settings-sec {

      font-family: $font-family-body;
      letter-spacing: normal;

      input,
      label {
        font-family: $font-family-body;
        letter-spacing: normal;
      }
    }

    .per-acc-detail-menu-btm {
      display: block;
      margin-bottom: 20px;

      @include respond-above(sm) {
        display: none;
      }

      a {
        color: #8c8c8c;
        text-decoration: none;
        font-size: 16px;
        padding: 0 30px;
        margin-bottom: 15px;
        font-weight: 600;
        text-transform: none;
      }
    }

    @include respond-below(sm) {
      border: none;
    }
  }

  &-menu-btm {
    margin-top: auto;
    margin-bottom: 100px;
    display: flex;
    flex-direction: column;

    @include respond-below(sm) {
      margin-bottom: 0px;
      display: none;
    }
  }
  .css-19kzrtu {
    padding: 0;
    padding-top: 24px !important;
  }
}
.try::before{
  content: "";
  width: 1px;
  position: absolute;
  left: calc(100% + -1px);
  top: 18px;
  height: 12px;
  background-color: #2b2b2b;
}