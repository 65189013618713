.mobile-toggle {
  margin-right: 10px;

  color: $color-body i {
    font-size: $font-size-body + .65rem;
    color: $color-hamburger;
  }

  @include respond-below(sm) {
    .profile-icon {
      position: absolute;
      z-index: -1;
      right: 0px;
      bottom: 0;
      border: 1px solid $color-body;
    }
  }
}

#mobileMenu {

  // z-index: 10001;
  .navbar-brand {
    img {
      max-height: 30px;
    }
  }

  .offcanvas-header {
    border-bottom: 1px solid $color-body;
    padding-top: 14px;
    padding-bottom: 14px;
  }
}