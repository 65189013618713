.checkout {
  padding: 100px 0;

  &-desc {
    flex: 9999;
    border-radius: 8px;
    background-color: $color-white;
    padding: 20px 2vw;
    margin-bottom: $spacing-base;
    position: relative;
    display: flex;
    flex-direction: column;


    h3 {
      font-size: $font-size-sml;
      margin-bottom: 0px;
      font-weight: normal;
      padding: 0 10px;


    }

    h2 {
      font-size: $font-size-body + .125rem;
      margin-bottom: 15px;
      font-weight: $font-weight-semibold;
      padding: 0 10px;

    }

    // &:after,
    // &:before {
    //   position: absolute;
    //   content: "";
    //   background: #777;
    //   width: 16px;
    //   height: 32px;
    //   top: -16px;
    //   left: 0px;
    //   border-radius: 50%;
    //   border-bottom-right-radius: 90px;
    //   border-top-right-radius: 90px;
    // }

    // &:before {
    //   right: 0;
    //   left: auto;
    //   border-bottom-right-radius: 0;
    //   border-top-right-radius: 0;
    //   border-top-left-radius: 90px;
    //   border-bottom-left-radius: 90px;
    // }
    .btn {
      @media only screen and (max-width:1370px) {
        font-size: 12px;
      }
    }

  }

  &-time,
  &-count,
  &-price {
    font-size: $font-size-sml;
    display: block;
    padding-bottom: 5px;
  }

  &-price {
    padding-bottom: 0;
  }

  &-time {
    font-size: $font-size-xsml;
    border-bottom: 1px dashed $color-divider;
    padding: 0 10px 10px;
    margin-bottom: 5px;
  }

  &-count {
    display: flex;
    align-items: center;
    padding: 0 10px;

    label {
      padding-left: 0;
    }
  }

  &-total {
    margin-left: auto;
  }

  &-img {
    width: 100%;
    margin-right: 15px;
    width: 100%;

    img {
      max-width: 100%;
      border-radius: 17px;
    }
  }

  &-card {
    display: flex;
    padding: 15px;
    max-width: 350px;
    flex-direction: column;
    margin: 0 auto;
    border-radius: 8px;


  }

  &-card-title-sec {

    border-bottom: 1px solid $color-divider;

    padding-bottom: 5px;
    margin-bottom: 10px;

    .checkout-card-price {
      padding-bottom: 0;
    }
  }

  &-card-title {
    font-size: 20px;
    display: block;
    text-align: center;
    margin-bottom: 0;
    padding-bottom: 10px;

    @media only screen and (max-width:1370px) {
      padding-bottom: 0px;

      font-size: 16px;
    }
  }

  &-card-info {
    padding-left: 16px;
    padding-bottom: 40px;

    @media only screen and (max-width:1370px) {
      padding-bottom: 0px;
      margin-bottom: 0;
    }

    li {
      padding-bottom: 5px;

      @media only screen and (max-width:1370px) {
        font-size: 13px;
      }
    }
  }

  &-card-price {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    display: block;
    padding-bottom: 10px;
    margin-top: auto;
    &.sml {
      font-size: 22px;
    }
    @media only screen and (max-width:1370px) {
      padding-bottom: 0px;
      font-size: 20px;
    }
  }

  &-info-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }


}

.checkout-counter {
  border: 1px solid $color-form-border;
  border-radius: 25px;


  span {
    margin: 0;
    font-size: 1rem;
    line-height: 0;
    background: #eee;
    height: 32px;
    display: inline-flex;
    justify-content: center;
    padding: 0px 2px;
    align-items: center;
    border-radius: 25px;
    cursor: pointer;
    vertical-align: top;
    width: 32px;

    &:hover {
      background-color: #ddd;
    }

    i {
      font-size: 18px;
    }
  }

  .form-control {
    height: 32px;
    font-size: 16px;
    max-width: 40px;
    border: none;
    padding: 0;
    text-align: center;
    vertical-align: top;
    background-color: #fff;
  }

  &.dummy {
    span {
      &:hover {
        background: #eee;
      }
    }
  }

}





// .ticket__divider {
//   position: relative;
//   height: 32px;
//   background-color: white;
//   margin-left: 15px;
//   margin-right: 15px;
// }

// .ticket__notch {
//   position: absolute;
//   left: -16px;
//   width: 40px;
//   height: 81px;
//   overflow: hidden;
//   top: -18px;
// }
// .ticket__notch::after {
//   content: "";
//   position: relative;
//   display: block;
//   width: 65px;
//   height: 65px;
//   right: 100%;
//   top: -6px;
//   border: 8px solid white;
//   border-radius: 50%;
//   box-shadow: inset 0 2px 4px rgba(41, 54, 61, 0.25);
// }
// .ticket__notch--right {
//   left: auto;
//   right: -16px;
// }
// .ticket__notch--right::after {
//   right: 0;
// }

.ticket__divider {
  position: relative;
  height: 1rem;
  background-color: white;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.ticket__notch {
  position: absolute;
  left: -0.5rem;
  width: 1rem;
  height: 1rem;
  overflow: hidden;
}

.ticket__notch::after {
  content: "";
  position: relative;
  display: block;
  width: 2rem;
  height: 2rem;
  right: 100%;
  top: -50%;
  border: 0.5rem solid white;
  border-radius: 50%;
  box-shadow: inset 0 2px 4px rgba(41, 54, 61, 0.25);
}

.ticket__notch--right {
  left: auto;
  right: -0.5rem;
}

.ticket__notch--right::after {
  right: 0;
}

.checkout-card-latest {
  display: flex;
  padding-bottom: 15px;
  flex-direction: column;

  .btn-black {
    border: 1px solid #888;
  }
}

.checkout-card-latest .btn {
  font-weight: normal;
  min-width: 240px;
  margin-bottom: 0;
  margin-right: 5px;
}

.checkout-btn-sec>span {

  border-radius: 31px;
  width: 24px;
  height: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $color-white;

}

.checkout-btn-sec {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .btn {
    @include respond-below(sm) {
      width: 100%;
    }
  }
}

.checkout-btn-sec:last-of-type {
  margin-bottom: 0;
}

.checkout-btn-sec>span i {
  font-size: 18px;
  color: #fff;

  &:before {
    display: block;

    @include respond-below(md) {
      display: inherit;
      vertical-align: inherit;
    }
  }
}

.modal-dialog.ticket-modal {
  max-width: 350px;
}



.ticket-modal {
  .modal-content {

    border-radius: 25px;
    padding: 15px 30px;
  }

  h3 {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 10px;
  }

  .checkout-price {
    font-size: 28px;
    font-weight: bold;
  }

  label {
    font-size: $font-size-body;
  }

  .total-price-sec {
    padding-top: 10px;

    label,
    strong {
      font-size: 20px;
    }
  }

  .btn-close {
    position: absolute;
    right: 30px;
    top: 25px;
  }

  .pay-in {
    width: 120px;
    height: 120px;
    border-radius: 10px;
    border: 1px solid #000;
    text-align: center;
    padding: 10px;

    &.pay-in-inr {
      .btn {
        width: 100%;
      }
      img {
        max-width: 80px;
        margin-top: 20px;
        margin-bottom: 14px;
      }
    }
  }
}