.intro-slider {
  padding: 10px 4px;
  background-color: $color-body;

  @include respond-below(sm) {
    padding: 0px;
  }

  .slick-dots {
    margin: 0px auto;
    display: flex !important;
    justify-content: center;
    list-style: none;
    padding-left: 0;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;

    @include respond-below(sm) {
      margin-top: 10px;
      margin-bottom: 5px;

    }

    li {
      margin-right: 5px;
      border: 1px solid rgba(255, 255, 255, 0.58);
      padding: 1px;
      display: inline-flex;
      border-radius: 50%;
      width: 16px;
      height: 16px;

      button {
        background: transparent;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0);
        text-indent: -9999px;
        padding: 1px;
        border: none;
        display: block;
      }

      &.slick-active {
        button {
          background: rgba(255, 255, 255, 0.58);
          width: 6px;
          height: 6px;
          display: block;
          margin: auto;
        }
      }
    }
  }

  &-single {
    display: flex !important;
    align-items: center;
    height: calc(100vh - 16px);
    justify-content: space-around;

    @include respond-below(sm) {
      flex-direction: column;
      height: auto;
    }

    &-left {
      align-self: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 0 0 400px;
      color: #fff;
      align-content: center;

      @include respond-below(sm) {
        order: 1;
        flex: 0 0 auto;
        margin-top: 40px;
        text-align: center;
      }

      h2 {
        font-size: 3rem;

        @include respond-below(sm) {
          font-size: 1.75rem;
        }

        a {
          color: $color-white;
          text-decoration: none;
        }
      }

      p {
        padding-bottom: 20px;
      }

      .btn {
        align-self: flex-start;

        @include respond-below(sm) {
          align-self: center;
          margin-bottom: 15px;
        }
      }
    }

    &-right {
      flex: 0 0 auto;
      max-height: 50vh;

      @include respond-below(sm) {
        flex: 0 0 auto;
        max-height: inherit;
      }

      img {
        max-width: 100%;
        transform: rotate(-6deg);
      }

      &-img {
        position: relative;
        background-clip: padding-box;
        max-width: 20vw;

        @include respond-below(sm) {
          max-width: 60%;
          margin: 70px auto 0;
        }

        &:after {
          background: linear-gradient(214.08deg, #1496FF 0%, #1BECB3 100%);
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          z-index: -1;
          transform: rotate(-2deg);


        }

        &:before {
          // background: linear-gradient(to right, red, orange);
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          margin: 2px;
          z-index: -2;
          transform: rotate(1deg);
          border-width: 1px;
          border-style: solid;
          border-image: linear-gradient(214.08deg, #1496FF 0%, #1BECB3 100%) 1;

        }
      }
    }

    &-mob {
      position: relative;

      .intro-slider-multi-img-cont {
        position: static;

        object-fit: cover;

        @include respond-below(sm) {
          height: calc(100vh - 160px);
        }
      }

      .intro-slider-multi-img-text {
        position: absolute;
        bottom: 50px;

        @include respond-below(sm) {
          bottom: 30px;
        }

        a {
          color: #fff;
          text-decoration: none;
        }
      }

      &::after {
        position: absolute;
        content: "";
        background-image: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0.8519607672) 10%, rgba(0, 0, 0, 0) 100%);
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 10px;

        @include respond-below(sm) {
          border-radius: 0px;
        }
      }
    }
  }

  .slick-list {
    @include respond-below(sm) {
      height: calc(100vh - 160px) !important;
    }
  }
}

.intro-slider-multi {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-between;


  &-container {
    flex: 0 0 calc(25% - 0px);
    overflow: hidden;
    position: relative;

    // width: 100px;
    // border-bottom: 1000px solid #f1f1f1;
    // border-left: 25px solid transparent;
    // border-right: 25px solid transparent;
    // -webkit-mask:
    //   /* Center area (rectangle)*/
    //   linear-gradient(red, red) center /100% calc(100% - 40px),
    //   /* triangle shape at the top*/
    //   linear-gradient(to bottom left, transparent 49%, red 51%) top / 100% 20px,
    //   /* triangle shape at the bottom*/
    //   linear-gradient(to top left, transparent 49%, red 51%) bottom / 100% 20px;
    // -webkit-mask-repeat: no-repeat;



    // &::after {
    //   content: "";
    //   height: 0;
    //   width: 100px;
    //   border-bottom: 100px solid red;
    //   border-left: 25px solid transparent;
    //   border-right: 25px solid transparent;
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    // }
    // &:nth-of-type(1) {
    //   .intro-slider-multi-img {


    //     -webkit-clip-path: polygon(0% 0%, 95% 0%, 100% 100%, 0% 100%);

    //     clip-path: polygon(0% 0%, 95% 0%, 100% 100%, 0% 100%);

    //   }
    // }

    // &:nth-of-type(2),
    // &:nth-of-type(4) {
    //   .intro-slider-multi-img {


    //     -webkit-clip-path: polygon(0 0, 100% 0, 95% 100%, 5% 100%);
    //     clip-path: polygon(0 0, 100% 0, 95% 100%, 5% 100%);

    //     &-text {
    //       padding: 0 50px;
    //     }
    //   }
    // }

    // &:nth-of-type(4) {
    //   .intro-slider-multi-img {


    //     -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 5% 100%);
    //     clip-path: polygon(0 0, 100% 0, 100% 100%, 5% 100%);

    //   }
    // }


  }

  &-img {
    // padding: 175% 0px 50px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    height: calc(100vh - 16px);
    position: relative;
    margin: 0 4px;

    a {
      position: relative;
      z-index: 1;
      color: #fff;
      text-decoration: none;

      &:hover {
        color: rgba(255, 255, 255, 0.58);
      }
    }



    // -webkit-clip-path: polygon(5% 0%, 95% 0%, 100% 100%, 0% 100%);

    // clip-path: polygon(5% 0%, 95% 0%, 100% 100%, 0% 100%);



    &:after {
      // content: attr(data-attr);
      // position: absolute;
      // top: 0;
      // left: 0;
      // width: 100%;
      // height: 100%;
      // // background-image:url(attr(data-attr));
      // // background-image: url(../../assets/img/data-attr);
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.04) 0%, rgba(0, 0, 0, 0.73) 100%);
      z-index: -1;

      border-radius: 10px;
    }

    &-text {
      display: flex;
      flex-direction: column;
      margin-top: auto;
      margin-bottom: 50px;
      padding: 0 30px;
      position: relative;
      z-index: 9;
      color: #fff;
      h3 {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

      }
      p {

        font-weight: normal;
        margin-bottom: 0;
      }
    }


  }
}

.intro-slider-multi-img-cont {
  height: 100%;
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  z-index: -1;
  border-radius: 10px;

  @include respond-below(sm) {
    border-radius: 0px;
  }

}