@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.1.0/dist/css/bootstrap.min.css");
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800;900&family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Montserrat:wght@400;500;600;700&family=Noto+Serif+Display:wght@500&family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css");
@import url("/src/assets/fonts/icomoon/icomoon.css");
@import url("/src/assets/fonts/font.css");
@import url("https://unpkg.com/bootstrap-table@1.18.3/dist/bootstrap-table.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datetimepicker/4.7.14/css/bootstrap-datetimepicker.min.css");

html,
body {
  min-height: 100vh;
}

.font-heading {
  font-family: $font-family-heading;
  font-weight: normal;
}

body {
  font-size: $font-size-body;
  color: $color-body;
  font-family: $font-family-body;
  overflow-x: hidden;
  overflow-y: auto !important;
  -webkit-text-size-adjust: none;
  // &.scroll-hidden {
  //   overflow: hidden;
  // }
}

#root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;

}

a {
  color: $color-body-light;

  &:hover {
    color: $color-body;
  }
}

ul {
  &.list-style-none {
    list-style: none;
    padding-left: 0;
    font-size: $font-size-sml;
  }
}

.container {
 
  &.container-custom {
    max-width: 1280px;
    
  }

  &.container-medium {
    max-width: 1000px;
  }

  &.container-small {
    max-width: 800px;
  }
}

.design{
  padding-left: 0;
  padding-right: 0;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  border: 1px solid #00000042;
}

.fs-body {
  font-size: $font-size-body  !important;
}

.fs-sml {
  font-size: $font-size-sml  !important;
}

.fs-xsml {
  font-size: $font-size-xsml  !important;
}

.bg-img {
  background-size: cover;
  background-position: center center;
  position: relative;
  z-index: 0;

  &:after {
    content: "";
    background-color: rgba($color-black, .7);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

.bg-primary {
  background-color: $color-primary  !important;
}

.scroll-down-sec {
  cursor: pointer;
}

@include respond-below(md) {
  .previewModal.modal-lg {
    max-width: 800px;
  }
}

@include respond-below(sm) {
  .previewModal.modal-lg {
    max-width: 80%;
    margin: 0 auto;
  }
}

// *:not(h2, h1, h3, h4, h5, h6, .h2, .h1, .h3, .h4, .h5, .h6, button) {
//   font-family: "Open Sans";
// }

.bgImg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}


