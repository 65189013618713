.livestream-container {
  display: flex;
  width: 100%;
  padding: 0 30px;
  max-width: 1280px;
  margin: 0 auto;
}

.livestream-container-video {
  flex: 0 0 calc(75% - 20px);
  height: 80%;
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid #ddd;
  padding-top: 20px;


  .ratio {
    height: 700px;
  }

  video {
    object-fit: cover;
  }
}

.livestream-container-chat {
  padding-top: 20px;
  flex: 0 0 calc(25% - 0px);

}



.livestream-streaming {
  padding: 30px 30px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;

  &-video {
    border-radius: 8px;
    flex: 0 0 calc(75% - 15px);
    margin-right: 15px;

    @include respond-below(lg) {
      flex: 0 0 calc(70% - 15px);
    }

    @include respond-below(md) {
      flex: 0 0 100%;
      width: 100%;
    }

    &.fullWidth {
      flex: 0 0 calc(100% - 30px);
      margin-right: 0;

      .livestream-video-inner {
        max-height: 100%;
      }

      .videoWrapper {

        --bs-aspect-ratio: calc(9 / 24 * 100%);

      }
    }

    .copy-icon{
      max-width: 16px;
      position: absolute;
      top: 79px;
      right: 421px;

      
    }
    .second{
      top: 154px !important;
    }
  }

  &-comments {
    position: relative;
    flex: 0 0 25%;

    @include respond-below(lg) {
      flex: 0 0 calc(30%);
    }

    @include respond-below(md) {
      flex: 0 0 100%;
    }

    .icon {
      position: absolute;
      left: -20px;
      font-size: 20px;
      padding: 50px 0px;
      background: $color-body-light;
      color: $color-white;
      border-radius: 50px 0px 0 50px;
      transform: translateY(-50%);
      top: 50%;

      @include respond-below(md) {
        display: none;
      }
    }


    &.hidden {
      flex: 0 0 auto;

      .livestream-streaming-comments-sec {
        display: none;
        height: 100%;
        min-height: 300px;
        @include respond-below(md) {
          display: block;
        }
        
      }

      .icon {
        left: 8px;
      }
    }
  }

  &-comments-sec {

    border: 1px solid #5c5c5c;
    border-radius: 8px;
    padding: 15px;

    height: calc(100% - 63px);

    @include respond-below(md) {
      height: auto;
    }

    @include respond-below(sm) {
      height: auto;
      padding: 8px 15px 0;

    }
    .ratio {
      @include respond-below(sm) {
        --bs-aspect-ratio: calc(9 / 9 * 100%);
      }
    }


  }

  &-info {
    width: 100%;
  }

  .livestream-streaming-info-sec {
    @include respond-below(md) {
      order: -1;
      padding-bottom: 10px;
      width: 100%;
    }
  }
}

.comment-label {
  display: inline-flex;
  position: absolute;
  left: 36px;
  top: 6px;
  color: #fff;
}
.comment-action-left, 
.comment-action-right {
  display: flex;
}
.comment-action-right {
  margin-left: auto;
  .switch {
    position: relative;
    display: inline-block;
    width: 80px;
    height: 34px;
    &.switch-active {
      .comment-label {
        left: 8px;
      }
    }
  }
  @include respond-below(xs) 
  {
    width: 100%;
  }
}

// .videoWrapper {
//   position: relative;
//   padding-bottom: 75.25%;
//   height: 0;
// }

// .videoWrapper video {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
// }

.livestream-video-inner {
  max-height: calc(100vh - 168px);

  .videoWrapper {
    --bs-aspect-ratio: calc(7.7 / 16 * 100%);
    background-color: #000 !important;
    border-radius: 8px;

    @include respond-below(lg) {

      --bs-aspect-ratio: calc(9 / 16 * 100%);
    }

    @include respond-below(sm) {}
  }

  @include respond-below(sm) {
    height: auto;
    max-height: inherit;
  }

  
}
.comment-action-left .text{
  font-size: 24px;
}
.sign-up-modal .size{
  padding-left: 3rem;
  padding-right: 3rem;
}
.livestream-streaming-video {
  .videoWrapper {
    &:after {
      @include respond-below(sm) {
        padding-top: 0;
        padding-bottom: 56.25%;
      }
    }
  }
}