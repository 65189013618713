.search-box {
  position: relative;
  margin-right: $spacing-base;
  -ms-flex-item-align: center;
  align-self: center;
}

.input-search {
  height: 40px;
  width: 120px;
  border: 1px solid transparent;
  padding: 10px 20px;
  outline: none;
  border-radius: 25px;
  -webkit-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
  background-color: transparent;
  padding-right: 40px;
  color: $color-body;
  font-size: 0;
  @include respond-above(xs) {
    width: 200px;
    border: 1px solid $color-gray;
    font-size: $font-size-sml;
  }
  @include placeholder {
    color: $color-body;
    opacity: 1;
  }
}

.btn-search {
  width: 50px;
  height: 40px;
  border-style: none;
  font-size: 20px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color: $color-body;
  background-color: transparent;
  pointer-events: painted;
}

.btn-search:focus~.input-search,
.input-search:focus {
  border-color: $color-gray;
  width: 175px;
  background-color: transparent;
  color: $color-body;
  -webkit-transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
  font-size: $font-size-xsml;
  @include respond-above(xs) {
    width: 250px;
  }
}

@include respond-below(xs) {
  .btn-search:focus~.input-search,
  .input-search:focus {
    width: 0;
    padding: 10px 0;
    border-color: transparent;
  }
  .search-box {
    margin-right: 0;
    .input-search {
      border-color: transparent;
    }
    .btn-search {
      &:focus {
        border-color: transparent;
      }
    }
    &.active {
      .input-search {
        width: 150px;
        border: 1px solid $color-gray;
        padding: 10px 20px;
        &:focus {
          width: 150px;
          border-color: $color-gray;
        }
      }
      .btn-search {
        width: 50px;
        &:focus {
          width: 50px;
          ~.input-search {
            width: 150px;
            border: 1px solid $color-gray;
          }
        }
      }
    }
  }
}