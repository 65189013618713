.guest-list {
  ul {
    padding-left: 0;
    margin-bottom: 150px;
    flex-direction: column;
  }

  ul,
  li {
    display: flex;
  }

  li {
    width: 100%;
    border-bottom: 1px solid $color-body-light;
    display: flex;
    padding: 10px 0;
    align-items: center;
    @include respond-below(md) {
      flex-direction: column;
      position: relative;
      align-items: flex-start;
    }

    &:hover {
      .guest-list-actions {
        opacity: 1;
      }
    }
  }

  &-actions {
    margin-left: auto;
    display: flex;
    opacity: 0;
    @include respond-below(md) {
      opacity: 1;
      position: absolute;
      top: 0;
      right: 0;
    }

    button {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &-mail {
    margin-right: 40px;
    min-width: 120px;
    &:nth-of-type(2) {
      @include respond-above(md) {
        min-width: 230px;
      }
    }
  }
}