.custom-tab {
  position: relative;
  padding-bottom: 5px;
  .line {
    height: 2px;
    position: absolute;
    bottom: -1px;
    margin: 0px;
    background: $color-primary;
    @include respond-above(sm) {
      bottom: 5px;
    }
  }
  ul.nav-pills {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    li {
      margin: 0 18px 0 0;
      // opacity: 0.7;
      transition: all 0.4s ease;
      flex: 1;
      text-align: center;
      @include respond-above(xs) {
        margin: 0 25px 0 0;
        flex: 0 0 auto;
        text-align: left;
      }
      @include respond-above(md) {
        margin: 0 60px 0 0
      }
      &:hover {
        opacity: 1;
        .dropdown-menu {
          li {
            opacity: 1;
          }
        }
      }
      &.active {
        opacity: 1;
      }
      &:last-child {
        margin-right: 0;
      }
      a {
        background-color: transparent;
        text-decoration: none;
        color: $color-body;
        text-transform: uppercase;
        display: block;
        font-size: 14px;
        padding: 0;
        position: relative;
        @include respond-above(xs) {
          letter-spacing: 0.1em;
          opacity: 0.8;
        }
        &:hover{
          opacity: 1;
        }
        &:before {
          content: "";
          width: 1px;
          position: absolute;
          left: calc(100% + 30px);
          @include respond-below(md) {  
            left: calc(100% + 15px);
          }
          @include respond-below(sm) {  
            left: calc(100% + 10px);
          }
          @include respond-below(xs) {  
            left: calc(100% + 5px);
          }
          top: 5px;
          height: 12px;
          background-color: $color-body;
        }
        &.active {
          background-color: transparent;
          color: $color-body;
          font-weight: bold;
          opacity: 1;
        }
      }
      &:last-of-type {
        a {
          &::before {
            content: none;
          }
        }
      }
    }
  }
}

#subs-subscription-tab {
  .custom-tab {
   li {
     z-index: 9;
     &:hover{
       opacity: 1;
     }
     .dropdown-menu {

       background-color: $color-white;
       li {
        padding: 5px 10px;
        margin-right: 0;
        a {
          font-size: $font-size-xsml;
          
        }
       }
     }
   } 
  }
}