.mb-3{
    .nav-item{
    
    .nav-link.active {
        background: black !important;
        color: white !important;
    }
}
}


    .verification-accept{
        background: white;
        border: 0.2px solid #12df12;
        // border-color: #12df12;
        color: #12df12;
        /* padding-right: 22px; */
        margin-right: 5px;
    }
    .verification-reject{
        background: white;
        border: 0.2px solid red;
        // border-color:  red;
        color:  red;
        /* padding-right: 22px; */
        margin-left: 5px;
    }

    .admin-withdrawl-modal{
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: space-evenly;
        input {
            margin-top: 10px;
        }
         button{
            margin-top: 10px;
         }
    }

    .after-succesful-confirmation{
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        border: 1px solid black;
        justify-content: center;
    }