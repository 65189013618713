.comment-container {
  height: 100%;
  border: 1px solid #979797;
  padding: 10px 15px 0;
  border-radius: 8px;
  @include respond-below(sm) {
    height: 350px;
  }
  min-height: 300px;
  .comment-sec {
    height: auto;
    bottom: 3px;
    top: auto;
    padding: 20px 10px 0;
    border-radius: 8px;
    background: #fff;
  }
}

.comment-sec {
  font-size: $font-size-sml;
  padding: 10px 0;

  ul {
    padding-left: 0;
    list-style: none;

  }
}