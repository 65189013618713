.alignment{
    width: 20% !important;
    margin: 25px auto;

}

// .bi-arrow-left-circle::before {
    
//     font-size: 42px;
// }

.btn-close{
    // font-size: 26px;
}
// .bi-x-lg::before{
//     font-size: 42px;
// }
// .bi::before{
//     font-size: 42px;

// }

.container .accordion-button:not(.collapsed){
    color: #2b2b2b;
    background-color: transparent !important;
    box-shadow:inset 0 -1px 0 rgb(0 0 0 / 13%);
    
}

.container .faq-header{
    font-weight:400 ;
    color: #2b2b2b;
}
.container .accordion-button{
    font-size: 1.25rem;
    color: #2b2b2b;
}
.container .accordian-body{
    color: #2b2b2b;
}
.container .accordion-button:focus{
    border-color: black;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}