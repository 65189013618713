.slider {
  &.slider-w-images-3 {
    .slider-item {
      padding: 0 30px;
      position: relative;

      &-img {
        width: 100%;
        padding: 5px;
        border: 1px solid $color-body;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    background-color: transparent;
    border: none;
    font-size: 0;
    color: $color-primary;
    z-index: 1;

    @include respond-below(sm) {
      right: calc(100% - 20px);
    }

    &:before {
      font-family: 'bootstrap-icons' !important;
      font-size: $font-size-h3;
    }

    &.slick-next {
      right: 0%;
      left: auto;

      @include respond-below(sm) {
        left: calc(100% - 20px);
      }

      &:before {
        content: "\f285";
      }
    }

    &.slick-prev {
      &:before {
        content: "\f284";
      }
    }

    &.slick-disabled {
      &::before {
        color: $color-gray-lt;
      }
    }

    &:hover {
      color: $color-primary-hover;
    }
  }
}

.slider-item-del {
  position: absolute;
  right: 40px;
  top: 10px;
  padding: 0 .5rem;
  i {margin-right: 0;}
}