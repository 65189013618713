.filter-sec {
  margin-top: $spacing-double;
  margin-bottom: $spacing-base;
  .past-collection & {
    @include respond-below(md) {
    display: flex;
    flex-direction: column;
    }
  }
  label {
    font-size: $font-size-sml;
  }
  ul {
    list-style: none;
    a{
      text-decoration: none;
    }
  }
}