.intro {
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: calc(100vh - 62px);
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: #fff;

  @include respond-above(sm) {
    height: 100vh;
  }

  &-title {
    font-size: 48px;
    position: relative;
    display: inline-block;
    @extend .font-heading;

    @include respond-above(sm) {
      font-size: 64px;
    }

    span {
      display: inline-block;
      position: relative;
    }

    .intro-inner-div {
      display: inline-block;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      text-align: center;
    }
  }

  &-subtitle {
    font-size: $font-size-h4-mob;
    position: relative;
    display: block;
    margin: 0 auto;
    max-width: 800px;
    padding: 0 20px;

    @include respond-above(sm) {
      font-size: $font-size-h4;
    }
  }
}

.intro-animation {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  z-index: 9999;

  .intro-animation-div {
    height: 100%;
    width: 100%;
    display: inline-block;
  }
}

.intro-svg-logo {
  display: block;
  margin: 0 auto;
  max-width: 300px;
}

.intro-svg-logo .st2 {
  position: relative;
  display: inline-block;
}

.home-sec {
  padding-top: 40px;
  padding-bottom: 40px;

  @include respond-above(sm) {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .container-small {
    max-width: 850px;
    margin: 0 auto;
    padding: 0 15px;
  }

  &-title {
    font-size: 48px;
    @extend .font-heading;

    @include respond-above(sm) {
      font-size: 62px;
    }
  }

  p {
    font-size: $font-size-body;
    padding: 0 5px;

    @include respond-above(sm) {
      font-size: $font-size-h4;
    }
  }

  &.home-how-does {
    text-align: center;
  }
}

// .featured-club {
//   background-color: $color-green-lt;
//   position: relative;
//   z-index: 1;
//   &-sec {
//     background-color: $color-green-lt;
//   }

//   h2 {
//     text-align: center;
//     padding-bottom: 40px;
//   }
//   .container {
//     position: relative;
//     max-width: 1200px;
//     &::after,
//     &::before {
//       background-image: url("../../assets/img/how-does-it-work.svg");
//       background-repeat: no-repeat;
//       content: "";
//       position: absolute;
//       right: -100px;
//       width: 700px;
//       height: 1000px;
//       bottom: calc(100% - 718px);
//       z-index: -1;
//       @include respond-below(sm) {
//         width: 250px;
//         height: 250px;
//         bottom: calc(100% - 100px);
//         z-index: 1;
//         right: 0;
//       }
//     }
//     &::before {
//       background-image: url("../../assets/img/how-does-it-work-btm.svg");
//       bottom: auto;
//       right: auto;
//       left: -80px;
//       top: calc(100% - 240px);
//       width: 613px;
//       height: 560px;
//       @include respond-below(sm) {
//         top: calc(100% - 115px);
//         z-index: 1;
//         left: 0px;
//         width: 250px;
//         height: 250px;
//       }
//     }
//   }
// }




// .featured-card-sec {
//   display: grid;
//   grid-template-columns: repeat(auto-fit, minmax(calc(100% - 10px), 1fr));
//   grid-auto-rows: 270px;
//   grid-auto-flow: dense;
//   gap: 10px;
//   max-width: 100%;
//   margin: auto;
//   @include respond-above(xs) {
//     grid-auto-rows: 190px;
//     grid-template-columns: repeat(auto-fit, minmax(calc(50% - 16px), 1fr));
//     gap: 16px;
//   }
//   @include respond-above(md) {
//     grid-template-columns: repeat(auto-fit, minmax(calc(25% - 16px), 1fr));
//   }
// }

// .featured-card-sec::before {
//   content: "";
//   grid-column: 1;
//   grid-row: 1;
//   // padding-bottom: 100%;
// }

// .featured-card-sec .featured-card-img:first-child {
//   grid-column: 1;
//   grid-row: 1;
// }

// .featured-card-sec .grid-h {
//   grid-column: span 1;
//   @include respond-above(md) {
//     grid-column: span 2;
//   }
// }

// .featured-card-sec .grid-v {
//   grid-row: span 1;
//   @include respond-above(md) {
//     grid-row: span 2;
//   }
// }

// .featured-card-img {
//   display: grid;
//   place-items: center;
//   border-radius: 0.25rem;
//   overflow: hidden;
//   position: relative;
//   border-radius: 10px;
//   img {
//     display: block;
//     -o-object-fit: cover;
//     object-fit: cover;
//     -o-object-position: center;
//     object-position: center;
//     position: absolute;
//     width: 100%;
//     height: 100%;
//   }
//   &:hover {
//     .featured-img-caption {
//       -webkit-transition-delay: 0s;
//       transition-delay: 0s;
//       opacity: 1;
//       -webkit-transform: translateY(0);
//       -ms-transform: translateY(0);
//       transform: translateY(0);
//       -webkit-transition: all .25s ease;
//       transition: all .25s ease;
//       ul,
//       h4,
//       h3,
//       i {
//         opacity: 1;
//         -webkit-transform: translateY(0%);
//         -ms-transform: translateY(0%);
//         transform: translateY(0%);
//         -webkit-transition: all .25s ease;
//         transition: all .25s ease;
//       }
//       ul {
//         opacity: 1;
//         -webkit-transition-delay: .15s;
//         transition-delay: .15s;
//       }
//       h3 {
//         -webkit-transition-delay: .25s;
//         transition-delay: .25s;
//       }
//       h4 {
//         -webkit-transition-delay: .35s;
//         transition-delay: .35s;
//       }
//       i {
//         -webkit-transition-delay: .4s;
//         transition-delay: .4s;
//         color: $color-primary;
//       }
//     }
//   }
// }

// .featured-img-caption {
//   position: absolute;
//   top: 0;
//   left: 0;
//   height: 100%;
//   padding: 10px;
//   width: 100%;
//   display: -webkit-box;
//   display: -ms-flexbox;
//   display: flex;
//   -webkit-box-pack: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   -webkit-box-align: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -webkit-box-orient: vertical;
//   -webkit-box-direction: normal;
//   -ms-flex-direction: column;
//   flex-direction: column;
//   z-index: 1;
//   color: $color-white;
//   opacity: 1;
//   -webkit-transform: translateY(0);
//   -ms-transform: translateY(0);
//   transform: translateY(0);
//   -webkit-transition: all .25s ease .5s;
//   transition: all .25s ease .5s;
//   @include respond-above(sm) {
//     opacity: 0;
//     -webkit-transform: translateY(100%);
//     -ms-transform: translateY(100%);
//     transform: translateY(100%);
//   }
//   ul {
//     padding-left: 0;
//     list-style: none;
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: flex;
//     margin-bottom: 5px;
//     -webkit-transition-delay: .35s;
//     transition-delay: .35s;
//     margin-bottom: 15px;
//     padding-bottom: 15px;
//     border-bottom: 1px solid rgba($color-white, .31);
//     li {
//       padding-right: 10px;
//     }
//   }
//   h4 {
//     font-size: $font-size-body;
//     -webkit-transition-delay: .15s;
//     transition-delay: .15s;
//     text-align: center;
//   }
//   h3 {
//     margin-bottom: 5px;
//     -webkit-transition-delay: .25s;
//     transition-delay: .25s;
//     font-weight: $font-weight-light;
//     text-transform: uppercase;
//   }
//   i {
//     -webkit-transition-delay: .4s;
//     transition-delay: .4s;
//   }
//   ul,
//   h3,
//   h4,
//   i {
//     @include respond-above(sm) {
//       opacity: 0;
//       -webkit-transition: all .25s ease;
//       transition: all .25s ease;
//       -webkit-transform: translateY(20px);
//       -ms-transform: translateY(20px);
//       transform: translateY(20px);
//     }
//   }
//   &:after {
//     position: absolute;
//     content: "";
//     background-color: rgba($color-black, .62);
//     height: 100%;
//     width: 100%;
//     top: 0;
//     left: 0;
//     z-index: -1;
//   }
// }

// .featured-card {
//   display: flex;
//   flex-wrap: wrap;
//   max-width: 900px;
//   margin-bottom: 50px;
//   --lh: 1.4rem;
//   line-height: var(--lh);
//   @include respond-above(xs) {
//     margin-bottom: 70px;
//   }
//   @include respond-above(md) {
//     margin-bottom: 90px;
//   }
//   @include respond-above(lg) {
//     margin-bottom: 115px;
//   }
//   &__left {
//     flex: 0 0 100%;
//     margin-left: auto;
//     display: flex;
//     flex-direction: column;
//     @include respond-above(xs) {
//       flex: 0 0 45%;
//     }
//     @include respond-above(sm) {
//       flex: 0 0 40%;
//     }
//     @include respond-above(md) {
//       flex: 0 0 380px;
//     }
//     &-img {
//       border-radius: $border-radius-default;
//       position: relative;
//       transition: all .35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
//       margin-bottom: 10px;
//       @include respond-above(xs) {
//         margin-bottom: 0px;
//       }
//       &:after {
//         position: absolute;
//         content: "";
//         width: calc(100% + 0px);
//         height: calc(100% - 0px);
//         left: 0px;
//         top: 0px;
//         border-radius: 10px;
//         transition: all .35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
//         @include respond-above(xs) {
//           left: 10px;
//           top: -10px;
//           border: 1px solid rgba(238, 12, 120, 0.44);
//         }
//       }
//       &:hover {
//         &:after {
//           left: 0;
//           top: 0;
//           width: calc(100% - 0px);
//         }
//       }
//       img {
//         max-width: 100%;
//         border-radius: $border-radius-default;
//         @include respond-below(sm) {
//           width: 100%;
//         }
//         @include respond-below(xs) {
//           height: 25vh;
//           object-fit: cover;
//         }
//       }
//     }
//   }
//   &__right {
//     flex: 0 0 100%;
//     margin-left: auto;
//     display: flex;
//     flex-direction: column;
//     @include respond-above(xs) {
//       flex: 0 0 50%;
//     }
//     @include respond-above(sm) {
//       flex: 0 0 45%;
//     }
//     @include respond-above(md) {
//       flex: 0 0 450px;
//     }
//   }
//   &__title {
//     font-weight: $font-weight-semibold;
//     line-height: normal;
//     font-size: $font-size-h3;
//     text-transform: uppercase;
//     padding-bottom: 0px;
//     @include respond-above(xs) {
//       font-weight: $font-weight-light;
//       padding-bottom: 10px;
//     }
//     &-link {
//       float: right;
//       font-size: $font-size-h4;
//       margin-top: 5px;
//       @include respond-above(xs) {
//         display: none;
//       }
//     }
//   }
//   &__post-info {
//     list-style: none;
//     padding-left: 0;
//     display: flex;
//     margin-bottom: 5px;
//     @include respond-above(xs) {
//       margin-bottom: 1rem;
//     }
//     li {
//       flex: 0 0 auto;
//       &:after {
//         content: "|";
//         margin: 0 10px;
//       }
//       @include respond-above(xs) {
//         flex: 1;
//       }
//       &:last-of-type {
//         &:after {
//           content: none;
//         }
//       }
//     }
//     font-size: $font-size-h6;
//     strong {
//       font-weight: $font-weight-bold;
//       padding-right: 10px;
//     }
//   }
//   &__post-desc {
//     font-size: $font-size-sml;
//     @include respond-below(xs) {
//       --max-lines: 3;
//       position: relative;
//       max-height: calc(var(--lh) * var(--max-lines));
//       overflow: hidden;
//       padding-right: 1rem;
//     }
//     @include respond-below(xs) {
//       &:before {
//         position: absolute;
//         content: "...";
//         /*   inset-block-end: 0;
//       inset-inline-end: 0; */
//         bottom: 0;
//         right: 0;
//       }
//       &::after {
//         content: "";
//         position: absolute;
//         /*   inset-inline-end: 0; */
//         right: 0;
//         width: 1rem;
//         height: 1rem;
//         background: white;
//       }
//     }
//     p {
//       display: block;
//       font-size: $font-size-sml;
//       margin-bottom: 5px;
//     }
//     a {
//       margin-bottom: 15px;
//       display: inline-block;
//     }
//   }
//   &__author {
//     margin-top: auto;
//     &-name {
//       font-size: $font-size-h4;
//       position: relative;
//       margin-bottom: 0px;
//       text-align: right;
//       @include respond-above(xs) {
//         margin-bottom: 55px;
//         text-align: left;
//       }
//       &:after {
//         content: attr(data-title);
//         position: absolute;
//         font-size: 12vw;
//         left: auto;
//         right: 0;
//         top: calc(100% - 30px);
//         filter: blur(1px);
//         text-shadow: 1px 0 0 rgba($color-black-lt, .5), 0 1px 0 rgba($color-black-lt, .5), -1px 0 0 rgba($color-black-lt, .5), 0 -1px 0 rgba($color-black-lt, .5);
//         color: #fff;
//         z-index: -1;
//         @include respond-above(xs) {
//           font-size: 7vw;
//           top: calc(100% - 43px);
//           left: 0px;
//         }
//         @include respond-above(sm) {
//           left: -70px;
//         }
//         @include respond-above(md) {
//           font-size: 75px;
//         }
//       }
//     }
//   }
//   &:nth-of-type(2n) {
//     flex-direction: row-reverse;
//     .featured-card__left-img {
//       &:after {
//         border-color: rgba(12, 150, 138, .44);
//         left: auto;
//         right: 0px;
//         width: 100%;
//         @include respond-above(xs) {
//           right: 10px;
//         }
//       }
//       &:hover {
//         &:after {
//           right: 0;
//         }
//       }
//     }
//     .featured-card__right {
//       margin-left: 0;
//       margin-right: auto;
//       .featured-card__post-desc {
//         @include respond-above(sm) {
//           padding-right: 70px;
//         }
//       }
//     }
//     .featured-card__author-name {
//       text-align: right;
//       @include respond-above(sm) {
//         text-align: right;
//       }
//       &:after {
//         right: 0;
//         @include respond-above(sm) {
//           left: auto;
//           right: -70px;
//         }
//       }
//     }
//   }
// }
// .welcome {
//   height: 100vh;
//   width: 100vw;
//   position: absolute;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: $color-black;
//   z-index: 999999;
// }
// .logo {
//   opacity: 0;
// }
// .club-text {
//   opacity: 0;
// }


.featured-club-new {
  padding-top: 100px;
  padding-bottom: 91px;
  background-color: #f4fcf8;

}

.why-us,
.featured-club-new {
  position: relative;
  z-index: 0;
}

.featured-club-new:after,
.why-us:after {
  content: "";
  background-image: url(../../assets/img/two-triangle.svg);
  width: 180px;
  height: 180px;
  position: absolute;
  right: 100px;
  top: 100px;
  z-index: -1;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: right top;
}

.featured-club-new .container {
  max-width: 1200px;
}

.featured-club-new ul {
  column-count: 4;
  column-gap: 20px;
  padding-left: 0;
  list-style: none;
}

.featured-club-new ul li {
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.21);
  display: inline-block;
  width: 100%;
  transition: transform 0.35s ease;
}

.featured-club-new ul li img {
  max-width: 100%;
  border-radius: 10px;
}

.featured-club-new ul li:first-of-type {
  margin-top: 64px;
}

.featured-club-new ul li:nth-of-type(5) {
  margin-top: 128px;
}

.featured-club-new ul li:nth-of-type(7) {
  margin-top: 192px;
}

.featured-club-img {
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
  display: inline-block;
  vertical-align: top;
  padding-bottom: 100%;
}

.featured-club-img>img {
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.featured-club-new li:hover .featured-club-caption img {
  transform: scale(1.35);
}


.featured-club-caption {
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 1;
  color: #fff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
  opacity: 0;
}


.featured-club-new li:hover .featured-club-caption {
  opacity: 1;
}

.featured-club-caption img {
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  background-color: #fff;
  margin-bottom: 8px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.21);
  transition: all 0.35s;
}

.featured-club-caption h3,
.featured-club-caption h4 {
  margin-bottom: 0;
}

.featured-club-caption h3 {
  font-size: 16px;
  font-weight: bold;
  color: #f8f8f8;
}

.featured-club-caption h4 {
  font-size: 14px;
  margin-bottom: 50px;
  font-weight: normal;
}

.featured-club-caption ul {
  display: block;
  column-count: inherit;
}

.featured-club-new .featured-club-caption ul li {
  margin-top: 0 !important;
  box-shadow: none;
  margin-bottom: 0px;
  font-size: 14px;

}

.featured-club-caption:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, .2);
  top: 0;
  left: 0;
  border-radius: 10px;
  z-index: -1;
}

.featured-club-text {
  margin-left: 159px;
  width: 541px;
  margin-top: -140px;
}

.featured-club-text h2 {
  font-size: 105px;
  font-family: "Billy Ohio";
  font-weight: normal;
  margin-bottom: 0;
  padding-bottom: 0;
  text-align: left;
}

.featured-club-text .btn {
  display: inline-flex;
}

.dive-in {
  background-color: #383838;
}

.dive-in-top {
  display: flex;
  margin-bottom: 50px;
}

.dive-in-top h2 {
  font-size: 105px;
  font-family: "Billy Ohio";
  font-weight: normal;
  margin-bottom: 0;
  padding-bottom: 0;
  text-align: left;

}

.dive-in-top-rt {
  width: 560px;
  margin-left: auto;
  text-align: left;
}

.dive-in-top-rt p {
  color: #fff;
}

.dive-in-top-rt .btn {
  display: inline-flex;
}

.dive-in-slide {
  padding: 0 10px;
  text-align: center;
}

.dive-in-slide-inner {
  display: flex;
  flex-direction: column;
  padding: 100% 40px 18px;
  color: #fff;
  text-align: center;
  border: 1px solid none;
  border-radius: 10px;
  margin-bottom: 20px;
  transition: all 0.35s ease;
  background-color: #2d2d2d;
  overflow: hidden;
}

.dive-in-slide-inner h3 {
  margin-bottom: 5px;
  padding-bottom: 0;
  white-space: nowrap;
}

.dive-in-slide-inner h3 a {
  text-decoration: none;
  color: #fff;
  transition: all 0.35s ease;
}

.dive-in-slide-inner h3 a:hover {
  color: #18CC73;
  letter-spacing: 1px;
}



.dive-in-slide-inner img,
.dive-in-slide-inner video {
  // width: 224px;
  // height: 224px;
  border-radius: 50%;
  margin: 0 auto 10px;
  border: 2px solid #18CC73;
  transition: all 0.35s ease;
  object-fit: cover;
  // height: auto;
  max-width: 90px;
}

.dive-in-slide-inner video {
  height: 100%;
}

.dive-in-slide-inner img:hover,
.dive-in-slide-inner video:hover {
  border-width: 0;
}

.dive-in-date {
  margin-top: 25px;
  margin-bottom: 10px;
}

.dive-in-slide .btn {
  display: inline-flex;
  opacity: 1;
  transition: all 0.35s ease;
  margin: auto;
  background-color: $color-white;

}

// .dive-in-slide .btn:hover {
//   background-color: #303030;

// }

// .dive-in-slide .btn:active {
//   background-color: #222;
// }

.dive-in-channel {
  color: #b2b2b2;
  font-size: 14px;
  text-decoration: none;
  transition: all 0.25s;
}


.dive-in-slider .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0;
  background-color: transparent;
  border: none;
  color: #18CC73;

}

.dive-in-slide .ratio {
  right: 0;
  transition: all 0.35s ease;
  margin-left: auto;
  margin-right: auto;
  max-width: 90px;
}

.dive-in-slide-inner:hover .ratio {
  width: 90%;
  height: 90%;
}

.dive-in-slide .dive-in-slide-inner:hover .btn {
  transform: translateY(0);
  opacity: 1;
}

.dive-in-slider .slick-arrow.slick-prev {
  right: 100%;
}

.dive-in-slider .slick-arrow.slick-next:after,
.dive-in-slider .slick-arrow.slick-prev:after {
  font-size: 30px;
  font-family: "bootstrap-icons";

}

.dive-in-slider .slick-arrow.slick-prev:after {
  content: "\f284"
}

.dive-in-slider .slick-arrow.slick-next:after {
  content: "\f285"
}

.dive-in-slider .slick-arrow.slick-next {
  left: 100%;
}

.dive-in-slider .slick-arrow.slick-disabled {
  color: #ddd;
  opacity: 0.3;
}

.dive-in-slide:hover .dive-in-slide-inner {
  background: #303030;
  border-color: #5f5f5f;
}

.dive-in-slide:hover .dive-in-slide-inner .dive-in-channel {
  color: #fff;
}

.livestream-sec {
  position: relative;
  z-index: 0;
}

.livestream-sec:after,
.livestream-sec:before {
  content: "";
  background-image: url(../../assets/img/triangle-5.svg);
  width: 1000px;
  height: 1000px;
  position: absolute;
  right: -215px;
  top: 10px;
  z-index: -1;
  background-size: 45% auto;
  background-repeat: no-repeat;
  background-position: right top;

}

.livestream-sec:before {
  left: -128px;
  right: auto;
  background-position: left 57%;
  background-size: 40% auto;
}

.dive-in-slide-inner {
  position: relative;
}

.dive-in-slide-inner::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  border-radius: 8px;
}

.dine-in-slide-content {
  display: flex;
  flex-direction: column;
  transition: all 0.35s ease;
}

.dive-in-slide-inner:hover .dine-in-slide-content {
  transform: translateY(-20px);
}

.exclusive-content {
  background-color: #18CC73;
  padding-top: 190px;
  padding-bottom: 100px;
  position: relative;
  color: #fff;
  background-image: url(../../assets/img/NFT.jpg);

  background-size: 100% auto;
  background-position: center center;
}



/* 
.exclusive-content p {
  margin-left: auto;
} */

.exclusive-content::after,
.exclusive-content::before {
  content: "";
  background-color: #18cc73;
  height: 100px;
  width: 100%;
  position: absolute;
  bottom: 100%;
  left: 0;
  z-index: -1;

}

.exclusive-content::after {
  height: 100%;
  z-index: 0;
  bottom: auto;
  top: 0;
  opacity: 0.9;
}

.exclusive-content .container {
  position: relative;
  z-index: 1;
}

.exclusive-content-desc {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.exclusive-content-desc img {
  width: 150px;
}

.exclusive-content h2 {
  font-size: 140px;
  padding-bottom: 100px;
  font-weight: 800;
  line-height: 120px;
}

.exclusive-content p {
  padding-left: 50px;
  width: 70%;
  font-size: 20px;
}

.why-us h2 {
  font-size: 105px;
  font-family: "Billy Ohio";
  font-weight: normal;
  text-align: left;
  margin-bottom: 20px;
}

@media only screen and (max-width:1100px) {
  .featured-club-caption {
    padding-top: 25px;
  }
}

@media only screen and (max-width:991px) {

  .featured-club-new ul {
    column-count: 2;
  }

  .featured-club-new ul li:nth-of-type(7) {
    margin-top: 0;
  }

  .featured-club-new .container {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
  }

  .featured-club-text {
    margin-left: 0;
    width: 100%;
    margin-top: 0 !important;
    order: -1;
  }

  // .dive-in-slide-inner img {
  //   width: 100%;
  //   height: 100%;
  // }

  .dive-in-top {
    flex-direction: column;
  }

  .dive-in-top-rt {
    width: 100%;
  }

  .exclusive-content {
    padding-top: 100px;
    padding-bottom: 50px;
  }

  .exclusive-content h2 {
    font-size: 100px;
    padding-bottom: 50px;
    line-height: 90px;
  }
}

@media only screen and (max-width:767px) {

  .dive-in-slide-inner h3,
  .exclusive-content p {
    font-size: 16px;
  }

  .dive-in-date {
    margin-top: 20px;
    font-size: 14px;
  }

  .featured-club-new {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  .featured-club-caption {
    opacity: 1;
  }

  .dive-in-top h2,
  .featured-club-new h2,
  .why-us h2 {
    font-size: 60px;
  }

  .dive-in-slide .dive-in-slide-inner {
    background: #222;
    border-color: #5f5f5f;
  }

  .exclusive-content-desc {
    flex-direction: column;
  }

  .exclusive-content h2 {
    font-size: 48px;
    padding-bottom: 30px;
    font-weight: 800;
    line-height: 1;
  }

  .exclusive-content {
    padding: 50px 0;
  }

  .exclusive-content p {
    padding-left: 0;
    width: 100%;
  }
}

@media only screen and (max-width:575px) {
  .featured-club-new ul {
    column-count: 1;
  }

  .featured-club-new ul li:nth-of-type(5) {
    margin-top: 0;
  }

  .dive-in-slider .slick-arrow.slick-prev {
    left: -30px;
    right: auto;
  }

  .dive-in-slider .slick-arrow.slick-next {
    right: -30px;
    left: auto;
  }

  .dive-in-top-slider {
    padding: 0 20px;
  }
}

@media only screen and (max-width:479px) {}


// New Homepage
.parallex-sec {
  .sec-home{
    &:nth-child(2n){
      .bgImg {
        background-position: right top;
        right: 0;
        left: auto;
      }
      .container {
        justify-content: flex-start;
      }
    }
  }
}
.sec-home {
  height: 100vh;
  position: relative;
  display: flex;

  .bgImg {
    background-size: 100%;
    background-position: left top;
    background-attachment: fixed;
    width: 100%;
    // position: absolute;
    height: 100vh;
    left: 0;
    top: 0;
  }

  .container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    padding: 0;
  }

  &-text {
    width: 100%;
    padding:50px 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}