.livestream-coll-sec {
  padding: 50px 0;
  &:first-of-type {
    padding-top: 60px;
    @include respond-above(sm) {
      padding-top: 120px;
    }
    
  }

  &-title {
    text-align: center;
    margin: 0 auto;
    margin-bottom: $spacing-double;
    display: flex;
    flex-direction: column;
    @include respond-below(sm) {
      margin-bottom: $spacing-base;
    }

    .btn {
      margin: 0 auto;
    }
  }

  .container {
    @include respond-below(sm) {
      max-width: 100%;
    }
  }
}

.language-dropdown {
  height: 64px;
  margin-left: 15px !important;
  @include respond-below(md) {
    margin-left: 0px!important;
  }
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  // overflow: inherit !important;
  // white-space: normal !important;
  // @include respond-below(sm) {
  //   padding: 8.5px 14px;
  // }
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root{
  @include respond-below(sm) {
    
    top: -5px;
  }
}