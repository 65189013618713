.radio-w-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1rem;
  .radio {
    flex: 0 0 auto;
    .custom-radio {
      label {
        &::before {
          top: 0;
        }
        &::after {
          top: 4px;
        }
      }
    }
  }
  .input {
    flex: 1;
  }
}