.dropdown-menu {
  border-color: transparent;
  box-shadow: 0 1.1px 2.6px rgba($color-black, 0.02), 0 9px 21px rgba($color-black, 0.1);
  width: 200px;
}

.dropdown-item {
  font-size: $font-size-sml;

  &.active,
  &:active {
    background-color: rgba($color-body-light,0.4);
  }
}

.create-post {
  .dropdown-menu {
    width: 150px;
    padding: 0;
    border-radius: $border-radius-default;

    li {
      &:first-of-type {
        a {
          border-radius: $border-radius-default $border-radius-default 0 0;
        }
      }

      &:last-of-type {
        a {
          border-radius: 0 0 $border-radius-default $border-radius-default;
        }
      }
    }
  }

  .dropdown-item {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}


.dropdown-notification {
  margin: 10px ($spacing-base + 5px) 10px $spacing-base;

  .dropdown-icon {
    display: block;

    i {
      font-size: 22px;
    }

    span {
      font-size: 11px;
    }

  }

  .dropdown-menu.notification {
    width: 400px;
    top: calc(100% + 16px);
    right: -30px;
    padding-bottom: 0px;

    &::after {
      content: "";
      position: absolute;
      right: 20px;
      bottom: calc(100% - 5px);
      width: 0;
      height: 0;
      border-width: 0 20px 20px 20px;
      border-color: transparent transparent #FFF transparent;
      border-style: solid;
      filter: drop-shadow(0 -6.9px 4.6px rgba(0, 0, 0, .05));
    }
  }

  .notification-heading-sec {
    a {
      text-decoration: none;
    }
  }

  #notificationTab {
    border: 1px solid $color-body;
    border-radius: 0.25rem;

    .nav-item {

      flex: 1;

      &:first-of-type {
        .nav-link {
          border-radius: 0;
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }
      }

      &:last-of-type {
        .nav-link {
          border-radius: 0;
          border-top-right-radius: 0.25rem;
          border-bottom-right-radius: 0.25rem;
        }
      }

    }

    .nav-link {
      border: none;
      width: 100%;
      border-radius: 0rem;
      color: $color-body;

      &:hover,
      &.active {
        background-color: $color-body-light;
        color: $color-white;
      }
    }

  }

  #notificationTabContent {
    max-height: 400px;
    overflow-y: auto;

    .card {
      padding: 7px 0;

      &.card-normal {
        border: none;
        padding-bottom: 15px;

        .card-post-info {
          margin-bottom: 3px;

          a {
            display: block;
            color: $color-body;
            text-decoration: none;

            &:hover {
              color: $color-body-light;
            }
          }
        }
      }
    }

    .card-post {
      display: flex;
      flex-direction: row;
      border-bottom: none;

    }

    .card-post-img {
      margin-right: 15px;
      border: 1px solid $color-body;

      img {
        vertical-align: top;

      }
    }

    .card-post-detail {
      font-size: $font-size-sml;
    }

    .card-posted-info {
      font-size: $font-size-xsml;
      color: $color-primary;

      &.older {
        color: $color-body;
      }
    }

  }
}

.notification {
  // position: relative;


}