@font-face {
  font-family: 'Billy Ohio';
  src: url('../fonts/BillyOhio.eot');
  src: url('../fonts/BillyOhio.eot?#iefix') format('embedded-opentype'), url('../fonts/BillyOhio.woff2') format('woff2'), url('../fonts/BillyOhio.woff') format('woff'), url('../fonts/BillyOhio.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* @font-face {
  font-family: 'OpenSans-Regular';
  src: url('../fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Light';
  src: url('../fonts/OpenSans/OpenSans-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Medium';
  src: url('../fonts/OpenSans/OpenSans-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-SemiBold';
  src: url('../fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: url('../fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'OpenSans-ExtraBold';
  src: url('../fonts/OpenSans/OpenSans-ExtraBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */