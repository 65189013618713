.card-subscription {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid $color-form-border;

  &-left {
    flex: 9999;
    display: flex;
    margin-right: $spacing-base;

  }
  &-right {
    margin-left: auto;
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .subscription-per-info {
      font-size: $font-size-sml;
      margin-bottom: 5px;

    }

    &-btm {
      margin-top: auto;
      display: flex;
      flex-wrap: wrap;

      .btn {
        margin-left: 5px
        }
    }
  }

  &-title {
    font-size: $font-size-h6;
    margin-bottom: 5px;

    .subscription-exp-info {
      font-size: $font-size-xsml;
      font-weight: normal;
      padding-left: $spacing-base;
    }

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-username {
    font-size: $font-size-sml;
    font-weight: normal;
    padding-left: $spacing-base - 5;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &-detail {
    display: flex;
    flex-flow: column nowrap;
  }

  .subscription-detail {
    padding-bottom: 5px;
    font-size: $font-size-sml;

    >* {
      padding-right: $spacing-base;
      margin-right: $spacing-base;
    }

    .subscription-term {
      border-right: 1px solid $color-form-border;
    }
    .subscription-term-period {
      text-transform: capitalize;
    }

  }

  .subscription-pkg-info {
    font-size: $font-size-xsml;

    .subscription-pkg-name {
      padding-right: $spacing-base;
    }

    .subscription-pkg {
      text-decoration: none;
    }
  }

  .card-subscription-img {
    flex: 0 0 150px;
    margin-right: $spacing-base;
  }

  .subscription-schedule {
    padding-bottom: $spacing-base - 10;

    &-date {
      padding-right: 10px;
    }
  }

  .subscription-download-info {
    display: flex;

    &-box {
      display: flex;
      flex-direction: column;
      padding: 5px 15px;
      border: 1px solid #ddd;
      margin-right: 10px;
      font-size: 14px;
    }
  }

  .subscription-comments {
    margin-bottom: 10px;
  }

  &.expired {
    .subscription-per-info {
      color: $color-orange;
    }
  }
}

#subs-subscription-tab {
  .card-subscription-left {
    flex-direction: row;
  }
}