.payment-history-sec {
  margin-bottom: $spacing-double + $spacing-double;
  .payment-history__top {
    margin-bottom: $spacing-base;
    display: flex;
    .payment-history__info {
      font-size: $font-size-xsml;
    }
    &-right {
      margin-left: auto;
    }
  }
}

.payment-history__middle {
  display: flex;
  &-right {
    margin-left: auto;
    .payment-history__info {
      font-size: $font-size-xsml;
    }
  }
}

.payment-history__bottom {
  table th {
    &:nth-of-type(3),&:nth-of-type(4),&:nth-of-type(5){
      min-width: 170px;
    }
  }
}