.category-list {
  margin-bottom: 120px;
  position: relative;
  z-index: 1;
  background-color: #fff;
  min-height: 100vh;

  .container {
    display: flex;
    flex-wrap: wrap;
  }

  .featured-card {
    margin-bottom: 20px;
    flex: 0 0 100%;
    margin-right: 0px;
    border: 1px solid $color-body;
    border-radius: $border-radius-default;
    max-width: 100%;
    flex-direction: column;
    flex-wrap: nowrap;

    @include respond-above(xs) {
      flex: 0 0 calc(50% - 5px);
      margin-right: 10px;
    }

    @include respond-below(sm) {
      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    @include respond-above(sm) {
      flex: 0 0 calc(33.33% - 13.33333px);
      margin-right: 20px;
    }

    @include respond-between(sm, md) {
      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    @include respond-above(md) {
      flex: 0 0 calc(25% - 20px);
      width: 300px;

    }

    &__left {
      flex: 0 0 auto;
      width: 100%;

      &-img {
        border-radius: $border-radius-default;
        position: relative;
        width: 100%;
        display: block;
        margin-bottom: $spacing-base;

        img {
          width: 100%;
          object-fit: cover;
          border-radius: $border-radius-default;
        }

        &:after {
          content: none;
        }
      }
    }

    &__right {
      flex: 0 0 auto;
      margin-left: 0;
      text-align: center;
    }

    &__title {
      padding-top: 10px;
      font-size: $font-size-body;
      text-transform: none;
      font-weight: $font-weight-bold;
      padding-bottom: 0;
      text-decoration: none;
      color: $color-black-4e;
    }

    &__handle {
      font-size: $font-size-sml;
      color: $color-gray-dk;
      margin-bottom: $spacing-double - 5;
    }

    &__post-info {
      font-size: $font-size-sml;
      display: flex;
      justify-content: space-between;
      padding: 0 15px;
      list-style: none;
      color: $color-hamburger;

      @include respond-below(xs) {
        justify-content: center;
      }

      margin-bottom: 8px;

      strong {
        padding-right: 2px;
      }

      @include respond-above(xs) {
        &:after {
          display: none !important;
        }
      }
    }

    &__post-desc {
      font-size: $font-size-sml;

      p {
        display: block;
        font-size: $font-size-sml;
        margin-bottom: 5px;
      }

      a {
        margin-bottom: 15px;
        display: inline-block;
      }
    }

    &__author {
      display: none;
      margin-top: auto;

      &-name {
        font-size: $font-size-h4;
        position: relative;
        margin-bottom: 55px;

        &:after {
          content: attr(data-title);
          position: absolute;
          font-size: 75px;
          left: -70px;
          top: calc(100% - 43px);
          filter: blur(1px);
          text-shadow: 1px 0 0 rgba($color-body, .5), 0 1px 0 rgba($color-body, .5), -1px 0 0 rgba($color-body, .5), 0 -1px 0 rgba($color-body, .5);
          color: #fff;
          z-index: -1;
        }
      }
    }

    &:nth-of-type(2n) {
      .featured-card__right {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
}

.category-listing {
  &.sidebar {
    background-color: $color-white;
    border-color: $color-body;
    box-shadow: none;

    li {
      border-color: $color-body;

      a {
        color: $color-body;

        &:hover {
          background-color: $color-primary-dk;
          color: $color-white;
        }

      }
    }
  }
}

.category-listing-main-content {
  margin-left: 275px;
  width: calc(100% - 275px);
}

.category-slider {
  .slick-arrow {
    top: 0;
    transform: none;

    width: 50px;
    height: calc(100% - 20px);
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 50%, rgba(0, 0, 0, 0.07) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 50%, rgba(0, 0, 0, 0.07) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 50%, rgba(0, 0, 0, 0.07) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

  }
}