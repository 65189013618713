/* nft-listing-banner */
.nft-listing-banner {
  background-color: #151515;

  height: calc(100vh - 70px);

  @include respond-below(sm) {
    height: auto;
    padding: $spacing-double 0;
  }
}

.nft-listing-banner .container {
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: calc(100vh - 130px);
  flex-wrap: wrap;
  overflow: hidden;
  justify-content: space-around;
  @include respond-below(sm) {
    height: auto;
  }
}

.nft-listing-banner-left {
  // padding-left: 145px;
  // padding-right: 120px;
  // flex: 0 0 650px;
  flex: 0 0 35%;
  color: #fff;
  @include respond-below(sm) {
    flex: 0 0 100%;
  }
}

.nft-listing-banner-right {
  display: flex;
  flex: 0 0 50%;
  flex-wrap: wrap;
  // margin-left: auto;
  margin-top: 80px;
  margin-bottom: 100px;
  height: calc(100vh - 200px);
  @include respond-below(sm) {
    display: none;
  }
}

.nft-listing-banner-right .nft-listing-banner-right-img {
  flex: 0 0 calc(33.33% - 14px);
  margin-bottom: 14px;
  margin-right: 14px;
  overflow: hidden;
  transition: transform 0.35s cubic-bezier(0.075, 0.82, 0.165, 1)
}

.nft-listing-banner-right .nft-listing-banner-right-img {}

.nft-listing-banner-right .nft-listing-banner-right-img img {
  max-width: 100%;
  transition: transform 0.35s cubic-bezier(0.075, 0.82, 0.165, 1);
  object-fit: cover;

}

.nft-listing-banner-right .nft-listing-banner-right-img:hover img {
  transform: scale(1.1);
}