.post-detail {
  .post-card {
    border: none;
    margin-bottom: $spacing-base - 5;
    &__img {
      margin-bottom: $spacing-base - 5;
      border: 1px solid $color-form-border;
      border-radius: 0;
      >img, >video, > audio {
        border-radius: 0;
        vertical-align: top;
        height: 500px;
        object-fit: contain;
      }
    }
    &__footer {
      -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
      order: 0;
      padding: 0;
      &-left {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
        margin-left: auto;
        li {
          &:last-of-type {
            padding-right: 0;
          }
        }
      }
      &-right {
        margin-left: 0;
      }
    }
    &__body {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
      padding: 0;
      border-bottom: 1px solid $color-form-border;
    }
  }
}

.comment-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  @include respond-below(sm) {
    margin-bottom: 0;
  }
  &-photo {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 36px;
    flex: 0 0 36px;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    border: 1px solid $color-form-border;
    margin-right: 15px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
}

.comment-count {
  margin-bottom: 15px;
  display: block;
  font-size: $font-size-sml;
}

.comment-add {
  -webkit-box-flex: 99999;
  -ms-flex: 99999;
  flex: 99999;
  textarea {
    width: 100%;
    resize: none;
    height: 37px;
  }
}

.commented-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: $spacing-base;
  &-left {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 36px;
    flex: 0 0 36px;
    border-radius: 50%;
    height: 36px;
    width: 36px;
    margin-right: 15px;
    border: 1px solid $color-form-border;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      -o-object-fit: cover;
      object-fit: cover;
      font-size: 0;
    }
  }
  &-right {
    -webkit-box-flex: 9999;
    -ms-flex: 9999;
    flex: 9999;
    .comment-name {
      a {
        text-decoration: none;
        color: $color-body
      }
      font-size: $font-size-sml;
      font-weight: $font-weight-semibold;
      span {
        padding-left: 10px;
        font-size: $font-size-xsml;
        pointer-events: none;
        color: $color-black-2e;
      }
    }
    .comment-desc {
      font-size: $font-size-sml;
      margin-bottom: $spacing-base - 5px;
    }
  }
}

.comment-count-info {
  list-style: none;
  padding-left: 0;
  font-size: $font-size-sml;
  margin-bottom: 0;
  li {
    display: inline-block;
    padding-right: $spacing-double;
    i {
      padding-right: $spacing-base - 10px;
    }
  }
}

.recent-posts-sec {
  padding: 10px;
  border: 1px solid $color-form-border;
  .card-post {
    padding-top: 0;
    border-bottom: none;
    padding-bottom: 10px;
    .card-post__content {
      &-left {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
      }
      &-desc {
        h3 {
          margin-bottom: 3px;
          font-size: $font-size-sml;
        }
        p {
          margin-bottom: 3px;
          font-size: $font-size-xsml;
        }
      }
      &-img {
        width: 64px;
        height: 64px;
        border: 1px solid $color-form-border;
        img {
          width: 100%;
          height: 100%;
          -o-object-fit: cover;
          object-fit: cover;
        }
      }
      .comment-count-info {
        @extend .comment-count-info;
        li {
          padding-right: 10px;
        }
      }
    }
  }
}

.activity-info-sec{
  margin-top: $spacing-double;
  @include respond-above(sm) {
    margin-top: 0;
    border-left: 1px solid $color-gray;
    padding-left: $spacing-double;
  }

}
.activity-info {
  padding: 10px;
  border-radius: 8px;
  background-color: $color-offwhite1;
  margin-bottom: 8px;
  font-size: $font-size-sml;
  p {
    margin-bottom: 3px;
  }
  a {
    text-decoration: none;
  }
  .activity-info-title {
    font-size: $font-size-body;
  }
  img {
    margin-top: 15px;
    width: 100%;
  }
}