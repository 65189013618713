.fixed-bottom-menu {
  background: #000;
  margin-top: 100px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99;
  padding: 10px 0;
  .main-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-bottom: 0;
    padding-left: 0;
    li {
      list-style: none;
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      text-align: center;
    }
    a {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      color: $color-white;
      font-size: $font-size-xsml;
      text-decoration: none;
      i {
        font-size: $font-size-h6;
      }
      &:hover,
      &.active {
        color: $color-primary-lt;
      }
    }
  }
}