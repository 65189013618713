.link {
  font-size: $font-size-sml;
  text-decoration: none;
  &-primary {
    color: $color-primary;
    &:hover {
      color: $color-primary-hover;
    }
  }
}

.link-effect{
  text-decoration: none;
  &:hover{
    text-decoration: underline;
  }
}

.link-underline{
  text-decoration: underline;
  &:hover{
    text-decoration: none;
  }
}