.checkout-count {
    span {
        
        cursor: pointer;
        user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
&.dec {
    font-size: $font-size-h3;
    line-height: 0;
}
    }
    .form-control {
        max-width: 70px;
        display: inline-flex;
    }
}

