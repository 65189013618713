@include respond-below(sm) {
  .my-club {
    // .container {
    //   padding: 0;
    // }
    .custom-tab {
      width: calc(100% + 24px);
      border-bottom: 1px solid $color-divider;
      margin-left: -12px;
      ul.nav-pills {
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 100%;
        padding: 0 15px;
      }
    }
    .tab-content {
      padding: 0;
    }
  }
}
.post-card__body {
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}