.banner-cover {
  &.category-detail-page {
    margin-bottom: 150px;
    z-index: 1;
    border-bottom: 1px solid $color-body;

    @include respond-below(sm) {
      position: relative;
      margin-bottom: 30px;

      &:after {
        position: absolute;
        content: "";
        background-color: rgba($color-black, .7);
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 0;
      }
    }
  }

  &.livestream {
    height: 100vh;
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    align-items: flex-end;
    max-width: inherit;
    margin: inherit;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;


    @include respond-below(sm) {
      flex-direction: column;
      padding: 0 0px;
      height: calc(100vh - 110px);
      justify-content: flex-end;
      // position: fixed;
      width: 100%;

    }

    .banner-cover-profile {
      bottom: 0;
      top: auto;
      max-width: 640px;
      min-width: 420px;
      // height: calc(100vh - 30px);
      margin-left: 0px;
      margin-bottom: inherit;

      // @media only screen and (max-width:1366px) {
      //   width: 47%;
      // }
      @media only screen and (max-width:991px) {
        width: 100%;
        min-width: 100%;
      }

      @include respond-below(sm) {
        position: static;
        width: 100%;
        max-width: 725px;
        justify-content: flex-end;
      }

      &__left {
        margin-bottom: -35px;

        &-img {
          margin-bottom: 0;
        }

        flex-direction: column;
        align-items: center;
      }

      &__left,
      &__right {
        width: 100%;
      }

      &__right {

        box-shadow: 0 0 16px 9px rgba(0, 0, 0, 0.17);
        max-height: calc(100% - 200px);

        // background-color: rgba($color-black, .49);
        border-radius: $border-radius-default;
        border: 1px solid rgba($color-black, .23);
        // padding-top: 60px;
        min-height: 250px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;

        background-color: rgba(0, 0, 0, 0.49);
        backdrop-filter: blur(6px);

        @include respond-below(sm) {
          padding-top: 80px;
          max-height: calc(100% - 50px);


        }

        .livestream-desc {
          font-size: $font-size-body;

          p {
            padding-bottom: 0;
          }
        }

      }

      &__info {
        text-align: center;
        flex-shrink: 1;
        margin-bottom: 20px;
        margin-top: 0px;

        h3 {
          font-size: 18px;

          a {
            text-decoration: underline !important;
          }
        }

        h1 {
          font-size: 24px;
          margin-bottom: 10px;
          letter-spacing: normal;
          font-weight: 900;
        }
      }

    }

    .livestream-details {
      flex-grow: 1;
      overflow-y: auto;

    }

    .livestream-detail-btm {
      text-align: center;
      padding-top: 15px;

      .checkout-btn-sec {
        @include respond-below(md) {
          margin-right: 5px;
          margin-bottom: 0;
        }
      }

      strong {
        margin-bottom: 20px;
        display: block;
      }

    }

    .livestream-detail-main-img-bg {
      height: 100vh;
      display: flex;
      justify-content: space-between;
      padding: 0 30px;
      align-items: flex-end;
      max-width: inherit;
      margin: inherit;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      filter: blur(6px);

      @include respond-below(sm) {
        filter: blur(0px);
      }

    }

    .livestream-main-bg-img {
      width: auto;
      object-fit: inherit;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      z-index: -1;

      @include respond-below(sm) {
        display: none;
      }

    }

    .checkout-desc {
      margin-bottom: 10px;

      @media only screen and (max-width:1370px) {
        margin-bottom: 0;
      }
    }

    .checkout-desc {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }

    .checkout-card {
      .checkout-count {
        label {
          min-width: 125px;
        }
      }
    }
  }

  &.livestream-soon {
    height: 100vh;

    .banner-cover-profile__right {
      max-height: calc(100% - 80px);
    }
  }
}

.livestream-desc {
  border: 1px solid #000;
  padding: 15px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.29);
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.19);

  h3 {
    font-size: 16px;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  a {
    margin-left: auto;
    color: $color-white;
  }
}

.banner-cover-profile {
  max-width: 1280px;
  padding-left: $spacing-base;
  padding-right: $spacing-base;
  margin: 0 auto 100px;
  display: flex;
  margin-top: -75px;
  padding-bottom: 75px;
  align-items: flex-end;
  flex-wrap: wrap;

  @include respond-below(sm) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    bottom: 0;
    margin-top: 0;
    width: 100%;
    z-index: 1;
    margin-bottom: 0;
    padding-bottom: 25px;
    height: 70%;
  }

  &__left {
    display: flex;
    padding-top: 15px;
    justify-content: center;
    width: 100%;
    margin-bottom: -32px;
    z-index: 1;

    @include respond-below(sm) {
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    &-img {
      max-width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 1px solid $color-white;
      margin-bottom: 10px;
      background-color: $color-white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      @include respond-above(sm) {
        max-width: 150px;
        height: 150px;
        margin-right: 0px;
      }

      img {
        max-width: 100%;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__right {

    margin-right: auto;

  }

  &__info {
    align-self: flex-end;
    color: $color-body;

    @include respond-below(sm) {
      align-self: center;
      text-align: center;
      color: $color-white;
      margin-bottom: $spacing-base;
    }

    &-title {
      font-size: $font-size-h4;
    }
  }
}

.banner-cover-profile__info-title,
.banner-cover-profile__info-category-tags,
.banner-cover-profile__info-subs-count {
  margin-bottom: 0;
}

.card-detail-page {
  text-align: center;

  &.w-polls {
    .card-detail-left {
      text-align: left;

      h3 {
        font-size: $font-size-h4-mob;
        line-height: 26px;
      }

      p {
        line-height: 24px;
      }
    }

    .container-small,
    .container-medium {
      padding-left: 0;
      padding-right: 0;
      max-width: 100%;
    }
  }
}

.card-detail-tab {
  margin-bottom: 30px;
  display: inline-block;

  ul.nav-pills {
    justify-content: center;

    li {
      margin-right: 40px;

      @include respond-above(md) {}

      @include respond-above(md) {
        margin-right: 88px;
      }
    }

    .nav-link {
      margin-bottom: 10px;
      font-size: $font-size-body;
      font-weight: $font-weight-light;

      @include respond-above(md) {
        font-size: $font-size-h3 - .25rem;
      }

      @include respond-above(md) {
        font-size: $font-size-h3;
      }

      &:after {
        top: 8px;
        height: 15px;
        left: calc(100% + 20px);
        background-color: $color-form-border;

        @include respond-above(sm) {
          top: 5px;
          height: 15px;
        }

        @include respond-above(md) {
          left: calc(100% + 45px);
          top: 10px;
          height: 17px;
        }
      }
    }
  }

  &__content {
    margin-bottom: 50px;
    padding-bottom: 30px;

    .main-video {
      border: 1px solid $color-form-border;

      iframe {
        padding: 4px;
      }
    }
  }

  &-title {
    text-transform: uppercase;
    margin-bottom: $spacing-double
  }
}

.post-tab__content-left {
  @include respond-below(sm) {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.post-tab__content-right {
  order: -1;

  @include respond-above(sm) {
    order: 1;
  }
}

.package {
  display: flex;
  flex-direction: column;
  flex: 0 0 33%;
  border: 1px solid $color-body;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  height: auto;

  @include respond-above(sm) {
    height: 100%;
  }

  &-header {
    padding-bottom: 20px;
  }

  &-sec {
    display: flex;
    flex-wrap: wrap;
  }

  &-info {
    display: flex;
    flex-direction: column;
    font-size: $font-size-sml;
    align-items: center;

    strong {
      font-size: $font-size-body;
      margin-top: 30px;
      margin-bottom: 40px;
    }

    .package-price {
      padding-bottom: 0px;
      font-weight: $font-weight-bold;
      font-size: $font-size-h3 - .25rem;
      padding-top: $spacing-base;

      @include respond-above(sm) {
        font-size: $font-size-h3;
      }
    }

    .package-term {
      padding-bottom: $spacing-double;
    }

    .btn {
      margin-bottom: 5px;
      font-size: $font-size-h4 - .225rem;
      font-weight: $font-weight-bold;
      // min-height: 45px;
      width: 75%;

      @include respond-above(sm) {
        // font-size: $font-size-h4;
        // min-height: 52px;
      }
    }

    .package-link {
      font-size: $font-size-body - .125rem;
      text-decoration: none;

      @include respond-above(sm) {
        font-size: $font-size-body;
      }
    }
  }

  &-desc {
    text-align: left;
    font-size: $font-size-sml;

    ol {
      padding-left: 17px;
    }

    p {
      margin-bottom: 5px;
    }
  }
}

.package-header strong {
  position: relative;
  transform: translate3d(0, 0, 35px) perspective(100px);
  margin-bottom: 40px;
  margin-top: 30px;
}

.package-header {
  strong {

    &:before,
    &:after {
      position: absolute;
      content: '';
      z-index: -1;
      background: linear-gradient(217deg, rgba($color-primary, .4), rgba($color-primary, .7));
      width: 85px;
      height: 85px;
      display: block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0 6px 20px 0 rgba($color-primary, .3);
      animation: border-transform 5s linear infinite;
      padding: 10px;
    }

    &:after {
      background: linear-gradient(217deg, rgba($color-primary, .7), rgba($color-primary, .4));
      animation: border-transform 5s linear infinite;
      animation-direction: alternate-reverse
    }
  }
}

@keyframes border-transform {

  0%,
  100% {
    border-radius: 39% 59% 52% 49% / 42% 42% 57% 52%;
  }

  25% {
    border-radius: 40% 100% 68% 71% / 40% 86% 60% 100%;
  }

  50% {
    border-radius: 50% 55% 42% 49% / 42% 42% 60% 63%;
  }

  75% {
    border-radius: 50% 72% 100% 24% / 39% 42% 79% 87%;
  }

}

// @keyframes border-transform-reverse {
//   0%,100% {
//     border-radius: 71% 60% 72% 60% / 76% 60% 69% 60%;
//   }

//   25% {
//     border-radius: 55% 80% 72% 65% / 76% 60% 69% 60%;
//   }

//   50% {
//     border-radius: 49% 74% 69% 50% / 73% 71% 50% 50%;
//   }



// }

.post-card {
  display: flex;
  flex-direction: column;
  border: 1px solid $color-body;
  margin-bottom: $spacing-base + 5;
  border-radius: $border-radius-default;

  &__img {
    border-radius: $border-radius-default $border-radius-default 0 0;

    img,
    video {
      border-radius: $border-radius-default $border-radius-default 0 0;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 15px;
    text-align: left;

  }

  &-club_name {
    font-size: $font-size-xsml;
  }

  &__postedInfo {
    text-align: right;
  }

  &__title {
    font-size: $font-size-h3;
    font-weight: $font-weight-semibold;

    a {
      color: $color-body;
      text-decoration: none;

      &:hover {
        color: $color-primary;
      }
    }
  }

  &__desc {
    font-size: $font-size-sml;
  }

  &__footer {
    display: flex;
    list-style: none;
    padding-left: $spacing-base;
    padding-right: $spacing-base;

    &-left {
      display: flex;
      list-style: none;
      margin-bottom: $spacing-base;
    }

    &-right {
      margin-left: auto;
    }

    ul {
      list-style: none;
      padding-left: 0;
      margin-bottom: $spacing-base;
    }

    li {
      font-size: $font-size-sml;

      i {
        margin-right: 5px;
      }

      padding-right: $spacing-base;
    }
  }
}

// .card-detail-tab.tab-fixed {
//   position: sticky;
//   top: 0;
//   left: 0;
//   right: 0;
//   background: #fff;
//   padding: 10px;
//   border-bottom: 1px solid #ccc;
//   z-index: 10;
// }

.package-col.expanded ol li {
  display: block;
}

.package-col ol li {
  display: none;
}

.package-col ol li:first-of-type,
.package-col ol li:nth-of-type(2),
.package-col ol li:nth-of-type(3) {
  display: block;
}

.checkout-card {
  margin: inherit;
  padding: 0 0 15px 10px;

  @include respond-below(sm) {
    padding-left: 0;
    width: 100%;
    max-width: 100%;
  }

  .checkout-count {
    .row {
      @include respond-below(sm) {
        width: 100%;
      }
    }
  }

  .checkout-desc {
    @include respond-below(sm) {
      padding: 20px 30px;
    }

  }
}

.livestream-detail-mob {
  background-color: $color-white;
  border-radius: $border-radius-default $border-radius-default 0 0;
  padding: 30px 15px 15px;
  width: 100%;
  // align-items: flex-start;
  box-shadow: 0px -6px 5px rgba(0, 0, 0, 0.08);

  // position: fixed;
  // bottom: 0;
  &-content {
    flex: 0 0 75%;
    padding-right: 10px;

    @include respond-below(sm) {
      flex: 0 0 70%;
    }

    &-title,
    h3,
    strong {
      margin-bottom: 0;
      font-weight: normal;
    }

    &-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 5px;

    }

    h3 {
      font-size: 18px;

    }

    strong {
      padding-top: 15px;
      display: block;
    }
  }

  &-schedule {
    font-size: 16px;
    background-color: $color-body;
    padding: 10px;
    border-radius: 10px;
    color: $color-white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    margin-left: auto;
  }

  &-desc {
    background-color: #f2f2f2;
    color: $color-body;
    padding: 20px 15px;

    h3 {
      font-size: 20px;
      margin-bottom: 0px;
    }

    p {
      padding-bottom: 15px;
      border-bottom: 1px solid rgba($color-body-light, 0.1);
      font-size: 14px;
    }

    >strong {
      font-size: 14px;
    }
  }
}

.join-btn-mar-rt {

  @include respond-below(md) {
    margin-right: 0;
  }

  @include respond-below(sm) {
    margin-right: 32px;
  }
}