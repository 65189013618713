.spinner-container {
  position: fixed;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  width: 100%;

  .spinner {
    position: absolute;
    z-index: 99999;
    text-align: center;
    left: 50%;
    pointer-events: none;
    cursor: default;
    transform: translate(-50%, -50%);
    top: 50%;
  }

  .spinner-border {
    border-color: $color-white;
    border-right-color: transparent;
  }
}