.livestream-detail-more {
  background-color: #1a1a1a;
  padding: 50px 0;

  h3,
  a {
    color: #fff;
  }

  h3 {
    padding-bottom: 15px;

    @include respond-below(sm) {
      font-size: 20px;
    }
  }
}

.attendees-list {
  &.modal-dialog {
    @include respond-below(md) {
      max-width: 90%;
    }
  }
}

.code-country {
  .MuiFormControl-root {
    input {
      width: 60px;
    }
    @include respond-below(sm) {
      width: 100%;
    }
  }
}

.bi-x-lg:hover{
  color: #000;
    text-decoration: none;
    opacity: 0.75;
    background-color: #e8eaed;
    border-radius: 50%;
    transition: background-color 0.3s;
}
.bi-x-lg{
  font-size: 20px;
  padding: 0.2rem 0.6rem;
}