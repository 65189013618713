.banner-cover {
  transition: height .35s ease;
  height: 350px;
  @include respond-above(md) {
    height: 500px;
  }
   > img {
    height: 100%;
    object-fit: cover;
    max-width: 100%;
    width: 100%;
  }
  &.livestream {
    position: relative;
    .banner-cover-profile {
      
      padding: 15px 0;
      &__left-img {
        max-width: 70px;
        height: 70px;
        width: 70px;
      }
      &__info {
        align-self: center;color: $color-white;
      }
      &__right {
        width: 40%;
        padding: $spacing-base + 10;
        background-color: rgba($color-black, .8);
        color: $color-white;
        height: 100%;
        overflow:hidden;
        overflow-y: auto;
        span {
          padding-bottom: 10px;
          display: block;
        }
        p {
          padding-bottom: $spacing-base;
          word-break: break-word;
        }
      }
    }
   
  }
}