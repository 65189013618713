.featured-club-new {
  &.club-list {
    position: relative;
    z-index: 1;
    li {
      &:first-of-type,
      &:nth-of-type(5),
      &:nth-of-type(7) {
        margin-top: 0;
      }
    }
  }
}