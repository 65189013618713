.todayText {
  &::after {
    content: "Today";
  }
}

.table-condensed {
  caption-side: bottom;
  border-collapse: collapse;
  color: $color-body;
  font-size: $font-size-sml;
}

.table-condensed> :not(caption)>*>* {
  padding: .5rem .5rem;
  background-color: $color-white;
  border-bottom: 1px solid $color-body;
  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border-color: $color-body !important;
    border-style: solid;
    border-width: 0;
  }
}

.bootstrap-datetimepicker-widget table td {
  &.today,
  &.active {
    position: relative;
    color: #fff;
    z-index: 1;
    &::before {
      content: '';
      display: inline-block;
      border: 0 0 7px 7px solid transparent;
      border-bottom-color: $color-primary;
      border-top-color: rgba(0, 0, 0, 0.2);
      position: absolute;
      bottom: 0;
      right: 0;
      background: $color-primary;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }
  &.active {
    &::before {
      background: $color-secondary;
    }
  }
}

.input-height {
  min-height: 35px;
}

.timepicker-picker {
  table {
    td {
      height: 20px;
      line-height: 20px;
      .btn {
        span {
          height: 20px;
        }
      }
      span {
        height: 36px;
        line-height: 1;
      }
    }
  }
  .timepicker-hour,
  .timepicker-minute {
    line-height: 1;
    height: 36px;
    border-top: 1px solid #eee;
    border-radius: 0;
  }
  a[data-action] {
    padding: 10px 0;
    border: none;
  }
}