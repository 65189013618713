.site-footer {
  padding: 60px 0px 50px;
  color: $color-offwhite;
  bottom: 0;
  left: 0;
  font-size: $font-size-sml;
  width: 100%;
  background-color: #242424;
  // background-image: url('../../assets/img/footer-bg.png');
  background-position: left bottom;
  background-size: 100% auto;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  @include respond-above(sm) {
    margin-top: 100px;
    background-size: cover;
    z-index: 0;
  }
  &:after {
    content: "";
    position: absolute;
    right: 10px;
    top: 10px;
    background-image: url('../../assets/img/footer-logo.svg');
    background-position: right top;
    background-repeat: no-repeat;
    background-size: 70% auto;
    width: 367px;
    height: 367px;
    z-index: -1;
    @include respond-above(md) {
      top: -100px;
      background-size: 100% auto;
    }
  }
  @include respond-above(sm) {
    // position: sticky;
  }
  .footer-sec {
    font-size: 14px;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 20px 30px;
    display: flex;
    flex-flow: row wrap;
    @include respond-above(sm) {
      margin: 0 50px;
      padding: 0 20px 50px;
    }
    @include respond-above(md) {
      margin: 0 100px;
    }
    @include respond-above(lg) {
      margin: 0 auto;
    }
    .footer-info {
      margin-right: 30px;
      @include respond-below(sm) {
        margin-right: 0;
        margin-bottom: 30px;
      }
      @include respond-above(sm) {
        margin-right: 50px;
      }
      @include respond-above(lg) {
        margin-right: 100px;
      }
      &-title {
        margin-bottom: $spacing-base - 3px;
        font-size: $font-size-sml;
      }
      &:first-of-type {
        flex: 0 0 100%;
        @include respond-above(sm) {
          flex: 0 0 275px;
        }
      }
      .demo {
        margin-top: 20px;
        display: block;
        text-decoration: none;
      }
      &:last-of-type {
        margin-right: 0;
      }
      li {
        list-style: none;
        padding-left: 0;
        display: flex;
        flex-wrap: wrap;
      }
      &:nth-of-type(2) {
        margin-right: 30px;
        @include respond-below(sm) {
          flex: 0 0 50%;
        }
        @include respond-above(sm) {
          margin-right: 50px;
        }
        @include respond-above(lg) {
          margin-right: 130px;
        }
        .footer-links {
          a {
            // &:last-of-type {
            //   margin-top: 5px;
            //   border-top: 1px solid $color-body;
            //   padding-top: 10px;
            //   font-weight: $font-weight-semibold;
            //   font-size: $font-size-sml;
            // }
          }
        }
      }
    }
    .footer-info-loc {
      list-style: none;
      padding-left: 0;
      &-title {
        flex: 0 0 48px;
        margin-right: 10px;
        font-size: $font-size-h4;
        text-transform: uppercase;
        font-weight: $font-weight-semibold;
        line-height: 1.2;
        border-right: 1px solid $color-gray-dk;
        color: $color-white;
        padding-right: 15px;
      }
      li {
        &:nth-of-type(2) {
          .footer-info-loc-title {
            font-size: 21px;
          }
        }
        &:last-of-type {
          .footer-info-loc-title {
            font-size: 19px;
          }
          address {
            padding-bottom: 0;
          }
        }
      }
    }
    .social-icon {
      display: flex;
      a {
        color: $color-white;
        margin-right: 10px;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid $color-white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: $font-size-xsml;
        &:hover {
          background-color: $color-body-light;
          border-color: $color-body-light;
        }
      }
    }
    .footer-links {
      a {
        font-size: $font-size-xsml;
        margin-bottom: 7px;
        transition: letter-spacing .25s ease;
        &:hover {
          color: rgba($color-white, .7);
        }
      }
    }
    .footer-info-address {
      font-size: $font-size-xsml;
      flex: 999999;
      margin-bottom: 0px;
      padding-bottom: 10px;
      @include respond-below(sm) {
        br {
          display: none;
        }
      }
    }
    .footer-links {
      display: flex;
      flex-direction: column;
      a {
        color: $color-offwhite;
        text-decoration: none;
      }
    }
  }
}




// .site-footer {
//   flex-shrink: 1;
// }
// footer {
//   padding: 30px 0px 30px;
//   background: #1d1d1d;
//   color: #fff;
//   font-size: 14px;
//   bottom: 0;
//   left: 0;
//   width: 100%;
//   @media only screen and (min-width: 768px) {
//     position: sticky;
//   }
// }
// #footer {
//   .footer-top {
//     background: #1d1d1d;
//     border-bottom: 1px solid #2c2c2c;
//     padding: 60px 0 30px 0;
//     h4 {
//       font-size: 16px;
//       font-weight: 600;
//       color: #fff;
//       position: relative;
//       text-transform: uppercase;
//     }
//     .footer-info p {
//       font-size: 14px;
//       line-height: 20px;
//       margin-bottom: 0;
//       color: #a2a1a1;
//     }
//     .footer-newsletter form {
//       margin-top: 30px;
//       background: #fff;
//       padding: 6px 10px;
//       position: relative;
//       border-radius: 4;
//       input {
//         &[type="email"] {
//           border: 0;
//           padding: 4px;
//           width: calc(100% - 110px);
//         }
//         &[type="submit"] {
//           position: absolute;
//           top: 0;
//           right: 0;
//           bottom: 0;
//           border: 0;
//           background: none;
//           font-size: 14px;
//           padding: 0 20px;
//           background: #2c2c2c;
//           color: #fff;
//           transition: 0.3s;
//           border-radius: 4;
//         }
//       }
//     }
//     .social-links a {
//       font-size: 18px;
//       display: inline-block;
//       background: #33332f;
//       color: #fff;
//       line-height: 1;
//       padding: 8px 0;
//       margin-right: 4px;
//       text-align: center;
//       width: 36px;
//       height: 36px;
//       transition: 0.3s;
//       margin-top: 30px;
//       &:hover {
//         text-decoration: none;
//         color: #fff;
//       }
//     }
//   }
//   .footer-address {
//     background: #1d1d1d;
//     border-bottom: 1px solid #2c2c2c;
//     padding: 30px 0 30px 0;
//   }
// }
// .footer-address-hd {
//   font-size: 16px;
//   font-weight: 600;
//   color: #fff;
//   text-transform: uppercase;
//   padding-bottom: 12px;
// }
// .footer-add {
//   h3 {
//     font-size: 16px;
//     color: #fff;
//     margin-bottom: 5px;
//   }
//   p {
//     margin-bottom: 0;
//     color: #a2a1a1;
//     font-size: 14px;
//     margin-bottom: 8px;
//   }
//   a {
//     color: #a2a1a1;
//     text-decoration: none;
//     &:hover {
//       text-decoration: none;
//       color: #525456;
//     }
//   }
// }
// .footer-bottom {
//   padding-top: 10px;
//   font-size: 13px;
// }