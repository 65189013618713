.heading-sec {
  display: flex;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #cbcbcb;

  @include respond-below(sm) {
    flex-direction: column;
    padding-bottom: 20px;
  }

  h3 {
    align-self: center;
    margin-bottom: 0;
    @include respond-below(sm) {
      padding-bottom: 10px;
    }
  }

  &-actions {
    display: flex;
    margin-left: auto;

    @include respond-below(sm) {

      margin-left: auto;
      margin-right: auto;
    }

    .btn {
      margin-left: 20px;
    }
  }

}