/* Profile Before Login */
.banner-cover.category-detail-page.profile {
  padding-bottom: 0;
  margin-bottom: 90px;
}

.banner-cover.banner-cover.category-detail-page.profile .banner-cover-profile {
  margin-top: -170px;
}

.profile .banner-cover-profile__left {
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 259px;
  border-radius: 32px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff;
}

.profile .banner-cover-profile__info {
  text-align: center;
  align-self: center;
}

.profile .banner-cover-profile__info-username,
.profile .banner-cover-profile__info-user-title {
  font-size: 16px;
}

.profile .banner-cover-profile__info-username {
  margin-bottom: 0;
}

.profile .banner-cover-profile__right {
  margin-left: 30px;
  margin-bottom: -33px;
}

.tab-content-sec {
  padding-top: 30px;
  padding-bottom: 100px;
  &.more-livestream-sec {
    @include respond-below(sm) {
      padding-bottom: 30px;
    }
  }
}

.tab-rounded {
  border: 1px solid #eee;
  border-radius: 50rem;
}

.tab-rounded li button {
  padding: 0.5rem 3rem;
  border-radius: 50rem;
  color: $color-body-light;
  background-color: transparent;
  border: none;

  @include respond-below(sm) {
    padding: 0.5rem 2rem;
  }
}

.tab-rounded li button.active {
  background-color: $color-body;
  color: #fff;
}

#pills-nft.nft-listing {
  min-height: inherit;
  margin-bottom: 0;
}

#pills-nft.nft-listing .nft-list .featured-card {
  flex: 0 0 calc(25% - 10px);
}

@include respond-below(sm) {
  .tab-content-sec .container {
    max-width: 100%;
  }

  .profile .banner-cover-profile__info-username,
  .profile .banner-cover-profile__info-user-title {
    color: #000;
  }

  .banner-cover.banner-cover.category-detail-page.profile .banner-cover-profile {
    flex-flow: column nowrap;
    padding-bottom: 30px;
    height: auto;
  }

  .profile .banner-cover-profile__right {
    margin-left: auto;
    margin-bottom: 0;
  }

  .profile .banner-cover-profile__left {
    margin-bottom: 30px;
  }

  .tab-rounded {
    background-color: #fff;
  }

  .banner-cover.category-detail-page.profile {
    margin-bottom: 0;
  }

}