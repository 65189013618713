.card-nft {
  border-radius: 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  box-shadow: 0 2px 8px 2px rgba(186, 186, 186, 0.21);
  transition: all 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:hover {
    box-shadow: 0 0 14px 8px rgba(150, 150, 150, 0.21)
  }

  // @include respond-below(sm) {
  //   padding-left: 20px;
  //   padding-right: 20px;
  // }

  &.square {
    padding: 55% 40px 20px;
  }

  &-img {
    display: block;
    margin-bottom: 10px;
    img {
      width: 100%;
      display: block;
      object-fit: cover;
    }

    .ratio {
      display: block;
    }
  }

  &-content {
    text-align: left;
    padding: 10px 15px 15px;
    color: $color-body-light;

  }

  &-creator-info {
    .card-nft-img {

      img {
        border-radius: 0 0 8px 8px;
      }

    }

    .card-nft-content {
      padding: 20px 10px;
      text-align: center;
      .card-nft {
        &-title {
          margin-bottom: 10px;
          display: block;
        }

      }
    }
  }

  &-title {
    color: $color-body;
    text-decoration: none;
    font-weight: 600;
    font-size: $font-size-body + 0.125;

    &:hover {
      color: $color-body-light;
    }
  }

  &-creator {
    display: block;
    padding-bottom: 15px;
  }

  .btn {
    position: relative;
    z-index: 1;
    display: inline-flex;
    transform: translateY(80px);
    transition: all 0.35s ease 0s;
  }

  &:hover {
    .card-livestream-content {
      transform: translateY(0);
      transition: all 0.35s ease 0s;
    }

    .btn {
      transform: translateY(0);
      transition: all 0.35s ease 0s;
    }
  }
  &-available-count {
    position: absolute;
    right: 15px;
    top: 10px;
    color: #ffffff;
    z-index: 1;
    background: rgba(0, 0, 0, 0.3);
    padding: 4px 12px;
    border-radius: 30px;
    font-size: 14px;
  }
}