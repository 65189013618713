.my-nft {
  .card-nft-info {
    text-align: left;
    padding: 10px 15px 20px;

    &__handle {
      font-size: 14px;
      color: #818181;
    }
    .btn {
      transform: translateY(0);
      width: 85%;
      margin: 30px auto 0;
      display: flex;
    }
  }

  .card-nft-img {
    margin-bottom: 0;
  }

  .nft-price-info {
    padding: 0;
    padding-top: 20px;
  }
}