.create-livestream-sec {
  height: 100vh;
  display: flex;
  margin-top: 100px;

  >*>* {
    height: 100vh;
  }

  .step-sec-title {
    font-size: 2rem;
    margin-bottom: 50px;
    display: flex;
    align-items: center;

    i {
      width: 29px;
      height: 29px;
      border-radius: 50%;
      border: 1px solid #DCDCDC;
      background-color: #FFFFFF;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      font-size: 24px;
    }
  }


  .step-sec ul {
    display: flex;
  }

  .step-sec ul li {
    display: flex;
    flex-direction: column;
    flex: 1;

    &.active {
      .step-no {
        box-shadow: 0 2px 7px 2px rgba(0, 0, 0, 0.08);
        border-color: transparent;

      }

      .step-number {
        color: #42CB86;
      }

      .step-desc {
        font-weight: $font-weight-semibold;
        color: $color-black
      }
    }
  }

  .step-no {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #DCDCDC;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .step-no,
  .step-number,
  .step-desc {
    color: #bbb;
  }

  .step-number {
    font-size: 12px;
  }

  .step-desc {
    font-size: 14px;
  }
}

.create-livestream-step input,
.create-livestream-step label {
  font-family: $font-family-body;
  letter-spacing: normal;
}

.create-livestream-right {
  position: relative;

  .create-livestream-preview-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

  }

  .checkout-btn-sec {
    .btn {
      min-width: 164px;
      padding-left: 16px;
      padding-right: 16px;
      font-size: 13px;
      &:hover, &:focus {
        background-color: #fff;
        cursor: default;
        color: $color-body;
      }
    }
    span {
      cursor: default;
      &:hover{
        cursor: default;
      }
    }
  }
}

.create-nft-sec {
  border: 1px solid rgba(0, 0, 0, 0.23);
  position: relative;
  border-radius: 4px;
  padding: 18px 0px 18px 0px;
  margin-bottom: 20px;
  margin-top: 20px;

  .nft-title {
    position: absolute;
    top: -7px;
    font-size: 14px;
    color: #5c5c5c;
    background: #fff;
    display: inline-block;
    width: auto;
    left: 5px;
  }

  .nft-sec-chk {
    padding: 0;
    vertical-align: middle;
    margin-right: 0;
    padding-left: 4px;
  }

  .ticket-price{
    display: flex;
    flex-direction: column;
  }
}

.nft-helper-text {
  margin-bottom: -8px;
}

.rerun {
  margin-top: 15px;
}

.rerun-enable{
  padding: 0 0;
}

.rerun-text{
  font-size: 0.8rem;
  color: grey;
  font-family: "Open Sans", sans-serif;
  letter-spacing: normal;
}
.rerun-price-info{
  color: rgb(43, 43, 43);
  border: 1px solid black;
  border-radius: 50%;
  margin-left: 5px;


  &::before{
    font-size: 18px;
  }
}