.sidebar .sidebar-toggle {
    cursor: pointer;
}

.main-content-sec.static-page .main-content {
    margin-left: 0;
    position: relative;
    background-color: #fff;
    z-index: 1;
    width: 100%;
}

#create-offcanvas-mob-menu {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

#create-offcanvas-mob-menu .offcanvas-header,
#create-offcanvas-mob-menu .offcanvas-body {
    padding: 0;
}

#create-offcanvas-mob-menu .offcanvas-header {
    padding: 10px 15px;
    border-bottom: 1px solid #c8c8c8;
}

#create-offcanvas-mob-menu .offcanvas-title {
    font-size: 14px;
    margin-bottom: 0;
    color: #42CB86;
    font-weight: normal;
}

#create-offcanvas-mob-menu .offcanvas-title i {
    font-size: 24px;
    vertical-align: middle;
}

#create-offcanvas-mob-menu.offcanvas-bottom {
    height: 24vh;
    bottom: 57px;
}

#create-offcanvas-mob-menu .offcanvas-header .btn-close {
    font-size: 12px;
}

.create-mob-menu ul {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.create-mob-menu li {
    flex: 0 0 50%;
    font-size: 14px;
    text-decoration: none;
    margin: 10px 0 0;
    padding-bottom: 5px;
}

.create-mob-menu li a {
    text-decoration: none;
    padding: 5px;
    border-right: 1px solid #c8c8c8;
    display: block;
}

.create-mob-menu ul li:nth-child(-n + 3) {
    border-bottom: 1px solid #c8c8c8;
    flex: 0 0 33.33%;
}

.create-mob-menu ul li:nth-child(3) a,
.create-mob-menu ul li:nth-child(5) a {
    border-right: none;
}

.create-mob-menu a h3 {
    font-size: 16px;
    color: #2a2a2a;
    text-decoration: none;
}

.fixed-bottom-menu .main-menu a i {
    line-height: 1;
}

#mobileMenu .offcanvas-header {
    border-bottom: 1px solid #eee;
    padding-top: 16px;
    padding-bottom: 16px;
}

#mobileMenu .offcanvas-title {
    margin-bottom: 0;
}

#mobileMenu .offcanvas-title .navbar-brand img {
    height: 40px;
}

/* Sign up modal */
.sign-up-modal {
    max-width: 410px;
}

.sign-up-modal.modal-lg {
    max-width: 870px;
}

.sign-up-modal .btn-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    z-index: 9;
    font-size: 14px;
    padding: 0.7rem 0.7rem;
}
.sign-up-modal .btn-close:hover{
    color: #000;
    text-decoration: none;
    opacity: .75;
    background-color: #e8eaed;
    border-radius: 50%;
    transition: background-color .3s;

}
.sign-up-modal .modal-header,
.sign-up-modal .modal-footer {
    border: none;
    padding: 0;
}

.sign-up-modal .modal-footer {
    padding: 15px;
    position: absolute;
    bottom: 0;
    right: 0;
}

.sign-up-modal .modal-footer>* {
    margin: 0;
}

.sign-up-modal .modal-body {
    padding: 0;
}

.auth-container {
    display: flex;
    padding: 0 20px;
    position: relative;
    z-index: 1;
}

/* .auth-container:before {
    background-image: url(../img/signupgraphic.png);
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    background-position: -90px 50px;
    width: 420px;
    background-repeat: no-repeat;
    height: 296px;
    z-index: -1;
    background-size: 100%;
    opacity: .7;
} */

.loginPage-background{
    background-image: url(../img/login-page-bckground.jpg);
     background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: -100px;
  
}





.login-page {
    width: 412px;
    /* margin: 0 auto; */
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1));
    -webkit-backdrop-filter: blur(20px);
    backdrop-filter: blur(20px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgb(0 0 0 / 37%);
    border-radius: 12px;
    /* margin-top: 85px; */
    margin-left: 250px;
    color: white;
   

}
.clr-red.mobile-error {
    position: absolute;
    top: -18px;
    font-size: 18px;
    /* margin-bottom: 28px; */
    background:none !important;
    
    /* color:white */
}
.clr-black.mobile-error {
    position: absolute;
    top: -18px;
    font-size: 18px;
    /* margin-bottom: 28px; */
    background: none !important;
    color:white !important;
}
.clr-black.email-label{
    font-size: 1rem;
    padding-top: 1.75rem !important;
}
.clr-red.email-label{
    font-size: 1rem;
    padding-top: 1.75rem !important;
   
}
.clr-red.email-label:focus{
    top: -50px !important;
}
.form-control:focus ~ .email-label{
    transform: scale(0.85) translateY(-2.2rem) translateX(0.65rem)!important;
}

.loginPage-background .bi-arrow-left-circle:hover{
    color: black !important;
}
@media only screen and (max-width:932px){
    .login-page{
        margin: 0 auto;
    }
}
@media only screen and (max-width:464px){
    .login-page{
        margin: 0px 10px;
    }
}
.auth-container .auth-sec {
    flex: 1;
    padding: 1.5rem;
}

.auth-sec .form-floating .form-control {
    height: calc(2.75rem + 4px);
}

.auth-sec .form-floating>label {
    padding-top: .75rem;
}

.auth-info {
    padding-bottom: 20px;
}

.auth-info a:hover {
    text-decoration: none !important;
}

.auth-sec.auth-left-sec {
    align-self: center;
    text-align: center;
    padding: 0;
    padding-right: 20px;
}

.auth-sec.auth-left-sec img {
    margin: 0 auto;
}

.auth-sec.auth-left-sec img {
    max-width: 100%;
    padding: 10px;
}

.sign-up-social {
    margin-bottom: 20px;
}

.sign-up-social strong {
    padding-right: 10px;
    margin-bottom: 10px;
    display: block;
}

.social-btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.social-btn .btn+span {
    display: block;
    width: 100%;
}

.social-btn .btn {
    box-shadow: none !important;
    border-radius: 2px;
    color: #2b2b2b;
    border-radius: 20px !important;
    border: 1px solid #000;
    padding-left: 0;
    display: flex !important;
    background-color: #fff !important;
    width: 100%;
    border-color: #000 !important;
    margin-bottom: 10px;
    padding-right: 0;
    font-family: "Open Sans" !important;
    font-size: 16px !important;
    height: 42px;
}


/* .social-btn .btn,
.social-btn .btn span {
    font-weight: 600 !important;

} */

.social-btn .btn:hover {
    background-color: #f3f3f3 !important;
}


.social-btn .btn:focus {
    background-color: #2b2b2b !important;
    color: #fff !important;
}

.social-btn .btn div {
    padding: 0 !important;
    border-radius: 0;
    margin-left: auto;
    display: flex;
}

.social-btn .btn div:active {
    background-color: transparent !important;
    fill: none;
}

.social-btn .btn span {
    margin-right: auto;
    padding: 0 !important;
}

.social-btn .btn i {
    margin-right: 10px;
    border-radius: 2px;
    font-size: 19px;

}

.social-btn .btn i.fa-facebook {
    color: #4267B2;
}

.sign-up-social .btn-black {
    background-color: #2b2b2b;
    color: rgb(0, 0, 0) ;

}
.verify {
    background-color: white !important;
    color: #706d6d !important;
}

.sign-up-social .btn-black:hover {
    background-color: #eeeeee !important;
    color: black !important;
}


/* .social-btn .btn:hover:last-of-type {
    background-color: #4267B2;
    color: #fff;
} */
.sign-up-social .appearance {
    background-color: #fff !important;
    color: #2b2b2b !important;
}


.sign-up-social .login-other {
    display: block;
    border-bottom: 1px solid #cacaca;
    width: 100%;
    margin-bottom: 30px;
}

.sign-up-social .login-other span {
    display: block;
    margin: 0px auto -30px;
    width: 50px;
    height: 50px;
    background-color: #fff;
    line-height: 40px;
    border-radius: 50%;
}

.sign-up-social strong {
    padding-right: 10px;
    margin-bottom: 10px;
}

.auth-sec-already-member {
    font-size: 14px;
    text-align: center;
    margin: 0 auto;
    width: 100%;
    display: inline-block;
    margin-top: 30px;
}

.auth-sec-already-member a {
    cursor: pointer;
    color: #fff !important;
    font-weight: bold;
    text-decoration: underline !important;
    font-size: 18px;
}

.auth-sec-already-member a:hover {
    text-decoration: none !important;
}

.auth-sec.auth-right-sec .btn-primary:hover {
    background-color: #fff;
}

/* Login Modal */

.sign-up-modal.login-modal .auth-left-sec {
    max-width: 60%;
    margin: 0 auto 30px;
}

.login-modal .auth-sec.auth-right-sec {
    padding-bottom: 30px;
}

.clr-red {
    color: #ea4335 !important;
}

.form-control.error {
    border-color: #ea4335;
}

.form-floating .form-control.error:focus {
    border-color: #ea4335;
}

.text-post {
    height: 150px;
    overflow-y: auto;
}

.pointer {
    cursor: pointer;
}
.form-control.mobileOTP{
    background: black;

    color: white !important;
    border: 1px solid #c8c8c8 !important;

}

.loginPage-background input:-webkit-autofill{
    -webkit-text-fill-color: white !important;
    -webkit-box-shadow: 0 0 0 30px black inset !important;

}
.loginPage-background input:-webkit-autofill:focus{
    -webkit-text-fill-color: white !important;
    -webkit-box-shadow: 0 0 0 30px black inset !important;

}
.form-control.mobileOTP:focus{
    color: white;
    background: black;

}
@media only screen and (max-width:575px) {
    .auth-sec.auth-right-sec {
        flex: 0 0 100%;
    }

    .social-btn {
        flex-direction: column;
        align-items: stretch;
    }

    .social-btn .btn:first-of-type {
        margin-bottom: 10px;
    }
}

.uploaded-multiple-img-sec {
    margin-top: 20px;
    text-align: left;
}

.uploaded-multiple-img-sec strong {
    margin-bottom: 10px;
    display: block;
}

.post-card__img .slider .slider-item,
.post-card__img .slider .slider-item-img {
    padding: 0;
}

.post-card__img .slider .slider-item-img {
    border: none;
}

.post-card.fixed-height .post-card__img .slider-item {
    height: 180px;
}

.dl-item {
    cursor: pointer;
}

.post-card.fixed-height .post-card__img {
    height: 200px;
}

.post-card.fixed-height .post-card__img img {
    height: 100%;
}

.social_content_fix {
    display: flex;
    position: relative;
}

.social_content_fix .social_contact {
    position: absolute;
    width: 200px;
    right: 0px;
    border-left: none;
    padding-left: 0px;
    border-radius: 0px 10px 10px 0px;
}

.social_content_fix .social_contact_twitter {
    position: absolute;
    width: 258px;
    right: 0px;
    border-left: none;
    padding-left: 0px;
    border-radius: 0px 10px 10px 0px;
}

.slider-item-header,
.slider-item-footer-left a:hover {
    color: #42CB86;
}

.main-content-sec.static-page .main-content main.main-content-sec-static-page section.banner-cover.category-detail-page {
    margin-left: 100px !important;
}

.main-content-sec.static-page.active .main-content main.main-content-sec-static-page section.banner-cover.category-detail-page {
    margin-left: 0px !important;
}

.livestream footer.site-footer {
    position: relative;
}

.filter-sec .custom-select {
    width: 250px;
}

@media only screen and (max-width:991px) {
    .filter-sec .custom-select {
        align-self: flex-end;
        width: 200px;
    }

}

@media only screen and (max-width:767px) {

    .filter-sec .sort-sec .custom-select,
    .filter-sec .sort-sec .form-control {
        flex: 1;

    }

}

.sec-signup-user,
.sec-signup-creator,
.signup-top-sec.hidden {
    display: none;
}

.sec-signup-user.active,
.sec-signup-creator.active,
.signup-top-sec {
    display: block;
    width: 100%;
}

.back-arrow {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    font-size: 16px;
    color: #2b2b2b;
    width: 40px;
    height: 40px;
    opacity: 0.75;
    padding: 0.5rem 0.7rem;

}
.back-arrow:hover {
    color: #000;
    text-decoration: none;
    opacity: .75;
    background-color: #e8eaed;
    border-radius: 50%;
    transition: background-color .3s;

}

.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #2b2b2b !important;
    border-width: 1px !important;
}

.MuiInputLabel-root.Mui-focused {
    color: #2b2b2b !important;

}

.searchWrapper {
    padding: 5px 10px !important;
    min-height: 56px !important;
    display: flex;
    overflow: hidden;
    align-items: center;

}

@media only screen and (max-width:1440px) {
    .searchWrapper {
        min-height: 43px !important;
    }
}

.searchWrapper input {
    line-height: normal;
    margin-top: 0;
    padding: 0;
}

.searchWrapper .chip {
    margin-bottom: 0;
}

.input-w-eye {
    position: relative;
    display: flex;
    flex-direction: column;

}

.input-w-eye span {
    right: 10px;
    cursor: pointer;
    position: absolute;
    top: 10px;
}

.otp-field {
    position: relative;
    margin-top: 20px;
}

.otp-field #country_code,
.otp-field #creator_country_code {
    position: absolute;
    width: 60px;
    left: 1px;
    border: none;
    border-radius: 8px 0 0 8px;
    top: 1px;
    height: 46px;
    color: white;

    border: none !important;


}

.otp-field .btn {
    position: absolute;
    right: 7px;
    top: 7px;
    font-size: 12px !important;
    padding: 3px 10px;
    height: auto;
}

.otp-field .form-control {
    padding-right: 85px;
    padding-left: 60px;
}

@media only screen and (max-width:767px) {
    .signup-last-step .MuiFormControl-marginNormal {
        margin-top: 8px;

    }

    .signup-last-step .MuiOutlinedInput-input {
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 14px;
    }

    .signup-last-step .MuiInputLabel-formControl {
        font-size: 14px;
    }

}


.MuiCalendarPicker-root.css-169iwlq-MuiCalendarPicker-root {
    max-height: 300px;
    overflow: auto;
}

.input-w-inner-input {
    position: relative;
}

.input-w-inner-input .country-code {
    width: 30px;
    position: absolute;
    left: 20px;
    min-height: 52px;
}

.input-w-inner-input .country-code .MuiInput-underline {
    height: 57px;
}

.input-w-inner-input .country-code .MuiInput-underline:before {
    border-color: transparent;
}

.input-w-inner-input .phone-number input {
    padding-left: 41px;
}


.input-w-inner-input .phone-number label {
    padding-left: 25px;
}

.input-w-inner-input .phone-number label {
    padding-left: 25px;
}

.input-w-inner-input .phone-number label.Mui-focused,
.input-w-inner-input .phone-number label.MuiFormLabel-filled {
    padding-left: 0;
}

.chat {
    overflow-y: auto;
    height: calc(100% - 123px);
}

.resend-otp-link {
    background-color: transparent;
    border: none;
    align-self: flex-end;
    margin-top: -1rem;
    color: #fff;
}

/* .otp-input {
    width: 16.6667%;
}

.otp-input input {
    width: 100%!important;
    border-radius: 8px;
    border: 1px solid #979797;
    height: 50px;
    margin-right: 5px;
    margin-left: 5px;
} */
.enterOTPrecdOuter {
    width: 100%;
    overflow: hidden;
}

.enterOTPrecdInner {
    left: 0;
    position: sticky;
}
.enterOTPrecdInner .form-control {
    border-radius: 0px;
}
.enterOTPrecd {
    padding-left: 15px;
    letter-spacing: 48px;
    border: 0;
    background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 57px 1px;
    /* background-repeat: repeat-x; */

    background-position-x: 35px;

    width: 130%;
    min-width: 130%;
    background-color: #2b2b2b;
    color: white;

    border-radius: 0 !important;


   
}

.enterOTPrecd:focus {
    color: white;
    background-color: #2b2b2b;

}
.url-social-links {
    width: 100%;
    text-align: left;
    list-style: none;
    padding-left: 0;
}

/* 
.url-social-links li {
    margin-bottom: 15px;
    border: 1px solid #000;
    padding:5px;
    border-radius: 4px;
    position: relative;
}
.url-social-links li i {
    margin-right: 10px;
    position: absolute;
    top: 5px;
    left: 5px;
}
.url-social-links li input {
    border: none;
    width: calc(100% - 0px);
    padding-left: 20px; 
}
.url-social-links li input:focus {
    border: none;
    outline: none;
} */

@media only screen and (max-width:575px) {
    .sign-up-modal {
        max-width: 340px;
        margin: 0 auto;
    }

    .enterOTPrecd {
        letter-spacing: 35px;
        /* background-size: 43px 1px; */
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(46px);
    -ms-transform: translateX(46px);
    transform: translateX(46px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.url-social-links {
    width: 100%;
    text-align: left;
    list-style: none;
    padding-left: 0;
}

.url-social-links li .btn {
    padding-left: 2rem;
    padding-right: 2rem;
}

/* 
.url-social-links li {
    margin-bottom: 15px;
    border: 1px solid #000;
    padding:5px;
    border-radius: 4px;
    position: relative;
}
.url-social-links li i {
    margin-right: 10px;
    position: absolute;
    top: 5px;
    left: 5px;
}
.url-social-links li input {
    border: none;
    width: calc(100% - 0px);
    padding-left: 20px; 
}
.url-social-links li input:focus {
    border: none;
    outline: none;
} */

@media only screen and (max-width:575px) {
    .sign-up-modal {
        max-width: 340px;
        margin: 0 auto;
    }

    .enterOTPrecd {
        letter-spacing: 35px;
        background-size: 43px 1px;
    }
}
@media only screen and (max-width:575px) {
    .login-page{
        max-width: 340px;
        margin: 0 auto;
    }
}
.content-info {
    font-size: 12px;
}

.content-info p {
    margin-bottom: 10px;
}

/* .content-info ol li > strong,
.content-info ul li > strong {
    display: block;
} */
.content-info ol li,
.content-info ul li,
.content-info ul.roman li {
    padding-bottom: 5px;
}


#aboutus {
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 120px 70px 70px;
    height: 100%;
    background-size: cover;
    background-position: right;
}

@media only screen and (max-width:767px) {
    #aboutus {
        padding: 20px 0px 70px;
    }  
}

#aboutus h1 {
    line-height: 56px;
    color: #fff;
}

#aboutus h1::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -30px;
    width: 100px;
    height: 1.2px;
    background-color: #fff;
}

#aboutus p {
    color: #fff;
    font-size: 18px;
    margin-bottom: 5px;
}

.page-aboutus ul {
    padding-left: 1rem;
}
.app-store .btn-app-store {
    display: flex;
    justify-content: center;
    margin: 20px auto 40px;
  }
  .app-store .btn-app-store img {
    max-width: 120px;
  }
  .app-store .btn-app-store a {
    margin-right: 6px;
  }