// Font size
$font-size-body: 1rem !default;
$font-size-sml:($font-size-body - .125rem) !default;
$font-size-xsml:($font-size-sml - .125rem) !default;
$font-size-sidebar-icon: 1.5rem !default;
// Font size Heading
$font-size-h1:2rem !default;
$font-size-h3:1.5rem !default;
$font-size-h4:1.35rem !default;
$font-size-h4-mob:1.125rem !default;
$font-size-h6:1.125rem !default;
// Font family
$font-family-body: 'Open Sans', sans-serif !default;
$font-family-logo: 'Shadows Into Light',
cursive!default;
// $font-family-heading: 'Noto Serif Display', serif !default;
$font-family-heading: 'Lato', sans-serif !default;
// Colors
$color-offwhite1:#f8f8f8 !default;
$color-offwhite:#f2f2f2 !default;
$color-white:#ffffff !default;
$color-body: #2b2b2b!default;
$color-body-light: #5c5c5c!default;
// $color-black-lt:#2d2d2d !default;
// $color-body:#eee !default;
// $color-body:#787878!default;
$color-hamburger:#5d5d5d !default;
$color-black-2e:#2e2e2e!default;
$color-black-4e:#4e4e4e !default;
$color-black:#000 !default;
$color-primary:#42CB86 !default;
$color-primary-lt:#1cff8e !default;
$color-primary-hover:#18CC73 !default;
$color-primary-dk:#2CA266 !default;
$color-secondary:#eb008b!default;
$color-red:#f00 !default;
$color-gray-dk:#a7a7a7!default;
$color-gray:#d8d8d8!default;
$color-gray-lt:#979797!default;
$color-divider:#979797!default;
$color-form-border:#c8c8c8!default;
$color-orange: #f56500!default;
$color-green-lt:#f4fcf8!default;
$color-green:#42CB86!default;
$color-green-dk:rgb(61, 193, 127) !default;
$color-sidebar-hover:#eee!default;
$color-sidebar-active:#2CA266 !default;
$color-border-light:#dcdcdc !default;
$color-offwhite2:#f2f3f5 !default;

// Spacing
$spacing-base:15px !default;
$spacing-double:$spacing-base * 2 !default;
// Box Shadow
$box-shadow-default:0px 2px 15px rgba(0, 0, 0, 0.1);
// Border Radius
$border-radius-default:8px !default;
// Font Weight
$font-weight-bold:700 !default;
$font-weight-semibold:600 !default;
$font-weight-light:300 !default;
// Z-Index
$zindex-sticky:1020 !default;
// Breakpoints
$breakpoints: ( xs: 576px, sm: 768px, md: 992px, lg: 1200px, xl: 1440px);