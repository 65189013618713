.nft-listing {
  padding-top: 20px;

  @include respond-above(sm) {
    padding-top: 70px;
  }

  .container {
    display: inherit;

  }

  .nft-list {
    display: flex;
    flex-wrap: wrap;

    .featured-card {
      flex: 0 0 calc(20% - 10px);
      margin-bottom: 10px;
      margin-right: 10px;
    }
  }

  .filter-sec {
    .nav {
      border: 2px solid $color-body;
      border-radius: $border-radius-default;

      .nav-item {
        &:first-of-type {
          a {
            border-radius: 4px 0 0 4px;


            &:hover {
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
          }
        }

        &:last-of-type {
          a {
            border-radius: 0 4px 4px 0;

            &:hover {
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
          }
        }
      }

      .nav-item {
        flex: 1;
      }

      a {
        width: 175px;
        text-align: center;
        cursor: pointer;
        font-size: 1.25rem;

        @include respond-below(md) {
          width: 140px;
          font-size: 1rem;
        }

        @include respond-below(sm) {
          width: 100%;
          flex: 1;
        }

        &.active,
        &:hover, &:focus {
          background-color: $color-body;
          color: $color-white;
        }
      }
    }

    .custom-select {
      div[class$="-control"] {
        min-height: 46px;
        border-color: $color-body;

        @include respond-below(md) {
          min-height: 43px;
        }
      }
    }

    form {
      .form-control {
        border-color: $color-body;
        min-height: 46px;

        @include respond-below(md) {
          min-height: 43px;
        }
      }
    }
  }

  .card-nft {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 15px;
  }

}