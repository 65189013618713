.offcanvas-body {
  .nav-item {
    border-bottom: 1px solid $color-body;
  }
}

.offcanvas-footer {
  padding: 10px 0 0;
  ul {
    padding-left: 0;
    list-style: none;
    li { 
      font-size: $font-size-xsml;
      // letter-spacing: 1px;
      // text-transform: uppercase;
      a {
        text-decoration: none;
        color: $color-body;
        padding-left: 0;
      }
    }
  }
}

#filter-tab {
  width: 180px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  .nav-link {
    text-align: left;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 1px solid $color-body;
    border-radius: 0;
    &.active,
    &:hover {
      background-color: transparent;
      color: $color-primary;
    }
  }
}

.filter-tab-content {
  width: 100%;
  .tab-pane {
    ul {
      padding-left: 0;
    }
    li {
      display: block;
      border-bottom: 1px solid $color-body;
    }
    a {
      text-decoration: none;
      padding: 10px 0;
      display: block;
    }
  }
}

#offCanvasFilter {
  .offcanvas-bottom {
    height: auto;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    border-top-color: $color-body;
    .btn-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      .btn {
        border-color: transparent;
        border-radius: 0;
        &:first-of-type {
          border-right-color: $color-body;
        }
      }
    }
  }
}

#mobileMenu {
  width: 320px;
  z-index: 1300;
  .nav-link {
    i {
      margin-right: 5px;
    }
  }
  .login-sec-mob {
    margin-top: $spacing-base;
    p {
      font-size: $font-size-sml;
      text-align: center;
    }
  }
  .offcanvas-body {
    display: flex;
    flex-direction: column;
    padding: 0;
    .mobile-menu {
      width: 100%;
    }
    .mobile-menu li {
     
      margin-bottom: 0px;
      border-bottom: 1px solid #eee;
    }
    .mobile-menu li a {
      text-decoration: none;
      display: block;
      padding: 10px 15px 10px;
    }
    .mobile-menu li a i {
      margin-right: 5px;
    }
    .login-sec-mob {
      padding:0 15px;
    }
  
  }
  .offcanvas-footer {
    margin-top: auto;
    ul {
      li {
        button {
          cursor: pointer;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
      }
    }
    a {
      padding-left:1rem;
      padding-right:1rem;
    }

  }
}