/* General Styles */

:root {
  --clr-light: rgb(245, 248, 255);
  --clr-light-gray: rgb(196, 195, 196);
  --clr-blue: $color-primary;
}

/* End General Styles */

.outer-btn {
  text-align: center;
  width: auto;
  display: inline-block;
  transition: 0.2s;
  position: relative;
  overflow: hidden;
}

.upload_btn {
  color: $color-white;
  text-align: center;
  position: relative;
  display: inline-block;
  overflow: hidden;
  z-index: 3;
  white-space: nowrap;
}

.upload_btn input {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  width: 100%;
  height: 105%;
  cursor: pointer;
  opacity: 0;
}

.file_uploading {
  width: 100%;
  height: 10px;
  margin-top: 20px;
  background: #ccc;
}

.file_uploading .upload_btn {
  display: none;
}

.processing_bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  border-radius: 15px;
  background: $color-primary;
  transition: 3s;
}

.file_uploading .processing_bar {
  width: 100%;
}

.file_uploading.file_uploaded {
  display: none;
}

.file_uploaded .success_box {
  display: inline-block;
}

.file_uploaded {
  margin-top: 0;
  width: 50px;
  background: $color-primary;
  height: 50px;
  border-radius: 50%;
}

.uploaded_file_view {
  max-width: 150px;
  text-align: center;
  position: relative;
  transition: 0.2s;
  opacity: 0;
  margin-top: 15px;
  img {
    max-width: 100%;
  }
  &.show {
    opacity: 1;
  }
}

.remove_file {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: block;
  position: absolute;
  background: #aaa;
  line-height: 30px;
  color: $color-white;
  font-size: 12px;
  cursor: pointer;
  right: -15px;
  top: -15px;
}

.remove_file:hover {
  background: #222;
  transition: 0.2s;
}

// Upload Video
.button_video_outer {
  // text-align: center;  
  display: inline-block;
  transition: 0.2s;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
  width: 100%;
  max-width: 100%;
}

.btn_video_upload {
  color: $color-white;
  text-align: center;
  position: relative;
  display: inline-block;
  overflow: hidden;
  z-index: 3;
  white-space: nowrap;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: $font-size-xsml;
  input {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    width: 100%;
    height: 105%;
    cursor: pointer;
    opacity: 0;
  }
}

.file_video_uploading,
.file_uploading {
  width: 100%;
  height: 10px;
  margin-top: 20px;
  background: #ccc;
  .btn_video_upload {
    display: none;
  }
  &.file_uploaded,
  &.file_video_uploaded {
    display: none;
  }
  .processing_bar,
  .processing_video_bar {
    width: 100%;
  }
}

.processing_bar,
.processing_video_bar {
  position: absolute;
  left: 0;
  bottom: -8px;
  width: 0;
  height: 100%;
  border-radius: 30px;
  background: $color-primary;
  transition: 3s;
  height: 4px;
}

.success_box,
.success_video_box {
  display: none;
  width: 50px;
  height: 50px;
  position: relative;
  &:before {
    content: "";
    display: block;
    width: 9px;
    height: 18px;
    border-bottom: 3px solid $color-white;
    border-right: 3px solid $color-white;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    left: 17px;
    top: 10px;
    z-index: 1;
  }
}

.file_uploaded,
.file_video_uploaded {
  .success_box,
  .success_video_box {
    display: inline-block;
  }
  margin-top: 0;
  width: 50px;
  background: $color-primary;
  height: 50px;
}

.uploaded_view,
.uploaded_video_view {
  max-width: 100%;
  margin: 0px 0;
  position: relative;
  transition: 0.2s;
  opacity: 0;
  &.uploaded {
    opacity: 1;
  }
}

.file_remove,
.file_video_remove {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: block;
  position: absolute;
  background: #aaa;
  line-height: 30px;
  color: $color-white;
  font-size: 12px;
  cursor: pointer;
  right: -15px;
  top: -15px;
  text-align: center;
  &:hover {
    background: #222;
    transition: 0.2s;
  }
}

#uploaded_view,
#uploaded_video_view {
  strong {
    display: block;
    padding-bottom: 10px;
  }
  video {
    width: 100%;
    height: 200px;
  }
  &.show {
    opacity: 1;
  }
}

.upload_error_msg,
.upload_video_error_msg {
  font-size: $font-size-sml;
  color: $color-red;
}

.custom {
  .outer-btn {
    width: 100%;
  }
  .btn_video_upload,
  .upload_btn {
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: $font-size-xsml;
    border: 1px solid $color-form-border;
    color: $color-body;
    border-radius: $border-radius-default;
    i {
      font-size: $font-size-h3;
      vertical-align: middle;
      color: $color-body-light;
    }
    strong {
      font-size: $font-size-sml;
    }
    strong,
    i,
    span {
      display: block;
    }
  }
}

.upload-sec {
  position: relative;
}

.my_club_media {
  width: 100%;
  max-width: 100%;
  height: 250px;
  object-fit: cover;
  display: inline-block;
  vertical-align: top;
  border-radius: 16px;
}

.upload-accept-filename {
  white-space: normal;
}


.upload-modal {
  .modal-header {
    border-bottom: none;
    padding-bottom: 0;
  }
  .modal-title {
    font-weight: normal;
    font-size: $font-size-h4;
  }
}
.upload-content {
  &-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: $font-size-sml;
    text-align: center;
    padding: 100px 30px;
    strong {
      font-size: $font-size-body;
      margin-bottom: $spacing-base - 5;
    }
    
  }
  &-icon {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: #2ca266;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-h3;
    color: $color-white;
    margin-bottom: $spacing-base;
  }
}

.upload-file-container {
  padding: 180px 0;
  width: 100%;
  opacity: 0;
  position: absolute;
  cursor: pointer;
}
.upload-requirement {
  padding-top: 30px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-around;
  text-align: left;
  &-right {
  }
  &-left{
    @media only screen and (max-width:1440px) {
      padding-bottom: 16px;
    }
  }
  @media only screen and (max-width:1440px) {
    flex-direction: column;
  }

}