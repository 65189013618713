.main-content-sec {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  // background-color: $color-body;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  z-index: 2;
  background-color: #fff;

  .sidebar {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70px;
    flex: 0 0 70px;

    &:hover {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 250px;
      flex: 0 0 250px;
    }
  }

  .sidebar.active {
    +.main-content {
      @media only screen and (min-width: 992px) {
        margin-left: 250px;
        width: calc(100% - 250px);
      }
    }
  }

  .main-content {
    -webkit-box-flex: 9999;
    -ms-flex: 9999;
    flex: 9999;
    padding: 15px 0;
    background-color: $color-white;
    margin: 0;
    width: 100%;
    border-radius: $border-radius-default;

    @include respond-above (md) {
      margin: 0 15px 15px 70px;
      padding: 15px 25px;
      width: calc(100% - 70px);
    }

    &__top {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin-bottom: $spacing-base;
      padding-bottom: 0px;
      margin-top: 5px;

      @include respond-above(sm) {
        border-bottom: 1px solid $color-gray;
      }

      @include respond-below(sm) {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
      }

      .custom-tab {
        margin-bottom: 10px;
        border-bottom: 1px solid $color-divider;

        @include respond-above(sm) {
          margin-bottom: -6px;
          border-bottom: none;
        }
      }

      &__right {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        margin-bottom: 5px;

        @include respond-above(sm) {
          margin-top: -12px;
          margin-left: auto;
        }

        @include respond-below (sm) {
          .btn {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
          }
        }

        // @include respond-below (sm) {
        //   position: fixed;
        //   bottom: 0;
        //   margin-bottom: 0;
        //   justify-content: flex-end;
        //   width: 100%;
        //   background: #fff;
        //   z-index: 9;
        //   padding: 10px;
        //   border-top: 1px solid #eee;
        //   left: 0px;
        // }
      }
    }

    @include respond-below (sm) {

      padding-bottom: 80px;
    }
  }

  &.bottom-space {
    margin-bottom: 60px;
  }
}

.main-content-sec-static-page {
  background-color: #fff;
  position: relative;
  z-index: 1;
  flex-grow: 1;
}