.myprofile-info {
  padding-top: $spacing-double;


  h3,
  h4 {
    margin-bottom: $spacing-double;
  }

  h3 {
    font-size: $font-size-h4;
  }

  h4 {
    font-size: $font-size-body;
  }


}

.myprofile-pic {
  display: flex;
  margin-bottom: $spacing-double;
  flex-direction: column;
  text-align: center;

  .myprofile-img {
    margin: 0 auto $spacing-base;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid $color-form-border;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

  }

  .myprofile-rt {
    display: flex;
    flex-direction: column;

    .btn_video_upload {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 0;
    }
  }

  .button_video_outer {
    margin-bottom: 0;
    overflow: visible;

    .btn_video_upload {
      padding: 0;
      text-align: left;
    }
  }
}

.location-info {
  border: 1px solid #c8c8c8;
  border-radius: 8px;

  label {
    padding-top: 8px;
  }

  .form-select {
    border: none;
    width: 33.33%;
    height: auto;
    padding-top: 0.25rem;
    padding-bottom: .55rem;
  }
}

.form-floating.datepicker {
  position: relative;
  z-index: 1;
  background-color: #fff;

  .form-control {
    z-index: 0;
    position: relative;
    background-color: transparent;
  }
}

.form-floating.datepicker::after {
  content: "\f1f6";
  position: absolute;
  top: 14px;
  right: 15px;
  font-family: "bootstrap-icons";
  z-index: -1;
}

.social-connect {
  h3 {
    margin-bottom: 0;
  }

  .btn {
    width: 100%;
    @include respond-above(sm) {
      min-width: 190px;
      width: auto;
    }
  }

  .myprofile-info {
    &-left {
      @include respond-below(sm) {
        margin-bottom: 10px;
      }
    }
  }

  &-info {
    display: flex;

    &-left {
      align-self: center;
      min-width: 190px;
      font-size: $font-size-sml;
      i {
        margin-right: 10px;
      }

    }
  }
}

.header-fixed {
  .profile-detail {

    background: rgba(0, 0, 0, 0.5);
    border-color: #333;

    .profile-user-name {
      color: #fff;
    }
  }

}

// ss

.profile-email-field .css-17vbkzs-MuiFormControl-root-MuiTextField-root {
  margin-top: 0px;
  margin-bottom: 0px;
  // margin-bottom: 1rem !important;
}

// .profile-detail {
//   display: flex;
//   background: rgba(255, 255, 255, 0.9);
//   border-radius: 8px;
//   padding: 5px 10px;
//   width: auto;
//   border: 1px solid #ddd;

//   .profile-detail-left {
//     display: flex;
//     align-items: center;
//   }

//   .profile-img {
//     margin-right: 10px;
//     border: 1px solid #aaa;
//     border-radius: 50%;
//     font-size: 10px;
//   }

//   .profile-detail-right {
//     margin:auto 0;

//   }
//   .profile-user-name {
//     display: flex;
//     flex-direction: column;
//     font-size: 12px;
//     font-weight: bold;
//     margin-right: 10px;
//   }
// }