.past-event-coll {
  .card-livestream {
    margin: 0 10px;
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 35px;
    border: none;
    font-size: 0;
    color: $color-white;
    background-color: rgba($color-body, 0.3);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    

    z-index: 1;
    @include respond-below(sm) {
      width: 32px;
      height: 32px;
    }

    &:before {
      font-family: 'bootstrap-icons' !important;
      font-size: $font-size-h3 - 0.25rem;
      @include respond-below(sm) {
        font-size: 0.75rem;
      }
    }

    &.slick-next {
      left: auto;
      right: 35px;

      // @include respond-below(sm) {
      //   left: calc(100% - 20px);
      // }

      &:before {
        content: "\f285";
      }
    }

    &.slick-prev {
      &:before {
        content: "\f284";
      }
    }

    &.slick-disabled {
      &::before {
        color: $color-gray-lt;
      }
      opacity: 0;
    }

    &:hover {
      background-color: $color-body-light;
      color: $color-white;
    }
  }
}