.modal-header {
  align-items: flex-start;

  .title-w-bdr {
    margin-bottom: 0;
  }

}

.modal-open {
  .modal {
    z-index: 1301;
  }
  .modal-backdrop {
    z-index: 1300;
  }
}