.upload-row .col-md-4 {
  position: relative;
  @include respond-above(sm) {
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 100%;
      top: 30px;
      background: $color-gray;
      width: 1px;
      height: calc(100% - 46px);
    }
  }
  &:last-of-type {
    &:after {
      display: none;
    }
  }
}

.profile-photo-preview {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  border: 1px solid $color-gray;
  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
}