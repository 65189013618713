.main-dashboard {
  .dashboard{
    margin-left: 0 !important;
   }
  .card {
    padding: 15px;
    margin-bottom: 15px;
    @include respond-above(sm) {
      margin-bottom: 0;
    }

    h3 {
      font-size: 18px;
    }

    ul {
      list-style: none;
      padding-left: 0;

      li {
        padding-bottom: 10px;
        display: flex;
        span {
          text-align: right;
        }
        span:first-of-type {
          margin-right: auto;
        }
      }
    }
    .card-link {
      text-decoration: none;
    }

    .upcoming-table{
      text-align: center;
    }
    
    .upcoming-table-head{
      padding: 8px 3px;
      font-size: 12px;
  
    }
   
  }
  @media only screen and(max-width: 1200px){
    .rerun-card{
      margin-top: 15px;
    }
  }
  @media only screen and(max-width: 768px){
    .rerun-card{
      margin-top: 0;
    }
  }


}

.claim-modal-row{
  text-align: right;
}
.claim-button{
  float: right;
  color: blue;
  &:hover{
    cursor: pointer;
  }
}

.claim-modal-detail{
  th{
    font-size: 12px;
    text-align: center;
  }
  td{
    font-size: 12px;
    text-align: center;
  }
}

.claim-button-amount{
  display: flex;
  width: 60%;

  button{
    margin-right: 5px!important;
    padding: 0.2rem 2rem !important;
  }

  input{
    border-radius: 8px;
    height: 30px;
    border: 0.1px solid #858080;
    // text-align: right;
    &:focus-visible{
      outline-offset: -webkit-focus-ring-color auto 0px !important;
    }
  }
}
.claim-section{
  display:flex;
  justify-content: space-evenly;
  padding-bottom: 5px;

  input{
    border-radius: 8px;
    height: 30px;
    margin-top: 3px;
    border: 0.1px solid #858080;
  
  }
}

.claim-account-details{
  h3{
    padding-left: 10px;
    padding-top: 10px;
  }
  p{
    font-size: 12px;
    padding-left: 10px;
    margin-bottom: 0.5rem;
  }
}
.amount-error-field{
  color: red;
  font-size: 12px;
  margin-bottom: 0;
  display: flex;
  justify-content: right;
  padding-right:100px;
  font-weight: 300;
}