.scroll-down-sec{
    position: absolute;
    bottom: 20px;
    text-align: center;
	font-size: 13px;
}
.mouse {
	border-radius: 60px;
	position: relative;
    width: 30px;
    height: 55px;
    border: 1px solid #fff;
    margin: 0 auto 10px;
	&::before {
		content: '';
		width: 7px;
		height: 7px;
		position: absolute;
		top: 10px;
		left: 50%;
		transform: translateX(-50%);
		background-color: #fff;
		border-radius: 50%;
		opacity: 1;
		animation: wheel 2s infinite;
		-webkit-animation: wheel 2s infinite;
	}
}

@keyframes wheel {
	to {
		opacity: 0;
		top: 40px;
	}
}

@-webkit-keyframes wheel {
	to {
		opacity: 0;
		top: 40px;
	}
}