.edit-package {
  display: none;
  &.active {
    display: block;
  }
}

.plan-info {
  display: flex;
  &.plan-info-hidden {
    display: none;
  }
}

#packages {
  .card {
    min-height: 300px;
    &-body {
      display: flex;
      flex-direction: column;
      .btn-group {
        margin-top: auto;
        .btn {
          flex: 1;
        }
      }
    }
  }
}